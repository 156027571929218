import {useEffect, useState} from 'react';

import {useI18n} from '@udemy/i18n';
import {udApi} from '@udemy/ud-api';
import {udLink} from '@udemy/ud-data';

export const NOTICE_TYPES = {
    BANNER_CAROUSEL_SLIDE: 'banner_carousel_slide',
    FALLBACK_BANNER: 'fallback_banner',
};

export type CTATargetType = 'self' | 'new_tab';

export interface BannerData {
    id?: number;
    personalized_notice_set_id?: number;
    personalized_notice_set_name?: string;
    title: string;
    title_responsive: string;
    subtitle: string;
    subtitle_responsive: string;
    url?: string;
    cta_1_target?: CTATargetType;
    cta_1_text?: string;
    cta_1_url?: string;
    cta_2_target?: CTATargetType;
    cta_2_text?: string;
    cta_2_url?: string;
    image: string;
    image_responsive: string;
    target_group_id?: number | null;
    layout: 'wide' | 'split';
}

export const useFetchHeroBanners = (type: string) => {
    const {gettext} = useI18n();
    const [noticeType, setNoticeType] = useState(type);
    const [isHeroBannerLoading, setIsHeroBannerLoading] = useState(true);
    const [heroBanners, setHeroBanners] = useState<{data: BannerData}[]>([]);

    const slideLayoutType = (banners: {data?: {layout?: 'wide' | 'split'}}[]) => {
        if (banners.length > 0) {
            const {data} = banners[0];
            if (data && data.layout) {
                return data.layout;
            }
        }
        return null;
    };

    useEffect(() => {
        const getNoticeType = (banners: {data?: {id?: number}}[]) =>
            // Fallback Banner is hardcoded and its id is always 0
            banners[0]?.data?.id === 0 ? NOTICE_TYPES.FALLBACK_BANNER : type;

        const fetchHeroBanners = async () => {
            try {
                setIsHeroBannerLoading(true);
                const searchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(searchParams.entries());

                const response = await udApi.get(`/notices/me/`, {
                    params: {
                        type: type,
                        ...params,
                    },
                });

                setNoticeType(getNoticeType(response.data.results));
                setHeroBanners(response.data.results);
                setIsHeroBannerLoading(false);
            } catch (error) {
                setNoticeType(NOTICE_TYPES.FALLBACK_BANNER);
                setHeroBanners([
                    {
                        data: {
                            id: 0,
                            title: gettext('Learning that gets you'),
                            subtitle: gettext(
                                'Skills for your present (and your future). Get started with us.',
                            ),
                            title_responsive: gettext('Learning that gets you'),
                            subtitle_responsive: gettext(
                                'Skills for your present (and your future). Get started with us.',
                            ),
                            image: udLink.toStorageStaticAsset(
                                'browse_components/billboard/fallback_banner_image_udlite.jpg',
                            ),
                            image_responsive: udLink.toStorageStaticAsset(
                                'browse_components/billboard/fallback_banner_image_responsive_udlite.jpg',
                            ),
                            layout: 'wide',
                        },
                    },
                ]);
                setIsHeroBannerLoading(false);
            }
        };

        fetchHeroBanners();
    }, [type, gettext]);

    return {
        noticeType,
        heroBanners,
        isHeroBannerLoading,
        bannerSlideLayout: slideLayoutType(heroBanners),
    };
};
