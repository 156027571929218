import React, {useMemo, useState} from 'react';

import {useI18n} from '@udemy/i18n';
import {
    EnterprisePlanCard,
    EnterprisePlanHeader,
    LeadershipPlanCard,
    LeadershipPlanHeader,
    PersonalPlanCard,
    PersonalPlanHeader,
    PlanComparisonWrapper,
    PlanComparisonWrapperSkeleton,
    TeamPlanCard,
    TeamPlanHeader,
} from '@udemy/react-plan-comparison';
import {BlockSkeleton} from '@udemy/react-reveal-components';
import {PlanPeriod, PlanPeriodStyle} from '@udemy/subscription-browse';
import {
    AnnualSubscriptionPlanPricingOption,
    DateIntervalType,
    TeamSubscriptionPlan,
    useConsumerSubscriptionPlan,
    useTeamSubscriptionPlan,
} from '@udemy/subscription-graphql';
import {useFormatCurrency, useUDData} from '@udemy/ud-data';

import containerStyles from '../../features/lohp/logged-out-homepage-body.module.less';
import {TEAM_PLAN_V1_FALLBACK_CURRENCY_FORMATTER, UB_REF_QUERY_PARAM_VALUE} from './constants';
import styles from './plan-comparison.module.less';

export const PlanComparison = () => {
    const {gettext} = useI18n();
    const {formatCurrency} = useFormatCurrency();
    const {Config: udConfig} = useUDData();
    const showUBLA = udConfig.price_country?.id === 'US';
    const isPriceCountryJapan = udConfig.price_country?.id === 'JP';

    const PriceBlockSkeleton = () => <BlockSkeleton className={styles['price-loading']} />;

    const personalPlanContext = useConsumerSubscriptionPlan();
    const teamPlanContext = useTeamSubscriptionPlan();

    // These loading states ensure that, on this page, we differentiate between loading and no plan available
    // to ensure that the cards themselves are displayed rather than loading them in once the plan is available
    const [personalPlanIsLoading, setPersonalPlanIsLoading] = useState(
        personalPlanContext?.isLoading ?? true,
    );
    const [teamPlanIsLoading, setTeamPlanIsLoading] = useState(true);
    const [personalPlanDiscountEnabled, setPersonalPlanDiscountEnabled] = useState(false);

    // The Personal Plan Price prop
    const personalSubscription = useMemo(() => {
        setPersonalPlanIsLoading(personalPlanContext?.isLoading ?? true);
        // Don't display card if plans are loaded and there isn't a plan available
        if (!personalPlanContext && !personalPlanIsLoading) {
            return undefined;
        }
        if (personalPlanIsLoading) {
            return {subscriptionPlan: undefined, priceComponent: <PriceBlockSkeleton />};
        }
        if (personalPlanContext && 'priceOption' in personalPlanContext) {
            const formattedMonthlyPrice = personalPlanContext.formattedMonthlyAmount;
            const discountMonthlyPrice =
                personalPlanContext.selectedDiscount?.subscriptionDiscountMonthlyPrice;
            setPersonalPlanDiscountEnabled(!!discountMonthlyPrice);
            const formattedDiscountMonthlyPrice = discountMonthlyPrice
                ? formatCurrency(discountMonthlyPrice)
                : undefined;
            return {
                subscriptionPlan: personalPlanContext,
                priceComponent: discountMonthlyPrice ? (
                    <span className={styles['price-text']}>
                        <PlanPeriod
                            discountPriceText={formattedDiscountMonthlyPrice}
                            listPriceText={formattedMonthlyPrice}
                            style={PlanPeriodStyle.PLAN_COMPARISON}
                            emphasizeListPriceText={true}
                        />
                    </span>
                ) : (
                    <span className={styles['price-text']}>
                        <PlanPeriod
                            listPriceText={formattedMonthlyPrice}
                            style={PlanPeriodStyle.PLAN_COMPARISON}
                        />
                    </span>
                ),
            };
        }
        return {subscriptionPlan: undefined, priceComponent: <PriceBlockSkeleton />};
    }, [formatCurrency, personalPlanContext, personalPlanIsLoading]);

    // This logic is getting somewhat complex, so I've encapsulated it here. Here's what's happening:
    // - For TPv1 countries where pricing is not returned via GraphQL, we will show $360 USD per year per user.
    //   This is a hardcoded value that must be shown in USD, and must fully override the local currency formatter
    //   to ensure USD prices are displayed.
    // - For users in Japan, we will show annual pricing to better align with the annual pricing displayed on Benesse
    //   marketing pages.
    // - For TPv2 users, we will show the monthly price that is returned by the subscription pricing GraphQL calls
    const getTeamPlanPriceText = (teamPlanContext?: TeamSubscriptionPlan) => {
        const yearlyPlanOption = teamPlanContext?.priceOption(
            DateIntervalType.Year,
        ) as AnnualSubscriptionPlanPricingOption;

        // For Japan, explicitly show the yearly price
        if (isPriceCountryJapan) {
            const price = formatCurrency(yearlyPlanOption?.listPrice.amount);
            return interpolate(gettext('Annual fee %(price)s (excl. tax)'), {price}, true);
        }

        // HACK HACK HACK - this is a temporary solution to get the price to display for TPv1 b/c pricing won't support it at the GraphQL layer.
        // Specifically, the hardcoded TPv1 fallback price of $360.00 USD per year must be shown in USD, and must fully override the local currency formatter.
        // This is known to be a bad experience for users and an extreme anti-pattern, but again, pricing / subs platform will not support a fallback in the API layer
        // for TPv1.
        const useTPv1USDPriceFormatter = teamPlanContext?.id === '0';
        if (useTPv1USDPriceFormatter) {
            const price = formatCurrency(
                yearlyPlanOption.listPrice.amount,
                TEAM_PLAN_V1_FALLBACK_CURRENCY_FORMATTER,
            );
            return interpolate(gettext('%(price)s a year per user'), {price}, true);
        }
        const price = formatCurrency(yearlyPlanOption?.monthlyPrice.amount);
        return interpolate(gettext('%(price)s a month per user'), {price}, true);
    };

    // The Team Plan Price prop
    const teamSubscription = useMemo(() => {
        // Don't display card if there isn't a plan available
        if (!teamPlanContext && !teamPlanIsLoading) {
            return undefined;
        }
        setTeamPlanIsLoading(teamPlanContext?.isLoading ?? true);
        if (teamPlanIsLoading) {
            return {subscriptionPlan: undefined, priceComponent: <PriceBlockSkeleton />};
        }
        if (teamPlanContext && 'priceOption' in teamPlanContext) {
            const priceText = getTeamPlanPriceText(teamPlanContext);
            return {
                subscriptionPlan: teamPlanContext,
                priceComponent: <p className="ud-heading-md">{priceText}</p>,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamPlanContext, teamPlanIsLoading, formatCurrency, gettext]);

    // Render loading state for entire plan comparison module until consumer subscription plan loaded
    if (personalPlanIsLoading) {
        return (
            <section className={containerStyles['ud-container-with-common-padding']}>
                <PlanComparisonWrapperSkeleton />
            </section>
        );
    }

    // Hide entire plan comparison unit in geos without PP
    if (!personalSubscription?.subscriptionPlan && !isPriceCountryJapan) {
        return null;
    }

    const accordionPanels = [
        {
            renderTitle: () => (
                <TeamPlanHeader
                    licenseContext={teamSubscription?.subscriptionPlan?.licenseContext}
                />
            ),
            children: teamSubscription && (
                <TeamPlanCard subPlan={teamSubscription} ubRef={UB_REF_QUERY_PARAM_VALUE} />
            ),
        },
        {
            renderTitle: () => <EnterprisePlanHeader />,
            children: <EnterprisePlanCard ubRef={UB_REF_QUERY_PARAM_VALUE} />,
        },
    ];
    if (showUBLA) {
        accordionPanels.push({
            renderTitle: () => <LeadershipPlanHeader />,
            children: <LeadershipPlanCard ubRef={UB_REF_QUERY_PARAM_VALUE} />,
        });
    }
    if (!isPriceCountryJapan) {
        accordionPanels.unshift({
            renderTitle: () => (
                <PersonalPlanHeader
                    subscriptionDiscount={personalSubscription?.subscriptionPlan?.annualDiscount}
                />
            ),
            children: personalSubscription && (
                <PersonalPlanCard subPlan={personalSubscription} ubRef={UB_REF_QUERY_PARAM_VALUE} />
            ),
        });
    }
    // eslint-disable-next-line no-console
    // console.log('what the', personalSubscription, teamSubscription);
    return (
        <section className={containerStyles['ud-container-with-common-padding']}>
            <PlanComparisonWrapper
                title={
                    !isPriceCountryJapan
                        ? gettext('Accelerate growth — for you or your organization')
                        : gettext('Accelerate growth — for your organization')
                }
                subtitle={gettext(
                    'Reach goals faster with one of our plans or programs. Try one free today or contact sales to learn more.',
                )}
                accordionPanels={accordionPanels}
            >
                {personalSubscription && !isPriceCountryJapan && (
                    <PersonalPlanCard
                        subPlan={personalSubscription}
                        ubRef={UB_REF_QUERY_PARAM_VALUE}
                    />
                )}
                {teamSubscription && (
                    <TeamPlanCard
                        subPlan={teamSubscription}
                        ubRef={UB_REF_QUERY_PARAM_VALUE}
                        bottomPadding={personalPlanDiscountEnabled}
                    />
                )}
                <EnterprisePlanCard
                    ubRef={UB_REF_QUERY_PARAM_VALUE}
                    bottomPadding={personalPlanDiscountEnabled}
                />
                {showUBLA && (
                    <LeadershipPlanCard
                        ubRef={UB_REF_QUERY_PARAM_VALUE}
                        bottomPadding={personalPlanDiscountEnabled}
                    />
                )}
            </PlanComparisonWrapper>
        </section>
    );
};
