import {DynamicWebContentPageType} from 'src/generated/graphql';
import {DynamicWebContentQueryParams} from 'src/lib/url-utils';

export function useDynamicWebContentQueryParams({
    content_preview_overrides,
    pageType,
    preview_mode,
}: DynamicWebContentQueryParams & {
    pageType: DynamicWebContentPageType;
}) {
    const queryArgs: {
        pageType: DynamicWebContentPageType;
        isContentPreviewEnabled?: boolean;
        contentPreviewOverrides?: string[];
    } = {pageType};

    if (preview_mode) {
        queryArgs.isContentPreviewEnabled = true;

        if (content_preview_overrides && typeof content_preview_overrides === 'string') {
            queryArgs.contentPreviewOverrides = content_preview_overrides?.split(';');
        }
    }

    return queryArgs;
}
