import classNames from 'classnames';
import React from 'react';

import {UFBNoticeClickEvent} from '@udemy/browse-event-tracking';
import {Tracker, TrackImpression} from '@udemy/event-tracking';
import {useMatchMedia} from '@udemy/hooks';
import ArrowRightIcon from '@udemy/icons/dist/arrow-right.ud-icon';
import {Button} from '@udemy/react-core-components';
import {Ribbon} from '@udemy/react-messaging-components';
import {Skeleton} from '@udemy/react-reveal-components';
import {tokens} from '@udemy/styles';
import {useUDData} from '@udemy/ud-data';

import skeletonStyles from './features-skeleton.module.less';
import styles from './features-tab.module.less';
import {isUBMarketingUrl} from './value-props-and-features-cms-wrapper.react-component';

export interface FeatureTabContentProps {
    /** Title of the feature card */
    title: string;
    /** Description of the card */
    description: string;
    /** Flag to turn on the "Enterprise Badge" badge */
    enterpriseBadge?: boolean;
    /** Text to display on the optional CTA button **/
    ctaText?: string;
    /** URL for optional CTA button */
    ctaHref?: string;
    /** Text to display on the optional badge */
    badgeText?: string;
    /** Event tracking usually specified if an enterprise card */
    trackImpressionFunc?: () => void;
}

export const FeaturesTabContentLoader = () => {
    return (
        <div className={classNames(skeletonStyles['content-skeleton'])}>
            <Skeleton className={classNames(skeletonStyles['title-skeleton'])} />
            <Skeleton className={classNames(skeletonStyles['description-skeleton'])} />
        </div>
    );
};

/**
 * ### FeaturesTabContent
 */
export const FeaturesTabContent = ({
    title,
    enterpriseBadge,
    description,
    ctaText,
    ctaHref,
    badgeText,
    trackImpressionFunc,
}: FeatureTabContentProps) => {
    const {request: udRequest} = useUDData();
    const isMdSized = useMatchMedia(
        `(min-width: ${tokens['breakpoint-md-min']}) and (max-width: ${tokens['breakpoint-md-max']})`,
    );

    const onClick = () => {
        isUBMarketingUrl(ctaHref as string) &&
            Tracker.publishEvent(
                new UFBNoticeClickEvent({
                    locale: udRequest.locale,
                    placement: 'features_module',
                    url: ctaHref,
                }),
            );
    };

    const content = (
        <div>
            <div className={styles['title-flex']}>
                <p className={classNames(styles.title)}>{title} </p>
                {enterpriseBadge && (
                    <Ribbon className={styles['enterprise-badge']}>{badgeText}</Ribbon>
                )}
            </div>
            <span
                className={classNames(styles.description, isMdSized ? 'ud-text-sm' : 'ud-text-md')}
                dangerouslySetInnerHTML={{__html: description ?? ''}}
            />
            {ctaHref && (
                <Button
                    componentClass="a"
                    href={ctaHref}
                    size={isMdSized ? 'small' : 'large'}
                    udStyle="ghost"
                    onClick={onClick}
                    target="_blank"
                >
                    {ctaText} <ArrowRightIcon />
                </Button>
            )}
        </div>
    );

    if (trackImpressionFunc) {
        return <TrackImpression trackFunc={trackImpressionFunc}>{content}</TrackImpression>;
    }

    return content;
};
