import Head from 'next/head';
import {useRouter} from 'next/router';

import {BrowseCourseBadgesContext} from '@udemy/browse-course';
import {HeadSEO, useCanonicalUrl} from '@udemy/head-seo';
import {META_DEFAULT_BASE_URL} from '@udemy/nextjs-core';
import {professionalSkillsShowcaseData} from '@udemy/react-discovery-units';
import {useFeatures} from '@udemy/react-experimentation-provider';
import {SitelinkSearchBoxScript} from '@udemy/schema-markup';
import {SubscriptionPlanProductType, SubscriptionPlansProvider} from '@udemy/subscription-graphql';
import {useUDData} from '@udemy/ud-data';

import {getLayout} from 'src/components/layout';
import {
    getBrowseCourseBadgesContextOptionsByExperiment,
    SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE,
} from 'src/lib/experimentation-helper';

import {ProfessionalSkillsShowcaseDataProvider} from './contexts/professional-skills-showcase-data-context';
import {
    LoggedOutHomePageBody,
    LoggedOutHomePageBodyProps,
} from './logged-out-homepage-body.react-component';

const MX_SEARCH_URL = 'courses/search';

interface LoggedOutHomePageFeatureProps extends LoggedOutHomePageBodyProps {
    professionalSkillsShowcaseData: typeof professionalSkillsShowcaseData;
}

export function LoggedOutHomePageFeature({
    professionalSkillsShowcaseData,
    ...props
}: LoggedOutHomePageFeatureProps) {
    const udConfig = useUDData().Config;
    const router = useRouter();
    const {url: canonicalUrl} = useCanonicalUrl(
        router as {locale: string; asPath: string},
        [],
        META_DEFAULT_BASE_URL,
    );

    // TODO: Clean up after subs catalog diff experiment is over https://udemyjira.atlassian.net/browse/CVR-151
    const {featureVariantAssignments} = useFeatures([SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE]);
    const subscriptionCatalogDifferentiatorFeature =
        featureVariantAssignments.get(SUBSCRIPTION_CATALOG_DIFFERENTIATOR_FEATURE)?.configuration ??
        {};

    return (
        <>
            <SitelinkSearchBoxScript
                brandUrl={udConfig.url.to_root}
                searchHandlerUri={`${udConfig.url.to_root}${MX_SEARCH_URL}`}
                headWrapper={Head}
            />
            <HeadSEO
                canonicalUrl={canonicalUrl}
                assetPath={process.env.ASSET_PREFIX}
                headWrapper={Head}
            />
            <SubscriptionPlansProvider
                subscriptionPlanQueryVariables={[
                    {
                        filters: {productType: SubscriptionPlanProductType.Consumersubscription},
                        marketingURL: `${new URL(canonicalUrl).origin}${router.asPath}`,
                    },
                    {
                        filters: {productType: SubscriptionPlanProductType.Team},
                    },
                ]}
            >
                <BrowseCourseBadgesContext.Provider
                    value={{
                        browseBadgeHandlerOptions: getBrowseCourseBadgesContextOptionsByExperiment(
                            subscriptionCatalogDifferentiatorFeature,
                        ),
                    }}
                >
                    <ProfessionalSkillsShowcaseDataProvider
                        context={professionalSkillsShowcaseData}
                    >
                        <LoggedOutHomePageBody {...props} />
                    </ProfessionalSkillsShowcaseDataProvider>
                </BrowseCourseBadgesContext.Provider>
            </SubscriptionPlansProvider>
        </>
    );
}

LoggedOutHomePageFeature.getLayout = getLayout;
