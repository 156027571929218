import classNames from 'classnames';
import ouibounce from 'ouibounce';
import React, {useContext, useEffect, useRef} from 'react';

import {InDemandCareer, InDemandCareerWrapper} from '@udemy/career-academies';
import {AboveTheFoldProvider} from '@udemy/design-system-utils';
import {useI18n} from '@udemy/i18n';
import {TestimonialsCMSWrapper} from '@udemy/react-cms-components';
import {useFeatures} from '@udemy/react-experimentation-provider';
import {
    CourseGuidanceEntryPointWithPreselection,
    TopCategoriesComponent,
} from '@udemy/react-globalization-components';
import {SearchBarMXEnum} from '@udemy/react-header';
import {
    OnePaneLayoutContainer,
    OnePaneLayoutFullWidthSection,
    OnePaneLayoutFullWidthSectionContent,
} from '@udemy/react-layout-components';
import {
    CMSConnectedPartnersBanner,
    DynamicWebContentPageType,
} from '@udemy/shared-one-udemy-components';
import {useUDData} from '@udemy/ud-data';

import {DiscoveryUnits} from 'src/components/discovery-units/discovery-units.react-component';
import {
    BannerLayout,
    MarketingHeroBanner,
} from 'src/components/hero-banner/marketing-hero-banner.react-component';
import {PlanComparison} from 'src/components/plan-comparison/plan-comparison.react-component';
import {
    ProfessionalSkillsUnit,
    PSUSkeleton,
} from 'src/components/professional-skills-showcase/professional-skills-showcase.react-component';
import {TrendingSkills} from 'src/components/trending-skills/trending-skills.react-component';
import {UBCaseStudiesCMSWrapper} from 'src/components/ub-case-studies/ub-case-studies-cms-wrapper.react-component';
import {
    BROWSE_AGGREGATOR_PAGE_FEATURE,
    CAREER_ACADEMIES,
    COURSE_GUIDANCE_FEATURE,
    LEARNERS_ARE_VIEWING_ON_LOHP,
    NEW_SEARCH_BAR_MX,
    POPULAR_CATEGORIES_MODULE,
    SHOW_TRENDING_SKILLS_ABOVE_PARTNERS_BANNER,
} from 'src/lib/experimentation-helper';

import {UBReportsBannerCMSWrapper} from '../../components/ub-reports-banner/ub-reports-banner-cms-wrapper.react-component';
import {ValuePropsAndFeaturesCMSWrapper} from '../../components/value-props-and-features/value-props-and-features-cms-wrapper.react-component';
import {ProfessionalSkillsShowcaseDataContext} from './contexts/professional-skills-showcase-data-context';
import {NOTICE_TYPES, useFetchHeroBanners} from './hooks/useFetchHeroBanners';
import styles from './logged-out-homepage-body.module.less';

export interface LoggedOutHomePageBodyProps {
    academies: InDemandCareer[];
}

export const LoggedOutHomePageBody = ({academies}: LoggedOutHomePageBodyProps) => {
    const {heroBanners, isHeroBannerLoading, noticeType, bannerSlideLayout} = useFetchHeroBanners(
        NOTICE_TYPES.BANNER_CAROUSEL_SLIDE,
    );
    const {locale} = useI18n();
    const psuData = useContext(ProfessionalSkillsShowcaseDataContext);
    const {me} = useUDData();
    const hasTrackedExitIntent = useRef(false);

    // PSU re-ordering Experiment - https://udemyjira.atlassian.net/browse/TB-10066
    const {isLoading, featureVariantAssignments: features} = useFeatures([
        BROWSE_AGGREGATOR_PAGE_FEATURE,
        CAREER_ACADEMIES,
        COURSE_GUIDANCE_FEATURE,
        LEARNERS_ARE_VIEWING_ON_LOHP,
        POPULAR_CATEGORIES_MODULE,
        SHOW_TRENDING_SKILLS_ABOVE_PARTNERS_BANNER,
    ]);
    const showCourseGuidanceFeature = features.get(COURSE_GUIDANCE_FEATURE)?.configuration ?? {};
    const showPopularCategoriesModule =
        features.get(POPULAR_CATEGORIES_MODULE)?.configuration ?? {};
    const showTrendingSkillsAbovePartnersBanner =
        features.get(SHOW_TRENDING_SKILLS_ABOVE_PARTNERS_BANNER)?.configuration ?? {};

    // Learners Also Viewing | PSU Features - https://udemyjira.atlassian.net/browse/CVR-523
    const learnersViewingFeatConfigVal = features.get(LEARNERS_ARE_VIEWING_ON_LOHP);

    // Retrieve feature configuration values from the ExperimentationContext features object
    const learnersViewingUnitPlacement = learnersViewingFeatConfigVal?.configuration?.placement;

    // Hide Learners Also Viewing Section on certain locales with low course supply
    const shouldHidePSUOnLowCourseSupplyLocale = ['nl_NL', 'ro_RO', 'th_TH', 'vi_VN'].includes(
        locale,
    );

    const {isLoading: isSearchBarLoading, featureVariantAssignments: SearchBarFeatures} =
        useFeatures([NEW_SEARCH_BAR_MX]);
    const showNewSearchBar =
        SearchBarFeatures.get('new_search_bar_mx')?.configuration.showNewSearchBarMX;

    let showTwoRowSearchBarMX = SearchBarMXEnum.NotLoaded;
    if (!isSearchBarLoading) {
        showTwoRowSearchBarMX = showNewSearchBar ? SearchBarMXEnum.NewBar : SearchBarMXEnum.OldBar;
    }

    const isAggregatorPageEnabled = features.get(BROWSE_AGGREGATOR_PAGE_FEATURE)?.configuration
        ?.pageEnabled;

    const isCareerAcademiesEnabled = features.get(CAREER_ACADEMIES)?.configuration?.feature;
    const displayedAcademies = academies.length > 3 ? academies.slice(0, 3) : academies;

    useEffect(() => {
        const dummyModal = document.createElement('div');

        ouibounce(dummyModal, {
            aggressive: true,
            timer: 0,
            callback: () => {
                if (me.is_authenticated || hasTrackedExitIntent.current) return;
                window.appboy?.logCustomEvent('exit_intent', {page: 'LoggedOutHomePage'});
                hasTrackedExitIntent.current = true;
            },
        });
    }, [me.is_authenticated]);

    return (
        <div data-testid="lohp-body">
            <OnePaneLayoutContainer>
                <AboveTheFoldProvider>
                    <section>
                        <OnePaneLayoutFullWidthSection>
                            <OnePaneLayoutFullWidthSection.Content
                                className={classNames(styles['marketing-hero-banner'], {
                                    [styles['marketing-hero-banner-wide']]:
                                        bannerSlideLayout === BannerLayout.WIDE,
                                })}
                            >
                                <MarketingHeroBanner
                                    heroBanners={heroBanners}
                                    noticeType={noticeType}
                                    isLoading={isHeroBannerLoading}
                                    showBannerSearchBar={
                                        showTwoRowSearchBarMX === SearchBarMXEnum.OldBar
                                    }
                                />
                            </OnePaneLayoutFullWidthSection.Content>
                        </OnePaneLayoutFullWidthSection>
                    </section>

                    {isCareerAcademiesEnabled && (
                        <section className={styles['academies-list']}>
                            <InDemandCareerWrapper
                                academies={displayedAcademies}
                                className={styles['academies-list-wrapper']}
                                headingProps={{className: 'ud-heading-serif-xxl'}}
                                isUSMarketplace
                            />
                        </section>
                    )}
                </AboveTheFoldProvider>

                {showCourseGuidanceFeature.show_course_guidance_feature && (
                    <section className={styles['guidance-entry-point']}>
                        <CourseGuidanceEntryPointWithPreselection />
                    </section>
                )}

                {showPopularCategoriesModule.show_popular_categories_module && (
                    <section className={styles['top-categories']}>
                        <TopCategoriesComponent />
                    </section>
                )}

                {!shouldHidePSUOnLowCourseSupplyLocale && (
                    <section className={styles['professionals-skills-module']}>
                        <ProfessionalSkillsUnit
                            pageType="logged_out_homepage"
                            // Needed to pass this for preventing gray background when padding is added
                            contentPanelClassName={styles['psu-subcontent-panel']}
                            unitData={psuData}
                            loadingSkeleton={isLoading ? <PSUSkeleton /> : null}
                        />
                    </section>
                )}
                {showTrendingSkillsAbovePartnersBanner.show_trending_skills_above_partners_banner && (
                    <section>
                        <OnePaneLayoutFullWidthSection className={styles['fill-background']}>
                            <div className={styles['ud-container-with-common-padding']}>
                                <TrendingSkills />
                            </div>
                        </OnePaneLayoutFullWidthSection>
                    </section>
                )}
                <section>
                    <div className={styles['partners-banner-wrapper']}>
                        <CMSConnectedPartnersBanner
                            pageType={DynamicWebContentPageType.LoggedOutHomePage}
                        />
                    </div>
                </section>
                {learnersViewingUnitPlacement === 'below_professional_skill_showcase_unit' && (
                    <OnePaneLayoutFullWidthSection>
                        <section
                            id="discovery-units-top"
                            className={styles['ud-container-with-common-padding']}
                        >
                            <DiscoveryUnits />
                        </section>
                    </OnePaneLayoutFullWidthSection>
                )}

                <section>
                    <OnePaneLayoutFullWidthSection className={styles['fill-background']}>
                        <div className={styles['ud-container-with-common-padding']}>
                            <ValuePropsAndFeaturesCMSWrapper
                                isAggregatorPageEnabled={isAggregatorPageEnabled}
                            />
                        </div>
                    </OnePaneLayoutFullWidthSection>
                </section>
                <OnePaneLayoutFullWidthSection>
                    <PlanComparison />
                </OnePaneLayoutFullWidthSection>
                <section>
                    <OnePaneLayoutFullWidthSection className={styles['fill-background']}>
                        <div className={styles['ud-container-with-common-padding']}>
                            <TestimonialsCMSWrapper />
                        </div>
                    </OnePaneLayoutFullWidthSection>
                </section>
                <section>
                    <UBReportsBannerCMSWrapper />
                </section>
                {!showTrendingSkillsAbovePartnersBanner.show_trending_skills_above_partners_banner && (
                    <section>
                        <OnePaneLayoutFullWidthSection className={styles['fill-background']}>
                            <div className={styles['ud-container-with-common-padding']}>
                                <TrendingSkills />
                            </div>
                        </OnePaneLayoutFullWidthSection>
                    </section>
                )}
                {learnersViewingUnitPlacement === 'default' && (
                    <OnePaneLayoutFullWidthSection>
                        <section
                            id="discovery-units-lower"
                            className={styles['ud-container-with-common-padding']}
                        >
                            <DiscoveryUnits />
                        </section>
                    </OnePaneLayoutFullWidthSection>
                )}
                <section>
                    <OnePaneLayoutFullWidthSection className={styles['fill-background']}>
                        <OnePaneLayoutFullWidthSectionContent className={styles['ub-case-studies']}>
                            <UBCaseStudiesCMSWrapper />
                        </OnePaneLayoutFullWidthSectionContent>
                    </OnePaneLayoutFullWidthSection>
                </section>
            </OnePaneLayoutContainer>
        </div>
    );
};
