import {useMatchMedia} from '@udemy/hooks';
import {Skeleton} from '@udemy/react-reveal-components';

import styles from './ub-case-studies-skeleton.module.less';

export const UBCaseStudiesSkeleton = () => {
    const is980Max = useMatchMedia('(max-width: 980px)');

    return is980Max ? <UBCaseStudiesSkeletonNarrow /> : <UBCaseStudiesSkeletonWide />;
};

const UBCaseStudiesSkeletonWide = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles['content-container']}>
                <Skeleton className={styles['logo']} />
                <Skeleton className={styles['description']} />
                <Skeleton className={styles['description']} />
                <Skeleton className={styles['description']} />
                <div className={styles['stats-container']}>
                    <div className={styles['stats']}>
                        <Skeleton className={styles['stat-title']} />
                        <Skeleton className={styles['stat-description']} />
                        <Skeleton className={styles['stat-description']} />
                    </div>
                    <div className={styles['stats']}>
                        <Skeleton className={styles['stat-title']} />
                        <Skeleton className={styles['stat-description']} />
                        <Skeleton className={styles['stat-description']} />
                    </div>
                </div>
                <Skeleton className={styles['cta-button']} />
                <Skeleton className={styles['controls']} />
            </div>
            <div className={styles['image-container']}>
                <Skeleton className={styles['image']} />
            </div>
        </div>
    );
};

const UBCaseStudiesSkeletonNarrow = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles['logo-sm']}>
                <Skeleton className={styles['logo']} />
            </div>
            <div className={styles['image-container']}>
                <Skeleton className={styles['image']} />
            </div>
            <div className={styles['content-container']}>
                <Skeleton className={styles['description']} />
                <Skeleton className={styles['description']} />
                <div className={styles['stats-container']}>
                    <div className={styles['stats']}>
                        <Skeleton className={styles['stat-title']} />
                        <Skeleton className={styles['stat-description']} />
                        <Skeleton className={styles['stat-description']} />
                    </div>
                    <div className={styles['stats']}>
                        <Skeleton className={styles['stat-title']} />
                        <Skeleton className={styles['stat-description']} />
                        <Skeleton className={styles['stat-description']} />
                    </div>
                </div>
                <Skeleton className={styles['cta-button']} />
                <Skeleton className={styles['controls']} />
            </div>
        </div>
    );
};
