import classNames from 'classnames';
import React from 'react';

import {UFBNoticeClickEvent, UFBNoticeImpressionEvent} from '@udemy/browse-event-tracking';
import {Tracker, TrackImpression} from '@udemy/event-tracking';
import {useI18n} from '@udemy/i18n';
import ArrowRightIcon from '@udemy/icons/dist/arrow-right.ud-icon';
import {Button, Image} from '@udemy/react-core-components';

import styles from './ub-reports-banner.module.less';

export interface UBReportsBannerProps {
    className?: string;
    heading?: string;
    description?: string;
    contentUrl?: string;
    contentUrlText?: string;
    image?: {
        url: string;
        altText?: string;
    };
    trackingId?: string;
}

export const UBReportsBanner = ({
    className,
    heading,
    description,
    contentUrl,
    contentUrlText,
    image,
}: UBReportsBannerProps) => {
    const {locale} = useI18n();

    const getEventParams = () => {
        return {
            locale,
            placement: 'learning_trends_report',
            url: contentUrl,
        };
    };

    const trackImpression = () => {
        Tracker.publishEvent(new UFBNoticeImpressionEvent(getEventParams()));
    };

    const trackClick = () => {
        Tracker.publishEvent(new UFBNoticeClickEvent(getEventParams()));
    };

    return (
        <TrackImpression trackFunc={trackImpression}>
            <div className={classNames(styles['ub-reports-banner'], className)}>
                <div>
                    {/* Adding empty alt text as this is a decorative image */}
                    <Image src={image?.url} alt="" />
                </div>
                <div className={styles['cta-section']}>
                    <div className={'ud-heading-serif-xxl'}>{heading}</div>
                    <div
                        className={classNames(styles.subtitle, 'ud-text-lg')}
                        dangerouslySetInnerHTML={{__html: description ?? ''}}
                    />
                    <Button
                        className={styles.cta}
                        componentClass="a"
                        udStyle="secondary"
                        href={contentUrl}
                        target="_blank"
                        rel="noopener"
                        onClick={trackClick}
                    >
                        {contentUrlText}
                        <ArrowRightIcon label={false} />
                    </Button>
                </div>
            </div>
        </TrackImpression>
    );
};
