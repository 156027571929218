export function isInternalUrl(url: string, domain = 'https://www.udemy.com') {
    return url.startsWith(domain);
}

export type DynamicWebContentQueryParams = {
    content_preview_overrides?: boolean | string;
    preview_mode?: boolean;
};

export function getDynamicWebContentQueryParams() {
    const params: DynamicWebContentQueryParams = {
        content_preview_overrides: false,
        preview_mode: false,
    };

    if (typeof window === 'undefined') {
        return params;
    }

    const searchParams = new URLSearchParams(window.location.search);

    params.content_preview_overrides = searchParams.get?.('content_preview_overrides') ?? false;
    params.preview_mode = searchParams.get?.('preview_mode') === 'true';

    return params;
}
