import {useI18n} from '@udemy/i18n';
import {udLink} from '@udemy/ud-data';

import {
    DynamicWebContentCaseStudiesQuery,
    DynamicWebContentPageType,
    useDynamicWebContentCaseStudiesQuery,
} from 'src/generated/graphql';
import {useDynamicWebContentQueryParams} from 'src/hooks/useDynamicWebContentQueryParams';
import {getDynamicWebContentQueryParams} from 'src/lib/url-utils';

import {UBCaseStudiesSkeleton} from './ub-case-studies-skeleton.react-component';
import {UBCaseStudies} from './ub-case-studies.react-component';
import {StatProps, UBCaseStudyProps} from './ub-case-study-slide.react-component';

// List of locales where the case studies module should be hidden
// Note: This is a stopgap solution until the CMS team provides support for bypassing the server-side English fallback
const HIDE_CASE_STUDIES_MODULE = [
    'ja_JP',
    'nl_NL',
    'pl_PL',
    'vi_VN',
    'zh_CN',
    'zh_TW',
    'it_IT',
    'ko_KR',
    'tr_TR',
    'id_ID',
    'ro_RO',
    'ru_RU',
    'th_TH',
];

/**
 * Validates that a CTA URL is available for all case studies returned by the CMS
 * If any case study is missing a CTA URL, the data is considered invalid and we
 * assume that case studies are not available for that country. This will ensure that
 * we hide the case studies module whenever we don't have valid links to show users.
 * @param data
 */
const validateCaseStudiesData = (locale: string, data?: DynamicWebContentCaseStudiesQuery) => {
    // Temporary solution: Hide module in certain locales
    // TODO: Replace this once CMS supports manually hiding modules instead of English fallback
    if (HIDE_CASE_STUDIES_MODULE.includes(locale)) {
        return false;
    }

    if (!data?.dynamicWebContent?.contents || !data?.dynamicWebContent?.contents.length) {
        return false;
    }
    const {dynamicWebContent} = data;
    return dynamicWebContent.contents?.every((caseStudy) => {
        return (
            caseStudy?.contentUrl &&
            caseStudy?.contentUrlText &&
            caseStudy?.heading &&
            caseStudy?.image?.url &&
            caseStudy?.secondaryImage?.url &&
            caseStudy?.contents?.every((stat) => stat?.heading && stat?.description)
        );
    });
};

export const UBCaseStudiesCMSWrapper = () => {
    const {gettext} = useI18n();
    const {locale} = useI18n();
    const FALLBACK_CASE_STUDIES_DATA: (UBCaseStudyProps & {trackingId: string})[] = [
        {
            buttonHref:
                'https://business.udemy.com/case-studies/booz-allen/?ref=ub-home-customerstories--variant-102500',
            buttonText: gettext('Read full story'),
            heading: gettext(
                'Booz Allen Hamilton Unlocks Talent Retention and Productivity Through Upskilling',
            ),
            image: {
                altText: 'Booz Allen Hamilton',
                url: udLink.toStorageStaticAsset(
                    'lohp/ub-case-studies/v1/boozallenhamilton-2x.png',
                ),
            },
            secondaryImage: {
                url: udLink.toStorageStaticAsset(
                    'lohp/ub-case-studies/v1/logo-boozallenhamilton-2.svg',
                ),
                altText: 'Booz Allen Hamilton',
            },
            stats: [
                {
                    stat: gettext('93%'),
                    label: gettext('retention rate among participating employees'),
                },
                {
                    stat: gettext('65%'),
                    label: gettext('of learners noted a positive impact on their productivity'),
                },
            ],
            trackingId: 'booz_allen_feature',
        },
        {
            buttonHref:
                'https://business.udemy.com/case-studies/capital-one/?ref=ub-home-customerstories--variant-102500',
            buttonText: gettext('Read full story'),
            heading: gettext(
                'Capital One Accelerates Transformational Learning through Udemy Business',
            ),
            image: {
                altText: 'Capital One',
                url: udLink.toStorageStaticAsset('lohp/ub-case-studies/v1/capitalone-2x.png'),
            },
            secondaryImage: {
                url: udLink.toStorageStaticAsset('lohp/ub-case-studies/v1/logo-capitalone-2.svg'),
                altText: 'Capital One',
            },
            stats: [
                {
                    stat: gettext('92.5%'),
                    label: gettext('of learners rated Udemy as “very helpful” to their success'),
                },
                {
                    stat: gettext('65%'),
                    label: gettext('increase in retention for in-demand tech roles'),
                },
            ],
            trackingId: 'capital_one_feature',
        },
        {
            buttonHref:
                'https://business.udemy.com/case-studies/eventbrite/?ref=ub-home-customerstories--variant-102500',
            buttonText: gettext('Read full story'),
            heading: gettext(
                'Eventbrite Navigates Change Through Skill-Building and Leadership Development',
            ),
            image: {
                altText: 'Eventbrite',
                url: udLink.toStorageStaticAsset('lohp/ub-case-studies/v1/eventbrite-1x.png'),
            },
            stats: [
                {
                    stat: gettext('4,800+'),
                    label: gettext(
                        'increase in employee enrollments for professional development courses',
                    ),
                },
                {
                    stat: gettext('65%'),
                    label: gettext(
                        'revenue growth supported by a business model backed by learning',
                    ),
                },
            ],
            secondaryImage: {
                altText: 'Eventbrite',
                url: udLink.toStorageStaticAsset('lohp/ub-case-studies/v1/logo-eventbrite-1.svg'),
            },
            trackingId: 'eventbrite_feature',
        },
        {
            buttonHref:
                'https://business.udemy.com/case-studies/toyota-tsusho-south-pacific-holdings/?ref=ub-home-customerstories--variant-102500',
            buttonText: gettext('Read full story'),
            heading: gettext('Toyota Tsusho Enhances its L&D Program to Improve Employee Outcomes'),
            image: {
                altText: 'Toyota Tsusho',
                url: udLink.toStorageStaticAsset('lohp/ub-case-studies/v1/toyota-1x.png'),
            },
            stats: [
                {
                    stat: gettext('50%'),
                    label: gettext('training cost reduction per person'),
                },
                {
                    stat: gettext('+7,000'),
                    label: gettext('hours of upskilling'),
                },
            ],
            secondaryImage: {
                altText: 'Toyota Tsusho',
                url: udLink.toStorageStaticAsset('lohp/ub-case-studies/v1/logo-toyota-1.svg'),
            },
            trackingId: 'toyota_feature',
        },
    ];
    const queryArgs = useDynamicWebContentQueryParams({
        ...getDynamicWebContentQueryParams(),
        pageType: DynamicWebContentPageType.LoggedOutHomePage,
    });
    const response = useDynamicWebContentCaseStudiesQuery(queryArgs);
    if (response.isLoading) {
        return <UBCaseStudiesSkeleton />;
    }
    // Hide unit if valid case studies data is valid or should not be displayed in a given language
    if (!validateCaseStudiesData(locale, response?.data)) {
        return null;
    }
    let data = FALLBACK_CASE_STUDIES_DATA;
    if (response.data?.dynamicWebContent?.contents) {
        data = response.data?.dynamicWebContent?.contents?.map((caseStudy) => {
            return {
                buttonHref: caseStudy?.contentUrl as string,
                buttonText: caseStudy?.contentUrlText ?? gettext('Read full story'),
                heading: caseStudy?.heading as string,
                image: {
                    // Adding empty alt text as this is a decorative image
                    altText: '',
                    url: caseStudy?.image?.url as string,
                },
                secondaryImage: {
                    // Adding empty alt text as this is a decorative image
                    altText: '',
                    url: caseStudy?.secondaryImage?.url as string,
                },
                stats: caseStudy?.contents?.map((stat) => {
                    return {
                        stat: stat?.heading as string,
                        label: stat?.description as string,
                    };
                }) as StatProps[],
                trackingId: caseStudy?.trackingId as string,
            };
        });
    }
    return <UBCaseStudies data={data} />;
};
