import React from 'react';

import {TrackImpression} from '@udemy/event-tracking';
import {useFormatNumber, useI18n} from '@udemy/i18n';
import NextIcon from '@udemy/icons/dist/next.ud-icon';
import {Button, ButtonProps} from '@udemy/react-core-components';
import {BlockSkeleton} from '@udemy/react-reveal-components';

import styles from './top-skill.module.less';
import {Topic, TrackingContext} from './types';

export const TopSkill = ({
    topic,
    loading = false,
    trackingContent,
}: {topic: Topic; loading?: boolean; trackingContent?: TrackingContext} & Pick<
    ButtonProps,
    'onClick'
>) => {
    const {gettext, interpolate, ninterpolate} = useI18n();
    const {formatNumber} = useFormatNumber();

    return loading ? (
        <div className={styles['top-skill']}>
            <BlockSkeleton
                className={styles['skeleton']}
                parentSkeletonProps={{className: styles['parent-skeleton']}}
            />
        </div>
    ) : (
        <TrackImpression trackFunc={() => trackingContent?.trackImpressionFunc?.(topic)}>
            <div className={styles['top-skill']}>
                <h2 className={styles['title']}>
                    {interpolate(gettext('%(topic)s is a top skill'), {topic: topic.title}, true)}
                </h2>
                <Button
                    componentClass="a"
                    udStyle="link"
                    size={'large'}
                    className={styles['cta']}
                    href={topic.url}
                    onClick={() => trackingContent?.trackItemClickFunc?.(topic)}
                >
                    {interpolate(gettext('See %(topic)s courses'), {topic: topic.title}, true)}
                    <NextIcon label={false} />
                </Button>
                <span className={styles['stat']}>
                    {ninterpolate(
                        '%(statValue)s learner',
                        '%(statValue)s learners',
                        topic.stat_value,
                        {statValue: formatNumber(topic.stat_value)},
                    )}
                </span>
            </div>
        </TrackImpression>
    );
};
