import React from 'react';

import ArrowRightIcon from '@udemy/icons/dist/arrow-right.ud-icon';
import {Button, Image} from '@udemy/react-core-components';

import styles from './ub-case-study-slide.module.less';

export interface StatProps {
    label: string;
    stat: string;
}

export interface ImageProps {
    /** Image alt text */
    altText: string;
    /** Image url */
    url: string;
}

export interface LogoProps {
    /** Logo alt text */
    altText: string;
    /** Logo image url */
    url: string;
}

export interface UBCaseStudyProps {
    /** Href for the for the CTA anchor tag */
    buttonHref: string;
    /** Text for the CTA anchor tag */
    buttonText: string;
    /** Heading for the Case Study slide */
    heading: string;
    /** Case Study image */
    image: ImageProps;
    /** Case Study Company logo image */
    secondaryImage: LogoProps;
    /** Stats for the Case Study slide */
    stats: StatProps[];
}

export interface UBCaseStudySlideProps extends UBCaseStudyProps {
    /** Optional onClick for CTA */
    buttonOnClick?: React.MouseEventHandler<HTMLElement>;
}

/**
 * ### UBCaseStudySlide
 *
 * Slide used for Udemy Business Case Studies on LOHP
 *
 */
export const UBCaseStudySlide = (props: UBCaseStudySlideProps) => {
    const {
        buttonHref,
        buttonOnClick,
        buttonText,
        heading,
        image,
        secondaryImage: logo,
        stats,
    } = props;

    const logoComponent = <Image src={logo.url} alt={logo.altText} />;

    return (
        <section className={styles.wrapper}>
            <div className={styles['content-container']}>
                <div className={styles['logo-lg']}>{logoComponent}</div>
                <div className={styles['heading']}>{heading}</div>
                <div className={styles['stats-container']}>
                    {stats.map(({stat, label}) => (
                        <div className={styles['stat']} key={label}>
                            <div className={styles['stat-value']}>{stat}</div>
                            <div className={styles['stat-label']}>{label}</div>
                        </div>
                    ))}
                </div>
                <Button
                    className={styles['button']}
                    componentClass="a"
                    href={buttonHref}
                    onClick={buttonOnClick}
                    target={'_blank'}
                >
                    {buttonText} <ArrowRightIcon label={false} />
                </Button>
            </div>
            <div className={styles['image-container']}>
                <Image className={styles['image-square']} alt={image.altText} src={image.url} />
            </div>
            <div className={styles['logo-sm']}>{logoComponent}</div>
        </section>
    );
};
