import React from 'react';

import {UFBNoticeImpressionEvent, UFBNoticeClickEvent} from '@udemy/browse-event-tracking';
import {Tracker, TrackImpression} from '@udemy/event-tracking';
import {HeroBanner} from '@udemy/shared-one-udemy-components';
import {useUDData} from '@udemy/ud-data';

import {UBCaseStudySlide, UBCaseStudyProps} from './ub-case-study-slide.react-component';

export interface UBCaseStudiesProps {
    data: (UBCaseStudyProps & {trackingId: string})[];
}

export const UBCaseStudies = ({data}: UBCaseStudiesProps) => {
    const {request: udRequest} = useUDData();

    const createTrackCTAClickHandler = (url: string) => {
        return () => {
            Tracker.publishEvent(
                new UFBNoticeClickEvent({
                    locale: udRequest.locale,
                    placement: 'ub-home-customerstories',
                    url: url,
                }),
            );
        };
    };

    const createTrackImpressionHandler = (url: string) => {
        return () => {
            Tracker.publishEvent(
                new UFBNoticeImpressionEvent({
                    locale: udRequest.locale,
                    placement: 'ub-home-customerstories',
                    url: url,
                }),
            );
        };
    };

    return (
        <HeroBanner showSlideIndicator={true}>
            {data?.map((slideProps, index) => (
                <TrackImpression
                    key={index}
                    trackFunc={createTrackImpressionHandler(slideProps.trackingId)}
                    visibilityThreshold={0.5}
                >
                    <div>
                        <UBCaseStudySlide
                            buttonOnClick={createTrackCTAClickHandler(slideProps.buttonHref)}
                            {...slideProps}
                        />
                    </div>
                </TrackImpression>
            ))}
        </HeroBanner>
    );
};
