import classNames from 'classnames';
import React from 'react';

import {useMatchMedia} from '@udemy/hooks';
import {Skeleton} from '@udemy/react-reveal-components';

import skeletonStyles from './features-skeleton.module.less';
import {FeaturesTabPanelSkeleton} from './features-tab-panel.react-component';
import {FeaturesTabSkeleton} from './features-tab.react-component';
import styles from './value-props-and-features.module.less';

export const ValuePropsAndFeaturesSkeleton = () => {
    let content;
    const is750Max = useMatchMedia('(max-width: 750px)');
    const renderTabSkeleton = () => {
        return (
            <>
                <div
                    role="tablist"
                    aria-labelledby="values-props-and-features-title"
                    aria-orientation="vertical"
                    className={styles['values-column']}
                >
                    {Array.from(Array(4).keys()).map((index) => (
                        <FeaturesTabSkeleton key={index} />
                    ))}
                </div>
                <div className={styles.panels} aria-live="polite">
                    <FeaturesTabPanelSkeleton />
                </div>
            </>
        );
    };

    const renderTitleDescriptionSkeleton = ({
        headingSkeletonRows,
        descriptionSkeletonRows,
        renderSlideIndicatorSkeleton = false,
    }: {
        headingSkeletonRows: number;
        descriptionSkeletonRows: number;
        renderSlideIndicatorSkeleton?: boolean;
    }) => {
        return (
            <>
                <div
                    id="values-props-and-features-title-skeleton"
                    className={classNames('ud-heading-serif-xxl', 'ud-text-responsive')}
                >
                    {Array.from(Array(headingSkeletonRows).keys()).map((index) => (
                        <Skeleton key={index} className={skeletonStyles['heading-skeleton']} />
                    ))}
                </div>
                <div>
                    {Array.from(Array(descriptionSkeletonRows).keys()).map((index) => (
                        <Skeleton key={index} className={skeletonStyles['description-skeleton']} />
                    ))}
                    <Skeleton className={skeletonStyles['description-skeleton-secondary']} />
                    {renderSlideIndicatorSkeleton && (
                        <Skeleton className={skeletonStyles['slide-indicator-skeleton']} />
                    )}
                </div>
            </>
        );
    };

    const renderCarouselSkeleton = () => (
        <>
            <FeaturesTabPanelSkeleton />
            {renderTitleDescriptionSkeleton({
                headingSkeletonRows: 1,
                descriptionSkeletonRows: 1,
                renderSlideIndicatorSkeleton: true,
            })}
        </>
    );

    if (is750Max) {
        content = renderCarouselSkeleton();
    } else {
        content = renderTabSkeleton();
    }

    const headingSkeletonRows = is750Max ? 2 : 1;
    const descriptionSkeletonRows = is750Max ? 3 : 1;
    return (
        <div>
            {renderTitleDescriptionSkeleton({headingSkeletonRows, descriptionSkeletonRows})}
            <div className={styles.content}>{content}</div>
        </div>
    );
};
