import {useI18n} from '@udemy/i18n';
import {useUDData, useUDLink} from '@udemy/ud-data';

import {
    DynamicWebContent,
    DynamicWebContentPageType,
    useDynamicWebContentPromoItemQuery,
} from 'src/generated/graphql';
import {useDynamicWebContentQueryParams} from 'src/hooks/useDynamicWebContentQueryParams';
import {getDynamicWebContentQueryParams} from 'src/lib/url-utils';

import {UBReportsBanner} from './ub-reports-banner.react-component';

export interface UBReportsBannerWrapperProps {
    className?: string;
}

export const UBReportsBannerCMSWrapper = ({className}: UBReportsBannerWrapperProps) => {
    const {gettext} = useI18n();
    const udLink = useUDLink();
    const {Config} = useUDData();
    const priceCountry = Config?.price_country?.id;

    const FALLBACK_UB_REPORTS_BANNER = {
        heading: gettext('Top trends for the future of work'),
        description: gettext(
            "GenAI and leadership are at the core of today's skills-based economy. Get the 2024 Global Learning & Skills Trends Report to learn more.",
        ),
        contentUrl:
            'https://business.udemy.com/2024-global-learning-skills-trends-report/?ref=learning_trends_report-variant-102500',
        contentUrlText: gettext('Get the report'),
        image: {
            url: udLink.toStorageStaticAsset('lohp/ub-reports/v1/2024-ub-reports.png'),
            altText: gettext('Udemy Business reports'),
        },
        trackingId: 'ub-reports-banner',
    };
    const queryArgs = useDynamicWebContentQueryParams({
        ...getDynamicWebContentQueryParams(),
        pageType: DynamicWebContentPageType.LoggedOutHomePage,
    });
    const response = useDynamicWebContentPromoItemQuery(queryArgs);
    if (response.isLoading) {
        return null;
    }
    const data = response?.data ?? {dynamicWebContent: FALLBACK_UB_REPORTS_BANNER};
    // If data is missing for critical fields, use the fallback data
    const useFallback =
        !data?.dynamicWebContent ||
        !data?.dynamicWebContent.heading ||
        !data?.dynamicWebContent.description ||
        !data?.dynamicWebContent.contentUrl ||
        !data?.dynamicWebContent.image ||
        !data?.dynamicWebContent.contentUrlText;
    // Hide Reports banner if the user is in a country where no UB report is available
    // In other words, fallback is only valid in the US and India
    if (priceCountry && !['US', 'IN'].includes(priceCountry) && useFallback) {
        return null;
    }

    const dynamicWebContent: DynamicWebContent = useFallback
        ? {...FALLBACK_UB_REPORTS_BANNER}
        : data?.dynamicWebContent;

    const heading = dynamicWebContent?.heading as string;
    const description = dynamicWebContent?.description as string;
    const contentUrl = dynamicWebContent?.contentUrl as string;
    const contentUrlText = dynamicWebContent?.contentUrlText as string;
    const image = (dynamicWebContent?.image as {url: string; altText: string | undefined}) && {
        url: dynamicWebContent.image?.url,
        altText: dynamicWebContent.image?.altText as string,
    };
    const trackingId = dynamicWebContent?.trackingId as string;

    return (
        <UBReportsBanner
            className={className}
            heading={heading}
            description={description}
            contentUrl={contentUrl}
            contentUrlText={contentUrlText}
            image={image}
            trackingId={trackingId}
        />
    );
};
