import {
    PAGE_TYPE_LOGGED_OUT_HOMEPAGE,
    PAGE_TYPE_PERSONALIZED_LOGGED_OUT_HOMEPAGE,
} from '@udemy/discovery-api';
import {useMatchMedia} from '@udemy/hooks';
import {CourseUnit, DiscoveryUnitsContainer} from '@udemy/react-discovery-units';
import {isFirstTimeVisitor} from '@udemy/shared-utils';

import styles from './discovery-units.module.less';

export const DiscoveryUnits = () => {
    const hasCoarsePointer = useMatchMedia('(any-pointer: coarse)');
    const hasFinePointer = useMatchMedia('(any-pointer: fine)');

    const getUnitComponentAndProps = () => {
        return {
            component: CourseUnit,
            componentProps: {
                renderExperimentalCourseCards: true,
                layout: 'singlerow',
                titleTypography: 'ud-heading-serif-xxl ud-text-responsive',
                showPager: !!hasFinePointer,
                allowScroll: !!hasCoarsePointer,
            },
        };
    };

    return (
        <DiscoveryUnitsContainer
            className={styles['discovery-unit']}
            itemCount={18}
            pageType={
                isFirstTimeVisitor()
                    ? PAGE_TYPE_LOGGED_OUT_HOMEPAGE
                    : PAGE_TYPE_PERSONALIZED_LOGGED_OUT_HOMEPAGE
            }
            getUnitComponentAndProps={getUnitComponentAndProps}
        />
    );
};
