/* eslint-disable @typescript-eslint/no-unused-vars */
import {createFetcher, ErrorPolicy} from '@udemy/graphql';

import {getRuntimeConfig} from '../pages/api/config.api';

export const fetcher = createFetcher({
    getUrl: () => {
        const fullGraphQLUrl = new URL(getRuntimeConfig('GRAPHQL_URL'), 'https://www.udemy.com');
        const isServer = typeof window === 'undefined';
        return isServer
            ? fullGraphQLUrl.toString()
            : `${window.location.origin}${fullGraphQLUrl.pathname}`;
    },
    getHeaders: (options = {}) => {
        // eslint-disable-next-line prefer-const
        let customHeaders = {};
        // <<Stencil::Block(getHeadersCustom)>>

        // <</Stencil::Block>>
        return customHeaders;
    },
    errorPolicy: ErrorPolicy.ALLOW_PARTIAL,
});
