import classNames from 'classnames';
import React from 'react';

import {BackendSourceOptions, discoveryTracker} from '@udemy/browse-event-tracking';
import {ClickEvent, Tracker} from '@udemy/event-tracking';
import {useMatchMedia} from '@udemy/hooks';
import {useI18n} from '@udemy/i18n';
import TrendingIcon from '@udemy/icons/dist/trending-graph.ud-icon';
import {Button, ButtonProps} from '@udemy/react-core-components';

import {TopSkill} from './top-skill.react-component';
import styles from './trending-skills.module.less';
import {TrendingTopics} from './trending-topics.react-component';
import {CategoryWithTopics, Topic, TrackingContext} from './types';
import {URLS, useTrendingTopicsByCategories} from './use-trending-topics-by-categories';

const AllSkillsButton = ({onClick}: Pick<ButtonProps, 'onClick'>) => {
    return (
        <Button
            componentClass="a"
            href={URLS.FEATURED_TOPICS}
            udStyle={'secondary'}
            onClick={onClick}
        >
            {gettext('Show all trending skills')}
            <TrendingIcon label={false} />
        </Button>
    );
};
interface TrendingSkillsProps {
    /**
     * Optional class name to add to the root element
     */
    className?: string;

    /**
     * Optional class name to add to the column content element. This will help other people to configure
     * their own styles for the content inside the columns with respect to #topics that rendered inside of it
     */
    columnContentClassName?: string;

    /**
     * Optional number of topics to render for each category, default is 3
     */
    topicCountPerCategory?: number;
}

export const TrendingSkills = ({
    className = '',
    columnContentClassName = '',
    topicCountPerCategory = 3,
}: TrendingSkillsProps) => {
    const isMobile = useMatchMedia('sm-max');
    const {gettext} = useI18n();
    const {topSkillTopic, trendingTopicsByCategory, isLoading} =
        useTrendingTopicsByCategories(topicCountPerCategory);

    const trackAllSkillsCTAClick = () => {
        Tracker.publishEvent(
            new ClickEvent({
                componentName: 'showAllTrendingSkills',
            }),
        );
    };

    const getTrackingContext = (): TrackingContext => {
        return {
            trackImpressionFunc: (topic: Topic, position?: number) =>
                discoveryTracker.trackDiscoveryImpression(
                    {
                        item: {
                            ...topic,
                            _class: 'course_topic',
                            type: 'course_topic',
                            tracking_id: String(topic?.id),
                        },
                    },
                    {
                        backendSource: 'discovery' as BackendSourceOptions,
                        index: position,
                    },
                ),
            trackItemClickFunc: (topic: Topic, position?: number) =>
                discoveryTracker.trackDiscoveryItemClickEvent(
                    {
                        item: {
                            ...topic,
                            _class: 'course_topic',
                            type: 'course_topic',
                            tracking_id: String(topic?.id),
                        },
                    },
                    {
                        backendSource: 'discovery' as BackendSourceOptions,
                        index: position,
                    },
                ),
        };
    };

    const filterTopSkillTopicFromTrendingTopics = React.useCallback(
        (categoryTopics: CategoryWithTopics[]) => {
            return categoryTopics.map((category) => {
                return {
                    ...category,
                    topics: category.topics.filter((topic) => topic.id !== topSkillTopic?.id),
                };
            });
        },
        [topSkillTopic],
    );

    /**
     * We don't want to show top skill in the trending topics per category section since we're already showing it
     * in the Top Skill Section
     */
    const filteredTrendingTopicsByCategory =
        filterTopSkillTopicFromTrendingTopics(trendingTopicsByCategory);

    const TrendingTopicsComponent = (
        <TrendingTopics
            loading={isLoading}
            categoryWithTopics={filteredTrendingTopicsByCategory}
            topicCountToRender={topicCountPerCategory}
            // Render 3 categories when loading
            loadingCategoryCountToRender={3}
            trackingContext={getTrackingContext()}
        />
    );

    return (
        <div className={classNames(styles['trending-skills'], className)}>
            <div className={styles['header-with-divider']}>
                <h1 className={'ud-heading-serif-xxl'}>{gettext('Trending Now')}</h1>
            </div>
            <div className={styles['content']}>
                <div className={styles['top-skill-with-cta']}>
                    <TopSkill
                        loading={isLoading || !topSkillTopic}
                        topic={topSkillTopic as Topic}
                        trackingContent={getTrackingContext()}
                    />
                    {!isMobile && <AllSkillsButton onClick={trackAllSkillsCTAClick} />}
                </div>

                {isMobile ? (
                    <div
                        className={classNames(
                            styles['wrapped-trending-topics'],
                            columnContentClassName,
                        )}
                    >
                        {TrendingTopicsComponent}
                    </div>
                ) : (
                    TrendingTopicsComponent
                )}
            </div>
            {isMobile && <AllSkillsButton onClick={trackAllSkillsCTAClick} />}
        </div>
    );
};
