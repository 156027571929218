import {observer} from 'mobx-react';
import React from 'react';

import {TrackerInitializer} from '@udemy/event-tracking';
import {Footer} from '@udemy/footer';
import {useFeatures} from '@udemy/react-experimentation-provider';
import {Header, SearchBarMXEnum} from '@udemy/react-header';
import {Toaster} from '@udemy/react-messaging-components';
import {useStores} from '@udemy/store-provider';

// TODO: decouple smartbar from header
// import {SmartBar} from '@udemy/smart-bar';
import {RootStore} from 'src/stores/root-store';

import {NEW_SEARCH_BAR_MX} from '../../lib/experimentation-helper';
import styles from './layout.module.less';

export const getLayout = (page: React.ReactElement) => {
    return <Layout>{page}</Layout>;
};

const Layout = observer(({children}: {children: React.ReactElement}) => {
    const [rootStore] = useStores([RootStore]);
    const {isLoading, featureVariantAssignments: features} = useFeatures([NEW_SEARCH_BAR_MX]);
    const showNewSearchBar = features.get('new_search_bar_mx')?.configuration.showNewSearchBarMX;
    /**
     * This is for mobile webview renders. Normally we shouldn't be going to here but this is a legacy support from
     * django that integrated all over our apps so we need to support it.
     */
    const shouldRenderHeaderAndFooter = () => {
        if (typeof window !== 'undefined') {
            const displayTypeParam = new URLSearchParams(window.location.search).get(
                'display_type',
            );
            return displayTypeParam !== 'mobile_app';
        }
        return true;
    };
    let showTwoRowSearchBarMX = SearchBarMXEnum.NotLoaded;
    if (!isLoading) {
        showTwoRowSearchBarMX = showNewSearchBar ? SearchBarMXEnum.NewBar : SearchBarMXEnum.OldBar;
    }

    return (
        <>
            {/*<SmartBar /> */}
            <div className={styles['container']}>
                {shouldRenderHeaderAndFooter() && (
                    <Header
                        {...rootStore.headerData}
                        showTwoRowSearchBarMX={showTwoRowSearchBarMX}
                    />
                )}
                <main className={styles['content']}>{children}</main>
                {shouldRenderHeaderAndFooter() && (
                    <Footer
                        clientProps={rootStore.footerData}
                        serverProps={rootStore.footerData}
                        limitedConsumptionTrial={true}
                    />
                )}
                <Toaster />
            </div>
            <TrackerInitializer />
        </>
    );
});
