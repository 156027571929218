import classNames from 'classnames';
import React, {useRef, useState} from 'react';

import {Keys} from '@udemy/design-system-utils';
import {ClickEvent, Tracker} from '@udemy/event-tracking';
import {useMatchMedia} from '@udemy/hooks';
import {
    AutoPlayCarousel,
    AutoPlayCarouselIntervalEvent,
    CarouselMutationEvent,
} from '@udemy/react-structure-components';
import {SlideIndicatorDashes} from '@udemy/shared-one-udemy-components';

import {FeaturesTabContent} from './features-tab-content.react-component';
import {FeaturesTabPanel, FeaturesTabPanelProps} from './features-tab-panel.react-component';
import {FeaturesTab, FeaturesTabProps} from './features-tab.react-component';
import styles from './value-props-and-features.module.less';

export interface ValuePropsAndFeature {
    featureTabProps: FeaturesTabProps;
    featureTabPanelProps: FeaturesTabPanelProps;
}

export interface ValuePropsAndFeaturesProps {
    heading: string;
    description?: string;
    features: ValuePropsAndFeature[];
    tabContainerRef: React.RefObject<HTMLDivElement>;
}

/**
 * ### ValuePropsAndFeatures
 */
export const ValuePropsAndFeatures = ({
    heading,
    description,
    features,
}: ValuePropsAndFeaturesProps) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [visiblePageIndex, setVisiblePageIndex] = useState(0);
    const [paused, setPaused] = useState(false);
    const is750Max = useMatchMedia('(max-width: 750px)');
    const tabContainerRef = useRef<HTMLDivElement>(null);
    let content;

    /** This handler is used for the AutoPlayCarousel rendering of this component */
    const handleMutationEvent = (event: CarouselMutationEvent) => {
        setVisiblePageIndex(event.visiblePageIndex as number);
    };

    /** This handler is used for the AutoPlayCarousel rendering of this component */
    const handleIntervalUpdate = (event: AutoPlayCarouselIntervalEvent) => {
        setPaused(event.isPaused);
    };

    /** This tab last has a vertical orientation on desktop, thus we look at up and down key navigation */
    const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.keyCode === Keys.UP || event.keyCode === Keys.DOWN) {
            const tabs = tabContainerRef.current?.querySelectorAll(
                '[role="tab"]',
            ) as NodeListOf<HTMLElement>;

            if (tabs) {
                const newIndex = selectedTabIndex + (event.keyCode === Keys.DOWN ? 1 : -1);
                const targetTab = tabs[newIndex];
                if (targetTab) {
                    targetTab.click();
                    targetTab.focus();
                    setSelectedTabIndex(newIndex);
                }
            }
        }
    };

    const trackSelectedTabChange = () => {
        Tracker.publishEvent(new ClickEvent({componentName: 'features_module_tab'}));
    };

    const renderTab = () => {
        const onTabClick = (index: number) => {
            setSelectedTabIndex(index);
            trackSelectedTabChange();
        };
        return (
            <>
                <div
                    role="tablist"
                    aria-labelledby="values-props-and-features-title"
                    aria-orientation="vertical"
                    ref={tabContainerRef}
                    className={styles['values-column']}
                >
                    {features.map((feature, index) => {
                        return (
                            <FeaturesTab
                                key={index}
                                {...feature.featureTabProps}
                                selected={selectedTabIndex === index}
                                onClick={() => onTabClick(index)}
                                onKeyDown={onKeyDown}
                            />
                        );
                    })}
                </div>
                <div className={styles.panels} aria-live="polite">
                    {features.map((feature, index) => {
                        return (
                            <div
                                id={`vpf-${index}`}
                                key={index}
                                role="tabpanel"
                                tabIndex={index}
                                aria-labelledby={`tab-${index}`}
                                hidden={selectedTabIndex !== index}
                            >
                                <FeaturesTabPanel key={index} {...feature.featureTabPanelProps} />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const renderHeading = () => (
        <>
            <h2 id="values-props-and-features-title" className={'ud-heading-serif-xxl'}>
                {heading ?? gettext('Learning focused on your goals')}
            </h2>
            {description && (
                <p className={classNames(styles.subtitle, 'ud-text-lg')}>{description}</p>
            )}
        </>
    );

    const renderCarousel = () => (
        <>
            <AutoPlayCarousel
                prioritizeTouch={true}
                onMutation={handleMutationEvent}
                onIntervalUpdate={handleIntervalUpdate}
            >
                {features.map((feature, index) => (
                    <div key={index} className={styles.slide}>
                        <FeaturesTabPanel {...feature.featureTabPanelProps} />
                        <FeaturesTabContent {...feature.featureTabProps} />
                    </div>
                ))}
            </AutoPlayCarousel>
            <SlideIndicatorDashes
                pageCount={features.length || 4}
                visiblePageIndex={visiblePageIndex}
                isPaused={paused}
            />
        </>
    );

    if (is750Max) {
        content = renderCarousel();
    } else {
        content = renderTab();
    }

    return (
        <div>
            {renderHeading()}
            <div className={styles.content}>{content}</div>
        </div>
    );
};
