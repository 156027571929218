import React from 'react';

import {UFBNoticeImpressionEvent} from '@udemy/browse-event-tracking';
import {Tracker} from '@udemy/event-tracking';
import {useI18n} from '@udemy/i18n';
import {useUDData} from '@udemy/ud-data';

import {
    DynamicWebContent,
    DynamicWebContentPageType,
    useDynamicWebContentFeaturesQuery,
} from 'src/generated/graphql';
import {useDynamicWebContentQueryParams} from 'src/hooks/useDynamicWebContentQueryParams';
import {getDynamicWebContentQueryParams} from 'src/lib/url-utils';

import {FeatureTabContentProps} from './features-tab-content.react-component';
import {FeaturesTabPanelProps} from './features-tab-panel.react-component';
import {FeaturesTabProps} from './features-tab.react-component';
import {ValuePropsAndFeaturesSkeleton} from './value-props-and-features-skeleton.react-component';
import {
    ValuePropsAndFeature,
    ValuePropsAndFeatures,
    ValuePropsAndFeaturesProps,
} from './value-props-and-features.react-component';

const CERTIFICATION_PREP_PAGE_HREF = '/browse/certification/';

export const isUBMarketingUrl = (url: string) =>
    url.includes('business.udemy.com') || url.includes('/udemy-business/');

export const isCertificationPrepUrl = (url: string) => url.includes(CERTIFICATION_PREP_PAGE_HREF);

/**
 * Validates the dynamic web content object has all the necessary fields to render the value props and features component.
 * @param dynamicWebContent
 */
export const validateDynamicWebContent = (dynamicWebContent?: DynamicWebContent): boolean => {
    if (!dynamicWebContent) {
        return false;
    }
    const isValidParent = !!dynamicWebContent.heading && !!dynamicWebContent.contents?.length;
    const validChildren = !!dynamicWebContent.contents?.every((content) => {
        return content?.heading && content?.description && content?.image?.url;
    });
    return isValidParent && validChildren;
};

export const ValuePropsAndFeaturesCMSWrapper = (props: {isAggregatorPageEnabled?: boolean}) => {
    const {gettext} = useI18n();
    const {request: udRequest} = useUDData();

    const trackUFBNoticeImpression = (url: string) => {
        // TODO: Update @udemy/organization package to include a utility that can determine if a URL is a UB marketing
        // URL. This will be used to determine if the URL is a UB marketing URL and if so, publish the UFBNoticeImpressionEvent.
        // This packaged utility will also need to account for Benesse and Korea URLs.
        if (isUBMarketingUrl(url)) {
            Tracker.publishEvent(
                new UFBNoticeImpressionEvent({
                    locale: udRequest.locale,
                    placement: 'features_module',
                    url,
                }),
            );
        }
    };

    const featuresFallback: ValuePropsAndFeaturesProps = {
        heading: gettext('Learning focused on your goals'),
        description:
            'Our features are thoughtfully designed for more practical and productive learning, making it easier for you, your team, or your organization to achieve outcomes and thrive.',
        tabContainerRef: {current: null},
        features: [
            {
                featureTabProps: {
                    id: 'hands-on-learning',
                    index: 0,
                    title: gettext('Hands-on training'),
                    icon: 'hands-on-practice',
                    description: gettext(
                        '<p>Upskill effectively with AI-powered coding exercises, practice tests, skills assessments, labs, and workspaces.</p>',
                    ),
                    ctaHref: undefined,
                    ctaText: undefined,
                    badgeText: undefined,
                    ariaControls: '',
                    trackImpressionFunc: undefined,
                },
                featureTabPanelProps: {
                    fallbackImage: {
                        imgKey: 'hands-on-learning',
                        imgAlt: 'Screenshot of the learner metrics interface',
                    },
                },
            },
            {
                featureTabProps: {
                    id: 'certification-prep',
                    index: 1,
                    title: gettext('Certification prep'),
                    icon: 'certificate',
                    description: gettext(
                        '<p>Prep for industry-recognized certifications by solving real-world challenges and earn badges along the way.</p>',
                    ),
                    ctaHref: CERTIFICATION_PREP_PAGE_HREF,
                    ctaText: undefined,
                    badgeText: undefined,
                    ariaControls: '',
                    trackImpressionFunc: undefined,
                },
                featureTabPanelProps: {
                    fallbackImage: {
                        imgKey: 'certification-prep',
                        imgAlt: 'Screenshot of an analytics interface',
                    },
                },
            },
            {
                featureTabProps: {
                    id: 'insights',
                    index: 2,
                    title: gettext('Insights and analytics'),
                    icon: 'empty-state-1',
                    description: gettext(
                        '<p>Fast-track goals with advanced insights plus a dedicated customer success team to help drive effective learning.</p>',
                    ),
                    ctaHref:
                        'https://business.udemy.com/analytics/?locale=en_US&ref=features_module-variant-102500&user_type=visitor',
                    ctaText: gettext('Find out more'),
                    badgeText: gettext('Enterprise Plan'),
                    ariaControls: '',
                    trackImpressionFunc: () => {
                        trackUFBNoticeImpression(
                            'https://business.udemy.com/analytics/?locale=en_US&ref=features_module-variant-102500&user_type=visitor',
                        );
                    },
                },
                featureTabPanelProps: {
                    fallbackImage: {
                        imgKey: 'insights-and-analytics',
                        imgAlt: 'Screenshot of an analytics interface',
                    },
                },
            },
            {
                featureTabProps: {
                    id: 'customizable',
                    index: 3,
                    title: gettext('Customizable content'),
                    icon: 'organizations-2',
                    description: gettext(
                        '<p>Create tailored learning paths for team and organization goals and even host your own content and resources.</p>',
                    ),
                    ctaHref:
                        'https://business.udemy.com/user-management/?locale=en_US&ref=features_module-variant-102500&user_type=visitor',
                    ctaText: gettext('Find out more'),
                    badgeText: gettext('Enterprise Plan'),
                    ariaControls: '',
                    trackImpressionFunc: () => {
                        trackUFBNoticeImpression(
                            'https://business.udemy.com/user-management/?locale=en_US&ref=features_module-variant-102500&user_type=visitor',
                        );
                    },
                },
                featureTabPanelProps: {
                    fallbackImage: {
                        imgKey: 'customizable',
                        imgAlt: 'Screenshot of an analytics interface',
                    },
                },
            },
        ],
    };

    /**
     * Adapts the dynamic web content object to the props needed by the ValuePropsAndFeatures component.
     * @param dynamicWebContent
     * @param locale
     */
    const adaptDynamicWebContent = (
        dynamicWebContent: DynamicWebContent,
    ): ValuePropsAndFeaturesProps => {
        const features: ValuePropsAndFeature[] =
            dynamicWebContent.contents?.map((content, index) => {
                let ctaHref = content?.contentUrl || '';

                // TODO: After we've productionized aggregator pages, we should remove this conditional
                // https://udemyjira.atlassian.net/browse/CVR-636
                // If aggregator page is not enabled, we should not show the certification prep page
                if (!props.isAggregatorPageEnabled && isCertificationPrepUrl(ctaHref)) {
                    ctaHref = '';
                }

                const featureTabContentProps: FeatureTabContentProps = {
                    title: content?.heading || '',
                    description: content?.description || '',
                    enterpriseBadge: !!content?.labelText || false,
                    ctaText: content?.contentUrlText || '',
                    ctaHref,
                    badgeText: content?.labelText || '',
                    trackImpressionFunc: () => {
                        trackUFBNoticeImpression(ctaHref);
                    },
                };

                const featureTabPanelProps: FeaturesTabPanelProps = {
                    fallbackImage: {
                        imgKey: 'hands-on-learning',
                        imgAlt: 'Screenshot of the learner metrics interface',
                    },
                    cmsImage: content?.image || undefined,
                };
                const featureTabProps: FeaturesTabProps = {
                    id: content?.trackingId ?? '',
                    index,
                    selected: false,
                    icon: undefined,
                    iconImage: content?.secondaryImage || undefined,
                    ariaControls: '',
                    ...featureTabContentProps,
                };
                return {featureTabProps, featureTabPanelProps};
            }) ?? [];
        return {
            heading: dynamicWebContent.heading ?? '',
            description: dynamicWebContent?.description || undefined,
            features,
            tabContainerRef: {current: null},
        };
    };
    const queryArgs = useDynamicWebContentQueryParams({
        ...getDynamicWebContentQueryParams(),
        pageType: DynamicWebContentPageType.LoggedOutHomePage,
    });
    const cmsFeatures = useDynamicWebContentFeaturesQuery(queryArgs);
    const isLoading = cmsFeatures.isLoading;
    const useFallback =
        !isLoading && !validateDynamicWebContent(cmsFeatures.data?.dynamicWebContent);
    const cmsFeaturesData: ValuePropsAndFeaturesProps =
        useFallback || !cmsFeatures.data?.dynamicWebContent
            ? featuresFallback
            : adaptDynamicWebContent(cmsFeatures.data?.dynamicWebContent);
    return isLoading ? (
        <ValuePropsAndFeaturesSkeleton />
    ) : (
        <ValuePropsAndFeatures {...cmsFeaturesData} />
    );
};
