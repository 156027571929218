import classNames from 'classnames';
import {Provider} from 'mobx-react';
import {useState} from 'react';

import {FunnelLogContextProvider, FunnelLogContextProviderProps} from '@udemy/funnel-tracking';
import {
    DiscoveryUnitsContainerStore,
    ProfessionalSkillsUnit as PSU,
    ProfessionalSkillsUnitProps as PSUProps,
} from '@udemy/react-discovery-units';
import {CourseCardSkeletonGroup} from '@udemy/react-reveal-components';
import {pxToRem} from '@udemy/styles';
import {useUDData} from '@udemy/ud-data';

import styles from './professional-skills-showcase.module.less';

export interface ProfessionalSkillsUnitProps {
    pageType: FunnelLogContextProviderProps['pageType'];
    contentPanelClassName?: string;
    loadingSkeleton?: React.ReactNode;
}

/**
 * @remarks A simple temporary skeleton for the PSU component. NEEDS TO BE REMOVED AFTER THE PSU REORDERING EXPERIMENT DONE
 * TODO: Please remove this after PSU re-ordering Experiment - https://udemyjira.atlassian.net/browse/TB-10066
 */
export const PSUSkeleton = ({withTitle = true}: {withTitle?: boolean}) => {
    const CustomCardWrapper = ({children}: {children: React.ReactNode}) => (
        <div
            style={{
                width: `${pxToRem(300)}rem`,
            }}
        >
            {children}
        </div>
    );
    return (
        <CourseCardSkeletonGroup
            withTitle={withTitle}
            cardCountPerRow={4}
            cardWrapper={CustomCardWrapper}
        />
    );
};

export const ProfessionalSkillsUnit = ({
    pageType,
    contentPanelClassName = '',
    unitData,
    loadingSkeleton = null,
}: ProfessionalSkillsUnitProps & Pick<PSUProps, 'unitData'>) => {
    const udData = useUDData();
    const [discoveryUnitsContainerStore] = useState(
        () => new DiscoveryUnitsContainerStore({pageType}, udData),
    );

    return (
        <FunnelLogContextProvider pageType={pageType}>
            <Provider discoveryUnitsStore={discoveryUnitsContainerStore}>
                {loadingSkeleton ?? (
                    <PSU
                        className={styles['professional-skills-unit']}
                        contentPanelClassName={classNames(
                            styles['panel-content'],
                            contentPanelClassName,
                        )}
                        unitData={unitData}
                        contentLoadingSkeleton={<PSUSkeleton withTitle={false} />}
                    />
                )}
            </Provider>
        </FunnelLogContextProvider>
    );
};
