export const UB_REF_QUERY_PARAM_VALUE = 'plan_comparison-variant-102500';

// HACK HACK HACK - this should only every be used to format prices for the team plan v1 fallback
export const TEAM_PLAN_V1_FALLBACK_CURRENCY_FORMATTER = {
    currency: 'usd',
    symbol: '$',
    locale: 'en_US',
    decimal: '.',
    group: ',',
    pattern: '!#,##0.00',
    scale: 2,
};
