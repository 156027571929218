import {useEffect, useState} from 'react';

import {useI18n} from '@udemy/i18n';
import {udSentry} from '@udemy/sentry';
import {udApi} from '@udemy/ud-api';

import {
    CategoryWithTopics,
    PopularTopicByCategory,
    SdEnrollmentStats,
    SdTagItem,
    Topic,
} from './types';

export const URLS = {
    FEATURED_TOPICS: '/featured-topics/',
    SD_TAG_BASE_URL: '/structured-data/tags/',
    SD_ENROLLMENT_STATS_BASE_URL: '/structured-data/enrollment-stats/',
};

// SD Tag API
export const POPULAR_TOPICS_IN_CATEGORY = 'popular_topics_in_category';

export const ROLE_MARKETPLACE = 'marketplace';
export const DEFAULT_STAT_SIZE = 4;

// Enrollment Stats API
export const TOPIC_IDS = 'topic_ids';

const fetchPopularTopicsByCategory = async ({
    sdTagId,
    statSize = DEFAULT_STAT_SIZE,
}: {
    sdTagId: number;
    statSize?: number;
}) => {
    const response = await udApi.get(`${URLS.SD_TAG_BASE_URL}category/${sdTagId}/`, {
        params: {
            'fields[course_category]': 'stats',
            stat_types: POPULAR_TOPICS_IN_CATEGORY,
            stat_size: statSize,
            stat_role: ROLE_MARKETPLACE,
        },
    });
    return (response.data.stats as PopularTopicByCategory[]).map((topic) => topic.sd_tag);
};

const fetchTopicsEnrollmentStats = async (topicIds: number[]) => {
    const response = await udApi.get(`${URLS.SD_ENROLLMENT_STATS_BASE_URL}`, {
        params: {
            [TOPIC_IDS]: topicIds.join(','),
        },
    });
    return response.data as SdEnrollmentStats;
};

class TrendingSkillsApi {
    constructor(
        public topSkillTopic: Topic,
        private topSkillCategories: {id: number; name: string}[],
        private topicCountPerCategory: number = DEFAULT_STAT_SIZE,
    ) {
        this.topSkillTopic = {...topSkillTopic};
    }

    /**
     * Fetch and populate trending topics.
     */
    fetchAndPopulateTrendingTopics = async (): Promise<{
        topSkillTopic: Topic;
        trendingTopicsByCategory: CategoryWithTopics[];
    }> => {
        const popularTopicsByCategory = await this.fetchPopularTopicsForAllCategories();
        const enrollmentStats = await this.fetchEnrollmentStatsForTopics(popularTopicsByCategory);
        this.populateEnrollmentStats(popularTopicsByCategory, enrollmentStats);

        return {
            topSkillTopic: this.topSkillTopic,
            trendingTopicsByCategory: popularTopicsByCategory,
        };
    };

    /**
     * Fetch popular topics for all categories.
     */
    private fetchPopularTopicsForAllCategories = async (): Promise<CategoryWithTopics[]> => {
        return await Promise.all(
            this.topSkillCategories.map(async (category) => ({
                ...category,
                topics: (await this.fetchPopularTopicsForCategory(category.id)) as Topic[],
            })),
        );
    };

    /**
     * Fetch popular topics for a given category.
     */
    private fetchPopularTopicsForCategory = async (categoryId: number): Promise<SdTagItem[]> => {
        return await fetchPopularTopicsByCategory({
            sdTagId: categoryId,
            statSize: this.topicCountPerCategory,
        });
    };

    /**
     * Fetch enrollment stats for topics.
     */
    private fetchEnrollmentStatsForTopics = async (
        popularTopicsByCategory: CategoryWithTopics[],
    ): Promise<SdEnrollmentStats> => {
        const topicIds = popularTopicsByCategory
            .map((category) => category.topics.map((topic) => topic.id))
            .flat();

        return await fetchTopicsEnrollmentStats([this.topSkillTopic.id, ...topicIds]);
    };

    /**
     * Populate enrollment stats to the topics.
     */
    private populateEnrollmentStats = (
        popularTopicsByCategory: CategoryWithTopics[],
        enrollmentStats: SdEnrollmentStats,
    ) => {
        popularTopicsByCategory.forEach((category) => {
            category.topics.forEach((topic) => {
                const stat = enrollmentStats[topic.id];
                topic.stat_value = stat ? stat.stat_value : 0;
            });
        });

        this.topSkillTopic = {
            ...this.topSkillTopic,
            stat_value: enrollmentStats[this.topSkillTopic.id].stat_value,
        };
    };
}

/**
 * Custom hook for fetching trending categories with topics.
 */
export const useTrendingTopicsByCategories = (topicCountPerCategory: number) => {
    const {gettext} = useI18n();
    const [trendingTopicsByCategory, setTrendingTopicsByCategory] = useState<CategoryWithTopics[]>(
        [],
    );
    const [topSkillTopic, setTopSkillTopic] = useState<Topic>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        /**
         * Right now this is hard coded.
         * TODO: We should be able to fetch this from the API.
         */
        const TOP_SKILL_TOPIC: SdTagItem = {
            _class: 'course_label',
            id: 158002,
            title: gettext('ChatGPT'),
            url: '/topic/chatgpt/',
            icon_class: 'udi udi-line-star',
            type: 'topic',
        };
        const TOP_SKILL_CATEGORIES = [
            {
                id: 288,
                name: gettext('Development'),
            },
            {
                id: 269,
                name: gettext('Design'),
            },
            {
                id: 268,
                name: gettext('Business'),
            },
        ];
        setIsLoading(true);

        /**
         * We're fetching topicCountPerCategory + 1 topics per category because we don't wanna show top skill in the
         * trending topic category section. That's why we're adding 1 to the topicCountPerCategory if we needed to shift
         * to other one
         */
        const trendingSkillsApi = new TrendingSkillsApi(
            TOP_SKILL_TOPIC as Topic,
            TOP_SKILL_CATEGORIES,
            topicCountPerCategory + 1,
        );

        trendingSkillsApi
            .fetchAndPopulateTrendingTopics()
            .then((response) => {
                const {trendingTopicsByCategory, topSkillTopic} = response;

                setTrendingTopicsByCategory(trendingTopicsByCategory);
                setTopSkillTopic(topSkillTopic);
                setIsLoading(false);
            })
            .catch((e) => udSentry.captureException(e));
    }, [topicCountPerCategory, gettext]);

    return {
        topSkillTopic,
        trendingTopicsByCategory,
        setTrendingTopicsByCategory,
        isLoading,
    };
};
