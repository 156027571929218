import classNames from 'classnames';
import React from 'react';

import {Keys} from '@udemy/design-system-utils';
import {useMatchMedia} from '@udemy/hooks';
import {ExpressiveIconName, ExpressiveIcon} from '@udemy/icons-expressive';
import {Image} from '@udemy/react-core-components';
import {Skeleton} from '@udemy/react-reveal-components';

import {DynamicWebContentImage} from 'src/generated/graphql';

import skeletonStyles from './features-skeleton.module.less';
import {
    FeaturesTabContent,
    FeaturesTabContentLoader,
    FeatureTabContentProps,
} from './features-tab-content.react-component';
import styles from './features-tab.module.less';

/** React props interface for `FeaturesTab` component */
export interface FeaturesTabProps extends FeatureTabContentProps {
    id: string;
    index: number;
    /** Flag if card is selected */
    selected?: boolean;
    /** Icon key for the ExpressiveIcon -- only used in fallback case where CMS has not provided URL to asset */
    icon?: ExpressiveIconName;
    /** icon Image returned from CMS to display on features tab **/
    iconImage?: DynamicWebContentImage;
    /** Event handler for when card selected */
    onClick?: () => void;
    /** Event handler for key down events, as this is applying interactivity to a `div` */
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
    /** The id of the tab panel this tab controls */
    ariaControls: string;
}

export const FeaturesTabSkeleton = () => {
    return (
        <div className={classNames(styles.card)}>
            <Skeleton className={classNames(styles.icon, skeletonStyles['icon-skeleton'])} />
            <div className={classNames(styles.content, skeletonStyles['content-skeleton'])}>
                <FeaturesTabContentLoader />
            </div>
        </div>
    );
};

/**
 * ### FeaturesTab
 */
export const FeaturesTab = ({
    id,
    icon,
    iconImage,
    title,
    description,
    enterpriseBadge = false,
    badgeText = '',
    selected = false,
    ctaHref,
    ctaText,
    onClick,
    onKeyDown,
    ariaControls,
    trackImpressionFunc,
}: FeaturesTabProps) => {
    const isMdMax = useMatchMedia('md-max');

    //  Handles keyboard events as we are applying interactivity on a div tab panel */
    const handleKeyboardEvents = () => (event: React.KeyboardEvent<HTMLDivElement>) => {
        // handle key navigation from parent tablist
        onKeyDown?.(event);

        // handle press on return or space
        const code = event.keyCode;
        if (code === Keys.RETURN || code === Keys.SPACE) {
            onClick?.();
        }
    };
    return (
        <div
            id={id}
            tabIndex={0}
            role="tab"
            aria-selected={selected}
            aria-controls={ariaControls}
            className={classNames(styles.card, {[styles.selected]: selected})}
            onClick={onClick}
            onKeyDown={handleKeyboardEvents()}
        >
            {icon && !iconImage && (
                <ExpressiveIcon
                    className={styles.icon}
                    name={icon}
                    size={isMdMax ? 'small' : 'medium'}
                />
            )}
            {iconImage && (
                <Image alt="" src={iconImage.url} className={styles.icon} height={64} width={64} />
            )}
            <div className={styles.content}>
                <FeaturesTabContent
                    title={title}
                    enterpriseBadge={enterpriseBadge}
                    description={description}
                    ctaHref={ctaHref}
                    ctaText={ctaText}
                    trackImpressionFunc={trackImpressionFunc}
                    badgeText={badgeText}
                />
            </div>
        </div>
    );
};
