import React from 'react';

import {Tracker, TrackImpression} from '@udemy/event-tracking';
import {
    HeroBanner,
    HeroBannerSkeleton,
    HeroBannerSlide,
    HeroBannerSlideProps,
    WideHeroBannerSlide,
} from '@udemy/shared-one-udemy-components';
import {WideHeroBannerSlideProps} from '@udemy/shared-one-udemy-components/dist/@types/hero-banner/wide-hero-banner-slide.react-component';
import {BannerClickEvent, BannerImpressionEvent} from '@udemy/smart-bar';

import {BannerData, CTATargetType} from 'src/features/lohp/hooks/useFetchHeroBanners';

import styles from './marketing-hero-banner.module.less';

export const HERO_BANNER_TARGETS = {
    self: '_self',
    new_tab: '_blank',
};

export const BannerLayout = {
    WIDE: 'wide',
    SPLIT: 'split',
};

interface Membership {
    id: number;
}

export interface BannerDataProps {
    data: BannerData;
    membership?: Membership;
}

export interface MarketingHeroBannerProps {
    heroBanners: BannerDataProps[];
    noticeType: string;
    isLoading: boolean;
    showBannerSearchBar: boolean;
}

interface NoticeEventDetails {
    noticeId: number;
    noticeType: string;
    personalizedNoticeSetId?: number | null;
    personalizedNoticeSetName?: string | null;
    topMembershipTargetGroupId?: number | null;
}

interface BannerEventDetails extends NoticeEventDetails {
    slideNumber: number | null;
    url: string | null;
    uiRegion: string | null;
}

export const MarketingHeroBanner = ({
    heroBanners,
    noticeType,
    isLoading,
    showBannerSearchBar,
}: MarketingHeroBannerProps) => {
    if (isLoading) {
        return <HeroBannerSkeleton limitHeight />;
    }

    if (!heroBanners?.length) {
        return null;
    }

    const handleClick = (bannerEventDetails: BannerEventDetails) => {
        if (bannerEventDetails) {
            Tracker.publishEvent(new BannerClickEvent({...bannerEventDetails}));
        }
    };

    const noticeEventDetails = {
        noticeId: heroBanners[0].data.id ?? 0,
        noticeType,
        personalizedNoticeSetId: heroBanners[0].data.personalized_notice_set_id,
        personalizedNoticeSetName: heroBanners[0].data.personalized_notice_set_name,
        topMembershipTargetGroupId:
            heroBanners[0].membership?.id ?? heroBanners[0].data.target_group_id,
    };

    const nextButtonOnClick = () => {
        handleClick({
            ...noticeEventDetails,
            uiRegion: 'next_button',
            slideNumber: null,
            url: null,
        });
    };
    const previousButtonOnClick = () => {
        handleClick({
            ...noticeEventDetails,
            uiRegion: 'prev_button',
            slideNumber: null,
            url: null,
        });
    };

    const getNoticeEventDetailsForSlide = (bannerData: BannerData, index: number) => {
        return {
            noticeId: bannerData.id ?? index,
            noticeType,
            personalizedNoticeSetId: bannerData.personalized_notice_set_id,
            personalizedNoticeSetName: bannerData.personalized_notice_set_name,
            topMembershipTargetGroupId: bannerData.target_group_id,
            slideNumber: index + 1,
        };
    };

    const getLayout = () => heroBanners[0]?.data.layout ?? BannerLayout.SPLIT;

    const getCommonBannerSlideProps = (
        banner: BannerDataProps,
        index: number,
    ): Omit<HeroBannerSlideProps, 'imageSquare' | 'imageWide'> => {
        const bannerData = banner.data;
        const slideNumber = index + 1;

        const getHrefTarget = (target: CTATargetType) => {
            return HERO_BANNER_TARGETS[target] ?? HERO_BANNER_TARGETS.self;
        };

        const button1OnClick = bannerData.cta_1_url
            ? () => {
                  handleClick({
                      ...getNoticeEventDetailsForSlide(bannerData, index),
                      uiRegion: 'primary_cta',
                      url: bannerData.cta_1_url ?? '',
                  });
              }
            : undefined;

        const button2OnClick = bannerData.cta_2_url
            ? () => {
                  handleClick({
                      ...getNoticeEventDetailsForSlide(bannerData, index),
                      slideNumber,
                      uiRegion: 'secondary_cta',
                      url: bannerData.cta_2_url ?? '',
                  });
              }
            : undefined;

        return {
            title: bannerData.title,
            subtitle: bannerData.subtitle,
            button1Href: bannerData.cta_1_url,
            button2Href: bannerData.cta_2_url,
            button1Text: bannerData.cta_1_text,
            button2Text: bannerData.cta_2_text,
            button1Target: getHrefTarget(bannerData.cta_1_target ?? 'self'),
            button2Target: getHrefTarget(bannerData.cta_2_target ?? 'self'),
            button1OnClick,
            button2OnClick,
        };
    };

    const getWideBannerSlideProps = (
        banner: BannerDataProps,
        index: number,
    ): WideHeroBannerSlideProps => {
        const bannerData = banner.data;
        const onUrlClick = () =>
            handleClick({
                ...getNoticeEventDetailsForSlide(bannerData, index),
                uiRegion: 'primary_cta',
                url: bannerData.url ?? '',
            });

        return {
            ...getCommonBannerSlideProps(banner, index),
            image: {src: bannerData.image, alt: '', width: 1340, height: 400},
            imageResponsive: {src: bannerData.image_responsive, alt: '', width: 1304, height: 400},
            url: bannerData.url,
            urlOnClick: onUrlClick,
        };
    };

    const getSplitBannerSlideProps = (
        banner: BannerDataProps,
        index: number,
    ): HeroBannerSlideProps => {
        const bannerData = banner.data;

        return {
            ...getCommonBannerSlideProps(banner, index),
            imageSquare: {src: bannerData.image, width: 646, height: 646, alt: ''},
            imageWide: {src: bannerData.image_responsive, width: 1304, height: 652},
        };
    };

    const heroBannerSlideProps = heroBanners.map((banner: BannerDataProps, index: number) => {
        const slideNumber = index + 1;
        const bannerData = banner.data;
        const trackImpression = () => {
            Tracker.publishEvent(
                new BannerImpressionEvent({
                    ...getNoticeEventDetailsForSlide(bannerData, index),
                    uiRegion: null,
                    url: null,
                    slideNumber,
                }),
            );
        };

        const bannerProps =
            getLayout() === BannerLayout.SPLIT
                ? getSplitBannerSlideProps(banner, index)
                : getWideBannerSlideProps(banner, index);

        return {
            ...bannerProps,
            layout: banner.data.layout,
            trackImpression,
        };
    });

    return (
        <HeroBanner
            onPreviousClick={previousButtonOnClick}
            onNextClick={nextButtonOnClick}
            slideLayout={getLayout()}
        >
            {heroBannerSlideProps.map((slideProps, index) => (
                <TrackImpression
                    key={index}
                    trackFunc={slideProps.trackImpression}
                    visibilityThreshold={0.5}
                >
                    <div>
                        {getLayout() === BannerLayout.SPLIT ? (
                            <HeroBannerSlide {...(slideProps as HeroBannerSlideProps)} />
                        ) : (
                            <WideHeroBannerSlide
                                showSearchBar={showBannerSearchBar}
                                {...(slideProps as WideHeroBannerSlideProps)}
                                className={styles['wide-hero-banner-slide']}
                            />
                        )}
                    </div>
                </TrackImpression>
            ))}
        </HeroBanner>
    );
};
