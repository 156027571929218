import React from 'react';

import {TrackImpression} from '@udemy/event-tracking';
import {useFormatNumber} from '@udemy/i18n';
import NextIcon from '@udemy/icons/dist/next.ud-icon';
import {Button} from '@udemy/react-core-components';
import {TextSkeleton} from '@udemy/react-reveal-components';

import styles from './trending-topics.module.less';
import {CategoryWithTopics, TrackingContext} from './types';

const TopicsByCategory = ({
    categoryWithTopics,
    topicCountToRender = -1,
    trackingContext,
}: {
    categoryWithTopics: CategoryWithTopics;
    topicCountToRender?: number;
    trackingContext?: TrackingContext;
}) => {
    const {formatNumber} = useFormatNumber();
    const topicCount =
        topicCountToRender === -1 ? categoryWithTopics.topics.length : topicCountToRender;
    return (
        <div className={styles['category-column']}>
            <span className={styles['title']}>{`${categoryWithTopics.name}`}</span>
            {categoryWithTopics.topics.slice(0, topicCount).map((topic, index) => (
                <TrackImpression
                    key={`${topic.title}-${topic.id}-${index}`}
                    trackFunc={() => trackingContext?.trackImpressionFunc?.(topic, index)}
                >
                    <div className={styles['topic']}>
                        <Button
                            componentClass="a"
                            udStyle="link"
                            size={'large'}
                            className={styles['title']}
                            href={topic.url}
                            onClick={() => trackingContext?.trackItemClickFunc?.(topic, index)}
                        >
                            {topic.title}
                            <NextIcon label={false} />
                        </Button>
                        <span className={styles['stat']}>
                            {ninterpolate(
                                '%(statValue)s learner',
                                '%(statValue)s learners',
                                topic.stat_value,
                                {statValue: formatNumber(topic.stat_value)},
                            )}
                        </span>
                    </div>
                </TrackImpression>
            ))}
        </div>
    );
};
export const TrendingTopics = ({
    categoryWithTopics,
    topicCountToRender,
    loading = false,
    loadingCategoryCountToRender,
    trackingContext,
}: {
    categoryWithTopics: CategoryWithTopics[];
    topicCountToRender: number;
    loading?: boolean;
    loadingCategoryCountToRender: number;
    trackingContext?: TrackingContext;
}) => {
    const LoadingSkeleton = () => (
        <>
            {Array.from({length: loadingCategoryCountToRender}).map((_, index) => (
                <TextSkeleton
                    key={`loading-skeleton-topic-category-${index}`}
                    lineCountPerParagraph={topicCountToRender}
                    justified={true}
                    withTitle={true}
                    className={styles['loading-skeleton-topic-category']}
                />
            ))}
        </>
    );

    return (
        <>
            {loading ? (
                <LoadingSkeleton />
            ) : (
                categoryWithTopics.map((categoryWithTopics) => (
                    <TopicsByCategory
                        key={`${categoryWithTopics.name}-${categoryWithTopics.id}`}
                        categoryWithTopics={categoryWithTopics}
                        topicCountToRender={topicCountToRender}
                        trackingContext={trackingContext}
                    />
                ))
            )}
        </>
    );
};
