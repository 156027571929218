import React from 'react';

import {Picture} from '@udemy/react-core-components';
import {ImageSkeleton} from '@udemy/react-reveal-components';
import {tokens} from '@udemy/styles';
import {UDLinkApi, useUDLink} from '@udemy/ud-data';

import {DynamicWebContentImage} from 'src/generated/graphql';

import skeletonStyles from './features-skeleton.module.less';

export interface FeaturesTabPanelFallbackImage {
    /** The key to interpolate within the image urls */
    imgKey: string;
    /** Alt text for the image being displayed */
    imgAlt: string;
}

export interface FeaturesTabPanelProps {
    /** The hardcoded fallback images optimized for mobile, tablet, and desktop sizes */
    fallbackImage: FeaturesTabPanelFallbackImage;

    /** The image to display from the CMS - differs from the mobile, tablet, desktop optimized
     *  images in the hardcoded unit */
    cmsImage?: DynamicWebContentImage;
}

export const fallbackFeatureTabPanelImage = (
    udLinkApi: UDLinkApi,
    imgKey: string,
    assetVersion = 'v5',
) => {
    return [
        {
            srcSet: `${udLinkApi.toStorageStaticAsset(
                `lohp/features-and-value-props/${assetVersion}/desktop-${imgKey}-1x.png`,
            )} 1x, ${udLinkApi.toStorageStaticAsset(
                `lohp/features-and-value-props/${assetVersion}/desktop-${imgKey}-2x.png`,
            )} 2x`,
            media: `(min-width: ${tokens['breakpoint-md-min']})`,
            width: 640,
            height: 700,
        },
        {
            srcSet: `${udLinkApi.toStorageStaticAsset(
                `lohp/features-and-value-props/${assetVersion}/tablet-${imgKey}-1x.png`,
            )} 1x, ${udLinkApi.toStorageStaticAsset(
                `lohp/features-and-value-props/${assetVersion}/tablet-${imgKey}-2x.png`,
            )} 2x`,
            media: `(max-width: ${tokens['breakpoint-sm-max']})`,
            width: 652,
            height: 450,
        },
        {
            srcSet: `${udLinkApi.toStorageStaticAsset(
                `lohp/features-and-value-props/${assetVersion}/mobile-${imgKey}-1x.png`,
            )} 1x, ${udLinkApi.toStorageStaticAsset(
                `lohp/features-and-value-props/${assetVersion}/mobile-${imgKey}-2x.png`,
            )} 2x`,
            media: `(max-width: ${tokens['breakpoint-mobile-xs-max']})`,
            width: 327,
            height: 225,
        },
    ];
};

/**
 * ### FeaturesTabPanel
 *
 * Renders a div with `role="tabpanel"` and a {@link Picture} component
 */
export const FeaturesTabPanel = ({fallbackImage, cmsImage}: FeaturesTabPanelProps) => {
    const udLinkApi = useUDLink();
    const assetVersion = 'v5';

    const pictureSources = cmsImage
        ? []
        : fallbackFeatureTabPanelImage(udLinkApi, fallbackImage.imgKey, assetVersion);
    const pictureSrc = cmsImage
        ? cmsImage.url
        : udLinkApi.toStorageStaticAsset(
              `lohp/features-and-value-props/${assetVersion}/desktop-${fallbackImage.imgKey}-1x.png`,
          );
    const alt = cmsImage ? cmsImage.altText : fallbackImage.imgAlt;

    return <Picture sources={pictureSources} alt={alt ?? ''} src={pictureSrc} />;
};

export const FeaturesTabPanelSkeleton = () => {
    return (
        <div className={skeletonStyles['image-skeleton']}>
            <ImageSkeleton />
        </div>
    );
};
