/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'src/lib/gql-fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AccountNumber: { input: any; output: any; }
  AverageRating: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Byte: { input: any; output: any; }
  CountryCode: { input: any; output: any; }
  CourseDuration: { input: any; output: any; }
  Cuid: { input: any; output: any; }
  Currency: { input: any; output: any; }
  DID: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeISO: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  DeweyDecimal: { input: any; output: any; }
  Duration: { input: any; output: any; }
  DurationInMinutes: { input: any; output: any; }
  DurationInSeconds: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  GUID: { input: any; output: any; }
  HSL: { input: any; output: any; }
  HSLA: { input: any; output: any; }
  HexColorCode: { input: any; output: any; }
  Hexadecimal: { input: any; output: any; }
  IBAN: { input: any; output: any; }
  IP: { input: any; output: any; }
  IPCPatent: { input: any; output: any; }
  IPv4: { input: any; output: any; }
  IPv6: { input: any; output: any; }
  ISBN: { input: any; output: any; }
  ISO8601Duration: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  JWT: { input: any; output: any; }
  LCCSubclass: { input: any; output: any; }
  Latitude: { input: any; output: any; }
  LocalDate: { input: any; output: any; }
  LocalDateTime: { input: any; output: any; }
  LocalEndTime: { input: any; output: any; }
  LocalTime: { input: any; output: any; }
  Locale: { input: any; output: any; }
  Long: { input: any; output: any; }
  Longitude: { input: any; output: any; }
  MAC: { input: any; output: any; }
  MaxResultsPerPage: { input: any; output: any; }
  NegativeFloat: { input: any; output: any; }
  NegativeInt: { input: any; output: any; }
  NonEmptyString: { input: any; output: any; }
  NonNegativeFloat: { input: any; output: any; }
  NonNegativeInt: { input: any; output: any; }
  NonPositiveFloat: { input: any; output: any; }
  NonPositiveInt: { input: any; output: any; }
  ObjectID: { input: any; output: any; }
  PhoneNumber: { input: any; output: any; }
  Port: { input: any; output: any; }
  PositiveFloat: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
  PostalCode: { input: any; output: any; }
  RGB: { input: any; output: any; }
  RGBA: { input: any; output: any; }
  RRuleString: { input: any; output: any; }
  RoutingNumber: { input: any; output: any; }
  SafeHtml: { input: any; output: any; }
  SafeInt: { input: any; output: any; }
  SemVer: { input: any; output: any; }
  Time: { input: any; output: any; }
  TimeZone: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
  URL: { input: any; output: any; }
  USCurrency: { input: any; output: any; }
  UUID: { input: any; output: any; }
  UnsignedFloat: { input: any; output: any; }
  UnsignedInt: { input: any; output: any; }
  UtcOffset: { input: any; output: any; }
  Void: { input: any; output: any; }
};

/** Price option for computed price plan. Will be returned for annual plans */
export type AnnualSubscriptionPlanPricingOption = {
  __typename?: 'AnnualSubscriptionPlanPricingOption';
  /** The annual savings amount for the subscription price plan when compared to monthly plans. calculated on pricing backend can be null */
  annualSavings?: Maybe<Money>;
  /** Contains information about the discount context for a given subscription plan price option */
  discount?: Maybe<SubscriptionPlanDiscount>;
  /** Field containing details about the discounted trial subscription offer for a given user. Null indicates no trial is available */
  discountTrial?: Maybe<SubscriptionTrial>;
  /** ID of the price option: */
  id: Scalars['ID']['output'];
  /** Contains information about the license context for a given subscription plan price option */
  licenseContext?: Maybe<SubscriptionPlanLicenseContext>;
  /** The list price of the subscription price plan based on provided requested count from request */
  listPrice: Money;
  /** The monthly list price of the subscription price plan. Applicable for annual plan only. Represents fraction of list price */
  monthlyPrice: Money;
  /** Field showing the prices for the option. */
  price?: Maybe<SubscriptionPlanPrice>;
  /** Interval for renewing the subscription plan ie the length of the subscription plan */
  renewalInterval: DateInterval;
  /** Field containing details about the trial subscription offer for a given user. Null indicates no trial is available */
  trial?: Maybe<SubscriptionTrial>;
};

/** An API client */
export type ApiClient = {
  /** The API Client expiration date */
  expiresOn?: Maybe<Scalars['Date']['output']>;
  /** The API Client ID as UUID */
  id: Scalars['ID']['output'];
  /** The API Client name */
  name: Scalars['String']['output'];
  /** The scopes this API client has access to */
  scopes: Array<Scalars['String']['output']>;
};

/** Response from creating an API client containing ApiClient info and client's secret */
export type ApiClientCreateResponse = {
  __typename?: 'ApiClientCreateResponse';
  /** The created API Client */
  apiClient?: Maybe<ApiClient>;
  /** Client Secret for the created API Client */
  clientSecret?: Maybe<Scalars['String']['output']>;
};

/** Response from deleting an API client containing the deleted API Client ID */
export type ApiClientDeleteResponse = {
  __typename?: 'ApiClientDeleteResponse';
  /** The deleted API Client ID */
  clientId?: Maybe<Scalars['ID']['output']>;
};

/** An API client for an Organization */
export type ApiClientForOrganization = ApiClient & {
  __typename?: 'ApiClientForOrganization';
  /** The API Client expiration date */
  expiresOn?: Maybe<Scalars['Date']['output']>;
  /** The API Client ID */
  id: Scalars['ID']['output'];
  /** The API Client name */
  name: Scalars['String']['output'];
  /** The organization this API client is for */
  organization: Organization;
  /** The scopes this API client has access to */
  scopes: Array<Scalars['String']['output']>;
};

/** An API client for a Partner */
export type ApiClientForPartner = ApiClient & {
  __typename?: 'ApiClientForPartner';
  /** The API Client expiration date */
  expiresOn?: Maybe<Scalars['Date']['output']>;
  /** The API Client ID */
  id: Scalars['ID']['output'];
  /** The API Client name */
  name: Scalars['String']['output'];
  /** The partner this API client is for */
  partner: Partner;
  /** The scopes this API client has access to */
  scopes: Array<Scalars['String']['output']>;
};

/** Input for creating an API client */
export type ApiClientInput = {
  /** The API Client name */
  name: Scalars['String']['input'];
  /** The scopes this API client has access to */
  scopes: Array<Scalars['String']['input']>;
};

/** Article lecture */
export type ArticleLecture = CurriculumItem & Lecture & {
  __typename?: 'ArticleLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Total duration of the lecture's content in seconds */
  durationInSeconds: Scalars['DurationInSeconds']['output'];
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/**
 * An Assessment represents a collection of Forms (which are variations of items and orderings of items).
 * A user chooses to take an Assessment. They are then give a Form to take from that Assessment.
 */
export type Assessment = LearningProduct & {
  __typename?: 'Assessment';
  /** The assessment's assignment information, if the assessment has been assigned to the user */
  assignment?: Maybe<AssessmentAssignment>;
  /** Description of the assessment. */
  description: Scalars['String']['output'];
  /** ID of the assessment. */
  id: Scalars['ID']['output'];
  /** Is the assessment active or not */
  isActive: Scalars['Boolean']['output'];
  /** Is the assessment beta or not */
  isBeta: Scalars['Boolean']['output'];
  /** Metadata associated with the assessment */
  metadata?: Maybe<AssessmentMetadata>;
  /** The name, for example: 'HTML' or 'AWS Developer Certification'. */
  title: Scalars['String']['output'];
  /** Landing page to view this Assessment */
  urlLanding: Scalars['URL']['output'];
};

/** A unique assignment of an assessment to a user, with optional due date */
export type AssessmentAssignment = {
  __typename?: 'AssessmentAssignment';
  /** An optional due date by which the assignment should be completed */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the assignment */
  id: Scalars['ID']['output'];
};

/** An AssessmentItem contains data about the item a user can interact with. */
export type AssessmentItem = {
  __typename?: 'AssessmentItem';
  /** The Id */
  id: Scalars['ID']['output'];
};

/** An  AssessmentItemAttempt is an item related to an Assessment that contains how a user performed in an AssessmentItem. */
export type AssessmentItemAttempt = {
  __typename?: 'AssessmentItemAttempt';
  /** AssessmentItem related to data */
  item: AssessmentItem;
  /** Returns the attempt status for item */
  status: AssessmentItemAttemptStatus;
};

/** Contains the valid item attempt status for an item attempt. */
export enum AssessmentItemAttemptStatus {
  /** The user answered the item correctly */
  Correct = 'CORRECT',
  /** The user answered the item incorrectly */
  Incorrect = 'INCORRECT',
  /** The user skipped the item */
  Skipped = 'SKIPPED'
}

/** Filters used to fetch user assessment items results that are correct, skipped and attempted. */
export type AssessmentItemResultsFilters = {
  /**
   * Use status to filter itemAttempts return with specific status.
   * Default is to return all status.
   */
  status?: InputMaybe<Array<AssessmentItemAttemptStatus>>;
};

/** MetaData for an assessment */
export type AssessmentMetadata = {
  __typename?: 'AssessmentMetadata';
  /** Unique analytics ID for this instance of Assessment returned from the server in this request. */
  trackingId?: Maybe<Scalars['String']['output']>;
};

/**
 * An AssessmentResults represents a user results for taking an Assessment.
 * items are the attempts of the latest form the User attempted for an Assessment.
 */
export type AssessmentResults = {
  __typename?: 'AssessmentResults';
  /** Assessment related to results */
  assessment: Assessment;
  /** List of items a user either skipped or answered */
  items: Array<AssessmentItemAttempt>;
};

/** List of Assessment and additional data about search response */
export type AssessmentSearchResponse = {
  __typename?: 'AssessmentSearchResponse';
  /** List of Assessment objects. */
  assessments: Array<Maybe<Assessment>>;
  /** Total number of Assessments matching the search query and filters. */
  count: Scalars['Int']['output'];
  /** Metadata for whole search result used by front end */
  metadata?: Maybe<AssessmentSearchResponseMetadata>;
};

/** Contains info for front end related stuff and tracking info. This data is calculated by backend service and used by Udemy front end. If you are an external user, you shouldn't need this. */
export type AssessmentSearchResponseMetadata = {
  __typename?: 'AssessmentSearchResponseMetadata';
  /** For the request, shows experiment metadata result like bucking info, experiment IDs of assigned feature variants. */
  experimentResults: Array<FeatureVariantAssignment>;
  /** Search analytics tracking id; for uniquely identifying this query and result set; for this request */
  trackingId: Scalars['String']['output'];
};

/**
 * A 'variant' is an instance/representation of an assessment used for having its own dynamic list of questions
 *
 * There may exist multiple variants of the same assessment, which share the same pool of questions, uniquely ordered between variants. Each variant will have a unique id, and will be associated with one assessment from which the questions are drawn.
 */
export type AssessmentVariant = {
  __typename?: 'AssessmentVariant';
  /** The assessment for which the variant is found */
  assessment: Assessment;
  /** The ID */
  id: Scalars['ID']['output'];
};

/** Top level object to track the progress and results of a user taking an assessment variant */
export type AssessmentVariantAttempt = {
  __typename?: 'AssessmentVariantAttempt';
  /** The score and percentile of the variant attempt, available once the attempt is completed */
  evaluation?: Maybe<AssessmentVariantAttemptEvaluation>;
  /** The ID */
  id: Scalars['ID']['output'];
  /** This is set to true once the variant attempt is completed by the user */
  isCompleted: Scalars['Boolean']['output'];
  /** The last time a user interacted with the variant attempt */
  lastInteraction: Scalars['DateTime']['output'];
  /** The variant that the attempt belongs to */
  variant: AssessmentVariant;
};

/** An evaluation of a completed attempt */
export type AssessmentVariantAttemptEvaluation = {
  __typename?: 'AssessmentVariantAttemptEvaluation';
  /** The percentile associated with this score, compared amongst all users' attempts of this assessment */
  percentile: Scalars['Int']['output'];
  /**
   * The score obtained by the user after completing the attempt
   * Scores can range from 0-200, with 100 representing an average score.
   */
  score: Scalars['Float']['output'];
};

/** Lecture in the form of just audio (deprecated) */
export type AudioLecture = CurriculumItem & Lecture & {
  __typename?: 'AudioLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Total duration of the lecture's content in seconds */
  durationInSeconds: Scalars['DurationInSeconds']['output'];
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** Issued Badge of a user (Assertion in OpenBadge specification) */
export type BadgeAssertion = {
  __typename?: 'BadgeAssertion';
  /** The BadgeClass object that this Assertion is issued for */
  badgeClass: BadgeClass;
  /** The date this assertion expires */
  expires?: Maybe<Scalars['Date']['output']>;
  /** IRI of the Assertion. IRI of OpenBadge specification */
  externalUrl: Scalars['URL']['output'];
  /** ID of the Assertion. */
  id: Scalars['ID']['output'];
  /** The date this assertion is issued */
  issuedOn: Scalars['Date']['output'];
  /** The url of the uploaded baked image of assertion */
  uploadedImageUrl?: Maybe<Scalars['URL']['output']>;
  /** Owner of the assertion */
  user: User;
};

/** Response for storing assertion (which is given as external url) for current user */
export type BadgeAssertionCreateOrUpdateResponse = {
  __typename?: 'BadgeAssertionCreateOrUpdateResponse';
  /** The BadgeAssertion object that was stored */
  badgeAssertion?: Maybe<BadgeAssertion>;
};

/** Response for deleting assertion of current user */
export type BadgeAssertionDeleteResponse = {
  __typename?: 'BadgeAssertionDeleteResponse';
  /** The ID of the deleted badge assertion */
  badgeAssertionId?: Maybe<Scalars['ID']['output']>;
};

/** Issued badges of a user (Assertion in OpenBadge specification) */
export type BadgeAssertionPaged = Paginated & {
  __typename?: 'BadgeAssertionPaged';
  /** List of BadgeAssertions */
  items: Array<BadgeAssertion>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page pageSize) */
  pageCount: Scalars['Int']['output'];
  /** Total number of BadgeAssertions */
  totalCount: Scalars['Int']['output'];
};

/** Search filters for Badge Assertion search */
export type BadgeAssertionsSearchFilters = {
  /** if given, assertions will be filtered by these badgeClasses */
  badgeClassExternalUrls?: InputMaybe<Array<Scalars['URL']['input']>>;
  /** endDate to filter assertions by dateUploaded. If not given, all assertions will be returned */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** startDate to filter assertions by dateUploaded. If not given, all assertions will be returned */
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Certification Provider (topic descriptor) for Open Badges */
export type BadgeCertificationProvider = {
  __typename?: 'BadgeCertificationProvider';
  /** ID of certification provider */
  id: Scalars['ID']['output'];
  /** Title of certification provider(Google, Microsoft) */
  name: Scalars['String']['output'];
};

/** Certification Subject Area (topic descriptor) for Open Badges */
export type BadgeCertificationSubjectArea = {
  __typename?: 'BadgeCertificationSubjectArea';
  /** ID of certification subject area */
  id: Scalars['ID']['output'];
  /** Title of certification subject area(Cloud, SQL) */
  name: Scalars['String']['output'];
};

/** The BadgeClass object. A collection of information about the accomplishment recognized by the Open Badge. It means Certification for the most cases. */
export type BadgeClass = {
  __typename?: 'BadgeClass';
  /** An object describing which objectives or educational standards this badge aligns to, if any. */
  alignment?: Maybe<Array<BadgeClassAlignment>>;
  /** User's assertions for the badge class */
  assertions: Array<BadgeAssertion>;
  /** Criteria document describing how to earn the BadgeClass (Certification) */
  criteria: BadgeClassCriteria;
  /** A short description of BadgeClass */
  description: Scalars['String']['output'];
  /** User's enrollments for the badge class */
  enrollments: Array<BadgeClassEnrollment>;
  /** Links to exam registration page for this certification */
  examRegistrationUrls: Array<CertificationExamUrl>;
  /** IRI of the BadgeClass. IRI of OpenBadge specification */
  externalUrl: Scalars['URL']['output'];
  /** ID of the BadgeClass. */
  id: Scalars['ID']['output'];
  /** A PNG or SVG image of the BadgeClass */
  image: BadgeClassImage;
  /** Organization that issued the badge. */
  issuer: BadgeClassIssuer;
  /** Name of the BadgeClass */
  name: Scalars['String']['output'];
  /** Certification Provider for this Badge Class */
  providers?: Maybe<Array<BadgeCertificationProvider>>;
  /** Subject Area (recently renamed to Skill Area) for this BadgeClass (i.e. Data Science, CyberSecurity) */
  subjectAreas?: Maybe<Array<BadgeCertificationSubjectArea>>;
  /** A tag that describes the type of achievement. (Skills) */
  tags: Array<Scalars['String']['output']>;
  /** Topic of badge class. Can be null for badge classes uploaded by users */
  topic?: Maybe<Topic>;
  /** Type of the BadgeClass. In most cases, this will simply be the string BadgeClass */
  type: Array<Scalars['String']['output']>;
  /** Visibility of the badge class on the Udemy platform (only available for applications with admin:application scope) */
  visibilityStatus: BadgeClassVisibilityStatus;
};

/** Alignment object. An intangible item that describes an alignment between a learning resource and a BadgeClass */
export type BadgeClassAlignment = {
  __typename?: 'BadgeClassAlignment';
  /** If applicable, a locally unique string identifier that identifies the alignment target within its framework and/or targetUrl. */
  targetCode?: Maybe<Scalars['String']['output']>;
  /** Short description of the alignment target. */
  targetDescription?: Maybe<Scalars['String']['output']>;
  /** Name of the framework the alignment target. */
  targetFramework?: Maybe<Scalars['String']['output']>;
  /** Name of the alignment. */
  targetName?: Maybe<Scalars['String']['output']>;
  /** URL linking to the official description of the alignment target, for example an individual standard within an educational framework. */
  targetUrl?: Maybe<Scalars['URL']['output']>;
};

/** Descriptive metadata about the achievements necessary to be issued with particular BadgeClass (Certification). */
export type BadgeClassCriteria = {
  __typename?: 'BadgeClassCriteria';
  /** The URI of a webpage that describes in a human-readable format the criteria for the BadgeClass */
  id?: Maybe<Scalars['URL']['output']>;
  /** A narrative of what is needed to earn the badge. */
  narrative?: Maybe<Scalars['String']['output']>;
  /** Type of the Criteria */
  type?: Maybe<Array<Scalars['String']['output']>>;
};

/** Enrolled learning products for a badge class */
export type BadgeClassEnrollment = {
  __typename?: 'BadgeClassEnrollment';
  /** Completion percentage for the learning product */
  completionPercentage: Scalars['NonNegativeFloat']['output'];
  /** Learning Product */
  learningProduct: LearningProduct;
};

/** Image object of BadgeClass or Issuer */
export type BadgeClassImage = {
  __typename?: 'BadgeClassImage';
  /** The author of the image */
  author?: Maybe<Scalars['String']['output']>;
  /** The caption for the image */
  caption?: Maybe<Scalars['String']['output']>;
  /** URI of the image */
  id: Scalars['ID']['output'];
  /** Type of Image */
  type?: Maybe<Array<Scalars['String']['output']>>;
};

/** Issuer of BadgeClass. A collection of information that describes the entity or organization */
export type BadgeClassIssuer = {
  __typename?: 'BadgeClassIssuer';
  /** A short description of the issuer entity or organization. */
  description?: Maybe<Scalars['String']['output']>;
  /** Contact address for the individual or organization. */
  email?: Maybe<Scalars['EmailAddress']['output']>;
  /** Unique IRI for the Issuer/Profile file */
  id: Scalars['ID']['output'];
  /** IRI or document representing an image of the issuer. This must be a PNG or SVG image. */
  image?: Maybe<BadgeClassImage>;
  /** The name of the entity or organization. */
  name: Scalars['String']['output'];
  /** A phone number for the entity. */
  telephone?: Maybe<Scalars['String']['output']>;
  /** Issuer type. In most cases, this will simply be the string Issuer or the more general Profile */
  type: Array<Scalars['String']['output']>;
  /** The homepage or social media profile of the entity, whether individual or institutional */
  url: Scalars['URL']['output'];
};

/** Search filters for BadgeClass search */
export type BadgeClassSearchFilters = {
  /** Filter by certification subject area */
  certificationAreaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter by issuer */
  issuerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If not given, all badges will be returned */
  visibilityStatus?: InputMaybe<Array<BadgeClassVisibilityStatus>>;
};

/** Paginated list of BadgeClasses for search response */
export type BadgeClassSearchResponse = Paginated & {
  __typename?: 'BadgeClassSearchResponse';
  /** List of BadgeClasses */
  items: Array<BadgeClass>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page pageSize) */
  pageCount: Scalars['Int']['output'];
};

/** Wrapped response type for BadgeClass mutations */
export type BadgeClassUpdateResponse = {
  __typename?: 'BadgeClassUpdateResponse';
  /** The BadgeClass object */
  badgeClass?: Maybe<BadgeClass>;
};

/** Input for updating a badge classes by topicId */
export type BadgeClassUpdateTopicInput = {
  /** ID of the BadgeClass. */
  id: Scalars['ID']['input'];
  /** Topic of badge class */
  topicId?: InputMaybe<Scalars['ID']['input']>;
};

/** Input for updating a badge classes by visibilityStatus */
export type BadgeClassUpdateVisibilityStatusInput = {
  /** ID of the BadgeClass. */
  id: Scalars['ID']['input'];
  /** Visibility of the badge class on the Udemy platform (only available for applications with admin:application scope) */
  visibilityStatus: BadgeClassVisibilityStatus;
};

/** Visibility of the badge class on the Udemy platform */
export enum BadgeClassVisibilityStatus {
  /** Deleted badges */
  Deleted = 'DELETED',
  /** Hidden from users on the platform */
  Hidden = 'HIDDEN',
  /** Visible to users on the platform, but not discoverable in search */
  InstructorIssued = 'INSTRUCTOR_ISSUED',
  /** Visible to users on the platform, but not discoverable in search, and only visible to users in the issuing organization */
  Organization = 'ORGANIZATION',
  /** Hidden from users on the platform, awaiting content or planning updates */
  Pending = 'PENDING',
  /** Visible to all users */
  Visible = 'VISIBLE'
}

/** Types of captions */
export enum CaptionType {
  /** Captions covering speech and descriptions of non-spoken sounds */
  ClosedCaption = 'CLOSED_CAPTION',
  /** Captions covering speech only */
  Subtitle = 'SUBTITLE'
}

/** Category the Course belongs to */
export type Category = {
  /** ID of the category */
  id: Scalars['ID']['output'];
  /** Name of the category */
  name: Scalars['String']['output'];
  /** Subcategories belonging to the category */
  subcategories: Array<SubCategory>;
};

/** URL to the exam registration page for a certification with its locale */
export type CertificationExamUrl = {
  __typename?: 'CertificationExamURL';
  /** Locale this exam Is administered in */
  locale: Scalars['Locale']['output'];
  /** URL to the exam registration page for a certification */
  url: Scalars['URL']['output'];
};

/** Type of the files withing coding exercise templates */
export type CodeFile = {
  __typename?: 'CodeFile';
  /** Content of the codefile inside one of the file list of the coding exercise template */
  content: Scalars['String']['output'];
  /** File name of the codefile inside one of file list of the coding exercise template */
  fileName: Scalars['String']['output'];
};

/** Coding exercise for students to practice their programming */
export type CodingExercise = CurriculumItem & {
  __typename?: 'CodingExercise';
  /** Problem statement */
  description?: Maybe<Scalars['String']['output']>;
  /** The Id of the coding exercise */
  id: Scalars['ID']['output'];
  /** The title of the coding exercise */
  title: Scalars['String']['output'];
  /** Landing page to view this CodingExercise */
  urlLanding: Scalars['URL']['output'];
};

/** Language possibilities for a coding exercise */
export enum CodingExerciseLanguageOption {
  /** C++ 14 */
  Cplusplus = 'CPLUSPLUS',
  /** C++ 20 */
  Cplusplus_20 = 'CPLUSPLUS_20',
  /** CSV processing with Python */
  CsvProcessingWithPython = 'CSV_PROCESSING_WITH_PYTHON',
  /** C# */
  CSharp = 'C_SHARP',
  /** C# 11 */
  CSharp_11 = 'C_SHARP_11',
  /** HTML */
  Html = 'HTML',
  /** JavaScript ES6 */
  JavascriptEs6 = 'JAVASCRIPT_ES6',
  /** Java 9 */
  Java_9 = 'JAVA_9',
  /** Java 11 */
  Java_11 = 'JAVA_11',
  /** Java 17 */
  Java_17 = 'JAVA_17',
  /** Kotlin 1.9 */
  Kotlin_1_9 = 'KOTLIN_1_9',
  /** PHP 5 */
  Php_5 = 'PHP_5',
  /** PHP 7 */
  Php_7 = 'PHP_7',
  /** Python 3.5 */
  Python_3_5 = 'PYTHON_3_5',
  /** Python 3.8 */
  Python_3_8 = 'PYTHON_3_8',
  /** Python 3.10 */
  Python_3_10 = 'PYTHON_3_10',
  /** React 16 */
  React_16 = 'REACT_16',
  /** React 18 */
  React_18 = 'REACT_18',
  /** Ruby */
  Ruby = 'RUBY',
  /** R 3.6 */
  R_3_6 = 'R_3_6',
  /** SciPy 1.4 (Numby, Pandas, SymPy and SkiCit Learn 0.23 */
  Scipy_1_4NumpyPandasSympyAndScikitLearn_0_23 = 'SCIPY_1_4_NUMPY_PANDAS_SYMPY_AND_SCIKIT_LEARN_0_23',
  /** SQL (MySQL) */
  Sql = 'SQL',
  /** SQLITE 3 */
  Sqlite_3 = 'SQLITE_3',
  /** Swift 3 */
  Swift_3 = 'SWIFT_3',
  /** Swift 5 */
  Swift_5 = 'SWIFT_5',
  /** Web Development */
  WebDevelopment = 'WEB_DEVELOPMENT'
}

/** Type of the coding exercise templates */
export type CodingExerciseTemplate = {
  __typename?: 'CodingExerciseTemplate';
  /** Description of the coding exercise template */
  description: Scalars['String']['output'];
  /** Language of the coding exercise template */
  language: CodingExerciseLanguageOption;
  /** Name of the coding exercise template */
  name: Scalars['String']['output'];
  /** Set of setup files of the sql type coding exercise template */
  setupFiles: Array<CodeFile>;
  /** Set of solution files of the  coding exercise template */
  solutionFiles: Array<CodeFile>;
  /** Supported versions of the language for which the template can be applied */
  supportedVersions: Array<CodingExerciseLanguageOption>;
  /** Set of test files of the coding exercise template */
  testFiles: Array<CodeFile>;
};

/** Filtration options for the coding exercise templates */
export type CodingExerciseTemplateFilters = {
  /** Include the language of the coding exercise template in the response */
  includeVersion?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of the language to filter the coding exercise templates */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The cohort path object. */
export type CohortPath = {
  __typename?: 'CohortPath';
  /** List of course library items */
  courseLibrary: Array<CohortPathCourseLibraryItem>;
  /** Description of the cohort path. */
  description?: Maybe<Scalars['String']['output']>;
  /** Id of the cohort path. */
  id: Scalars['UUID']['output'];
  /** Is the cohort path published */
  isPublished: Scalars['Boolean']['output'];
  /** When the cohort path was last edited */
  lastEditedAt: Scalars['DateTime']['output'];
  /** User who last edited the cohort path */
  lastEditor: User;
  /** Name of the cohort path. */
  name: Scalars['String']['output'];
  /** When the cohort path was published. Null if not published. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** List of sections in the cohort path */
  sections: Array<CohortPathSection>;
};

/** The type of activity within a section */
export enum CohortPathActivityResourceType {
  /** A course assignment */
  Assignment = 'ASSIGNMENT',
  /** A course exercise */
  CodingExercise = 'CODING_EXERCISE',
  /** A course lecture */
  Lecture = 'LECTURE',
  /** A course practice test */
  PracticeTest = 'PRACTICE_TEST',
  /** A course quiz */
  Quiz = 'QUIZ'
}

/** The cohort path course library object. */
export type CohortPathCourseLibraryItem = {
  __typename?: 'CohortPathCourseLibraryItem';
  /** The course object imported into the library */
  course: Course;
  /** When the course was imported into the library */
  importedAt: Scalars['DateTime']['output'];
};

/** Input for cohort path creation or update. */
export type CohortPathCreateUpdateInput = {
  /** Description of the cohort path */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the cohort path */
  name: Scalars['String']['input'];
};

/** Response for creating or updating cohort path. */
export type CohortPathCreateUpdateResponse = {
  __typename?: 'CohortPathCreateUpdateResponse';
  /** The cohort path created or updated if the mutation was successful */
  cohortPath: CohortPath;
};

/** The cohort path section object. */
export type CohortPathSection = {
  __typename?: 'CohortPathSection';
  /** List of activities in the cohort path section. */
  activities: Array<CohortPathSectionActivity>;
  /** Title of the cohort path section. */
  name: Scalars['String']['output'];
  /** Pacing setting in days for the cohort path section. */
  pacingDays: Scalars['NonNegativeInt']['output'];
  /** Sequence number of the cohort path section. */
  sequence: Scalars['NonNegativeInt']['output'];
};

/** Base type for the cohort path section activity object. */
export type CohortPathSectionActivity = {
  /** Sequence number of the section activity. */
  sequence: Scalars['NonNegativeInt']['output'];
};

/** The cohort path section activity input object. */
export type CohortPathSectionActivityInput = {
  /** The id of the referenced learning activity */
  resourceId: Scalars['String']['input'];
  /** the type of the referenced learning activity */
  resourceType: CohortPathActivityResourceType;
  /** the version of the referenced learning activity */
  resourceVersion?: InputMaybe<Scalars['String']['input']>;
  /** Sequence number of the section activity. */
  sequence: Scalars['NonNegativeInt']['input'];
};

/** The cohort path section activity object. */
export type CohortPathSectionCurriculumActivity = CohortPathSectionActivity & {
  __typename?: 'CohortPathSectionCurriculumActivity';
  /** The curriculum item attached to the activity */
  curriculumItem: CurriculumItem;
  /** Sequence number of the section activity. */
  sequence: Scalars['NonNegativeInt']['output'];
};

/** The cohort path section input object. */
export type CohortPathSectionInput = {
  /** List of activities in the cohort path section. */
  activities: Array<CohortPathSectionActivityInput>;
  /** Title of the cohort path section. */
  name: Scalars['String']['input'];
  /** Pacing setting in days for the cohort path section. */
  pacingDays: Scalars['NonNegativeInt']['input'];
  /** Sequence number of the cohort path section. */
  sequence: Scalars['NonNegativeInt']['input'];
};

/** Input for cohort path sections update. */
export type CohortPathSectionsUpdateInput = {
  /** List of cohort path sections */
  sections: Array<CohortPathSectionInput>;
};

/** Response for updating cohort path sections. */
export type CohortPathUpdateSectionsResponse = {
  __typename?: 'CohortPathUpdateSectionsResponse';
  /** List of cohort path sections */
  sections: Array<CohortPathSection>;
};

/** Paginated cohort path object. */
export type CohortPathsPaged = Paginated & {
  __typename?: 'CohortPathsPaged';
  /** List of cohort path objects */
  cohortPaths: Array<Maybe<CohortPath>>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page size) */
  pageCount: Scalars['Int']['output'];
};

/** Type representing a group of content for a subscription plan */
export type ContentCollection = {
  __typename?: 'ContentCollection';
  /** ID of the Content Collection */
  id: Scalars['ID']['output'];
};

/** The Course object. */
export type Course = LearningProduct & {
  __typename?: 'Course';
  /** Accreditations a course has */
  accreditations?: Maybe<Array<CourseAccreditation>>;
  /** Caption and subtitles the course has */
  captions?: Maybe<Array<CourseCaptionDetails>>;
  /** Categories the course is part of */
  categories: Array<CourseCategory>;
  /** Course's curriculum */
  curriculum: Curriculum;
  /** Description of the course */
  description?: Maybe<Scalars['SafeHtml']['output']>;
  /** Total duration of the course's content */
  duration?: Maybe<Scalars['CourseDuration']['output']>;
  /** Total duration of the course's content in seconds */
  durationInSeconds?: Maybe<Scalars['DurationInSeconds']['output']>;
  /** Total duration of video content only in seconds */
  durationVideoContent?: Maybe<Scalars['DurationInSeconds']['output']>;
  /** Whether a learner can enroll in a course or not */
  enrollable: Scalars['Boolean']['output'];
  /** Learners enrolled in a course */
  enrollments: CourseEnrollments;
  /** Headline to show under the title */
  headline?: Maybe<Scalars['String']['output']>;
  /** ID of the course. */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: CourseImages;
  /** Instructors of the Course */
  instructors: Array<CourseInstructor>;
  /** What you will learn in this course */
  learningOutcomes: Array<Scalars['String']['output']>;
  /** Instruction level of the course */
  level?: Maybe<DifficultyLevel>;
  /** Course locale e.g. en-US or en-GB */
  locale: Scalars['Locale']['output'];
  /** The UB organization this course belongs to, only visible if you are part of that organization */
  organization?: Maybe<Organization>;
  /** Organization specific course properties, only visible if you are part of that organization */
  organizationInfo?: Maybe<CourseOrganizationInfo>;
  /** Prerequisites for taking the course */
  prerequisites: Array<Scalars['String']['output']>;
  /** Primary topic course is tagged with */
  primaryTopic?: Maybe<Topic>;
  /** Whether the course is publicly available or not */
  private?: Maybe<Scalars['Boolean']['output']>;
  /** Promotional video for the course. Clients need to renew it if URL expires by querying it again. */
  promoVideo?: Maybe<CoursePromoVideo>;
  /** How do students rate the course */
  rating: CourseRating;
  /** Who should attend the course */
  targetAudience: Array<Scalars['String']['output']>;
  /** Title of the course. */
  title?: Maybe<Scalars['String']['output']>;
  /** Topics course is tagged with */
  topics?: Maybe<Array<Topic>>;
  /** When the course was last updated */
  updatedOn?: Maybe<Scalars['Date']['output']>;
  /** The URL to access the auto-enroll page */
  urlAutoEnroll?: Maybe<Scalars['URL']['output']>;
  /** The URL to access the course landing page */
  urlCourseLanding?: Maybe<Scalars['URL']['output']>;
  /** Mobile Native deep link of the course */
  urlMobileNativeDeeplink: Scalars['URL']['output'];
  /** Activity ID of xAPI statement to identify course */
  xapiActivityId: Scalars['ID']['output'];
};

/** Accreditations */
export type CourseAccreditation = {
  __typename?: 'CourseAccreditation';
  /** Start date of accreditation */
  compliantSince?: Maybe<Scalars['Date']['output']>;
  /** The amount of credits this accreditations supplies */
  creditCount: Scalars['NonNegativeFloat']['output'];
  /** The level of the accreditation */
  level?: Maybe<Scalars['String']['output']>;
  /** Technical subject area */
  subject?: Maybe<Scalars['String']['output']>;
  /** The type of accreditation */
  type: CourseAccreditationType;
};

/** Accreditation types */
export enum CourseAccreditationType {
  /** Continuing professional education */
  Cpe = 'CPE'
}

/** Course caption details such as locale, type, and source */
export type CourseCaptionDetails = {
  __typename?: 'CourseCaptionDetails';
  /** Whether the caption is automatically generated */
  automaticallyGenerated: Scalars['Boolean']['output'];
  /** The caption's locale */
  locale: Scalars['Locale']['output'];
  /** The caption type, either subtitles or closed captions */
  type: CaptionType;
};

/** A catalog of all courses available to the client */
export type CourseCatalogFullResponsePaged = {
  __typename?: 'CourseCatalogFullResponsePaged';
  /** List of courses in the catalog */
  courses: Array<Course>;
  /** Cursor to get the next page. Null if no more results available */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Sync Token to use for the next delta sync */
  syncToken: Scalars['String']['output'];
};

/** Changes to catalog of all courses available to the client */
export type CourseCatalogUpdatedResponsePaged = {
  __typename?: 'CourseCatalogUpdatedResponsePaged';
  /** Courses added since last sync */
  addedCourses: Array<Course>;
  /** Cursor to get the next page. Null if no more results available */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Course IDs for courses removed since last sync */
  removedCourses: Array<Scalars['ID']['output']>;
  /** Sync Token to use for the next delta sync */
  syncToken: Scalars['String']['output'];
  /** Courses updated since last sync */
  updatedCourses: Array<Course>;
};

/** Category the Course belongs to */
export type CourseCategory = Category & {
  __typename?: 'CourseCategory';
  /** ID of the category */
  id: Scalars['ID']['output'];
  /** Name of the category */
  name: Scalars['String']['output'];
  /** Subcategories belonging to the category */
  subcategories: Array<CourseSubCategory>;
  /** The URL to the category page this course is a part of */
  url: Scalars['URL']['output'];
};

/** Enrollments belonging to the course */
export type CourseEnrollments = {
  __typename?: 'CourseEnrollments';
  /** Exact count of how many students are there currently enrolled (course purchase and subscription). Requires token scope 'udemy:application' to access. */
  count: Scalars['Int']['output'];
  /**
   * Count of how many students are currently enrolled (course purchase and subscription) rounded to the nearest decimal
   * Exception: if there are fewer than 10 enrollments we show the exact amount (instead of 0)
   */
  roundedCount: Scalars['Int']['output'];
};

/** Course images by varying dimensions */
export type CourseImages = {
  __typename?: 'CourseImages';
  /** Course preview image with 125 pixels height */
  height125?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 200 pixels height */
  height200?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 48x27 dimensions in pixels */
  px48x27?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 50x50 dimensions in pixels */
  px50x50?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 75x75 dimensions in pixels */
  px75x75?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 96x54 dimensions in pixels */
  px96x54?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 100x100 dimensions in pixels */
  px100x100?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 240x135 dimensions in pixels */
  px240x135?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 304x171 dimensions in pixels */
  px304x171?: Maybe<Scalars['String']['output']>;
  /** Course preview image with 480x270 dimensions in pixels */
  px480x270?: Maybe<Scalars['String']['output']>;
};

/** Instructor for a course */
export type CourseInstructor = Instructor & {
  __typename?: 'CourseInstructor';
  /** ID of the Instructor */
  id: Scalars['ID']['output'];
  /** Instructor's image by varying pixels */
  images: InstructorImages;
  /** Instructor's name */
  name: Scalars['String']['output'];
  /** The URL to access the instructor page */
  url?: Maybe<Scalars['URL']['output']>;
};

/** Organization specific course properties */
export type CourseOrganizationInfo = {
  __typename?: 'CourseOrganizationInfo';
  /** True if this course was purchased separately from the marketplace */
  isMarketplaceImported?: Maybe<Scalars['Boolean']['output']>;
  /** True if this course was created specifically for this organization */
  isOrganizationSpecific?: Maybe<Scalars['Boolean']['output']>;
  /** If not null returns the Date this course will retire from organization's content collection */
  retiresOn?: Maybe<Scalars['Date']['output']>;
};

/** Object to store URL and expiration time for course's promo video */
export type CoursePromoVideo = {
  __typename?: 'CoursePromoVideo';
  /** Expiration time of the promo video URL. If null URL doesn't expire */
  expiresOn?: Maybe<Scalars['Timestamp']['output']>;
  /** URL for a promo video asset in mp4 format */
  url: Scalars['URL']['output'];
};

/** Ratings of a course */
export type CourseRating = {
  __typename?: 'CourseRating';
  /** Weighted average rating. Ranges from 0 to 5.0. */
  average?: Maybe<Scalars['AverageRating']['output']>;
  /** Number of ratings */
  count: Scalars['Int']['output'];
};

/** Search filters to apply on search request */
export type CourseSearchFilters = {
  /** Filter results based on closed caption language */
  closedCaptionLanguage?: InputMaybe<Array<LanguageCode>>;
  /** Filter results based on course language */
  language?: InputMaybe<Array<LanguageCode>>;
  /** Filter course based on difficulty level */
  level?: InputMaybe<Array<DifficultyLevel>>;
  /** Minimum average rating for the course. Ranges from 0 to 5.0. */
  minAverageRating?: InputMaybe<Scalars['AverageRating']['input']>;
  /** Whether or not course must have closed captions */
  mustHaveClosedCaption?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not course must have coding exercises */
  mustHaveCodingExercise?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not course must have practice tests */
  mustHavePracticeTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not course must have quizzes */
  mustHaveQuiz?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether or not course must have workspaces */
  mustHaveWorkspace?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter courses based on topics */
  topicIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter course based on video length */
  videoLength?: InputMaybe<Array<VideoLength>>;
};

/** List of Courses and additional data about search response */
export type CourseSearchResponse = Paginated & {
  __typename?: 'CourseSearchResponse';
  /** Total number of Courses matching the search query and filters. */
  count: Scalars['Int']['output'];
  /** List of Course objects. */
  courses: Array<Course>;
  /** Identifies available search filter facets. */
  filterOptions: Array<SearchAggregation>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages in search response */
  pageCount: Scalars['Int']['output'];
};

/** Sort order for courses in search response */
export enum CourseSearchSortType {
  /** Highest rated */
  Rating = 'RATING',
  /** Most relevant */
  Relevance = 'RELEVANCE',
  /** Most reviewed */
  Reviews = 'REVIEWS',
  /** Newest */
  Time = 'TIME'
}

/** Section of the course containing all types of curriculum items */
export type CourseSection = CurriculumItem & {
  __typename?: 'CourseSection';
  /** What will students be able to do at the end of this section? */
  description?: Maybe<Scalars['String']['output']>;
  /** Identifier for the course section */
  id: Scalars['ID']['output'];
  /** Content of the section */
  items: Array<CurriculumItem>;
  /** Title of the section */
  title: Scalars['String']['output'];
};

/** Response for create snapshot */
export type CourseSnapshotCreateResponse = {
  __typename?: 'CourseSnapshotCreateResponse';
  /** The versionId for the snapshot */
  versionId: Scalars['String']['output'];
};

/** SubCategory that is part of CourseCategory */
export type CourseSubCategory = SubCategory & {
  __typename?: 'CourseSubCategory';
  /** ID of the subcategory */
  id: Scalars['ID']['output'];
  /** Name of the subcategory */
  name: Scalars['String']['output'];
  /** The URL to the subcategory page this course is a part of */
  url: Scalars['URL']['output'];
};

/** Currencies available */
export enum CurrencyCode {
  /** Australian dollar */
  Aud = 'AUD',
  /** Brazilian real */
  Brl = 'BRL',
  /** Canadian dollar */
  Cad = 'CAD',
  /** Chilean peso */
  Clp = 'CLP',
  /** Colombian peso */
  Cop = 'COP',
  /** Danish crone */
  Dkk = 'DKK',
  /** Egyptian pound */
  Egp = 'EGP',
  /** Euro */
  Eur = 'EUR',
  /** Sterling */
  Gbp = 'GBP',
  /** Indonesian rupiah */
  Idr = 'IDR',
  /** Israel new shekel */
  Ils = 'ILS',
  /** Indian rupee */
  Inr = 'INR',
  /** Japanese yen */
  Jpy = 'JPY',
  /** South Korean won */
  Krw = 'KRW',
  /** Mexican peso */
  Mxn = 'MXN',
  /** Malaysian ringgit */
  Myr = 'MYR',
  /** Nigerian naira */
  Ngn = 'NGN',
  /** Norwegian krone */
  Nok = 'NOK',
  /** Peruvian sol */
  Pen = 'PEN',
  /** Philippine peso */
  Php = 'PHP',
  /** Polish złoty */
  Pln = 'PLN',
  /** Romanian leu */
  Ron = 'RON',
  /** Russian ruble */
  Rub = 'RUB',
  /** Singapore dollar */
  Sgd = 'SGD',
  /** Thai baht */
  Thb = 'THB',
  /** Turkish lira */
  Try = 'TRY',
  /** New Taiwan dollar */
  Twd = 'TWD',
  /** United States dollar */
  Usd = 'USD',
  /** Vietnamese dong */
  Vnd = 'VND',
  /** South African rand */
  Zar = 'ZAR'
}

/** Curriculum part of a course */
export type Curriculum = {
  __typename?: 'Curriculum';
  /** Each course section containing the course curriculum content */
  sections: Array<CourseSection>;
};

/** Interface for a curriculum item */
export type CurriculumItem = {
  /** Id of the curriculum item */
  id: Scalars['ID']['output'];
  /** Title of the curriculum item */
  title: Scalars['String']['output'];
};

/** Price option for computed price plan. Will be returned for daily plans */
export type DailySubscriptionPlanPricingOption = {
  __typename?: 'DailySubscriptionPlanPricingOption';
  /** Contains information about the discount context for a given subscription plan price option */
  discount?: Maybe<SubscriptionPlanDiscount>;
  /** Field containing details about the discounted trial subscription offer for a given user. Null indicates no trial is available */
  discountTrial?: Maybe<SubscriptionTrial>;
  /** ID of the price option: */
  id: Scalars['ID']['output'];
  /** Contains information about the license context for a given subscription plan price option */
  licenseContext?: Maybe<SubscriptionPlanLicenseContext>;
  /** The list price of the subscription price plan based on provided requested count from request */
  listPrice: Money;
  /** Field showing the prices for the option. */
  price?: Maybe<SubscriptionPlanPrice>;
  /** Interval for renewing the subscription plan ie the length of the subscription plan */
  renewalInterval: DateInterval;
  /** Field containing details about the trial subscription offer for a given user. Null indicates no trial is available */
  trial?: Maybe<SubscriptionTrial>;
};

/** Filter used to pass start and end date between which learning activity has to be returned. Date range should be less than or equal to 120 days when granularity is DAY level. */
export type DateFilter = {
  /** End date till which the learning activity has to be measured. */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Granularity at which learning activity trend response should be returned like DAY, MONTH. */
  granularity?: InputMaybe<DateIntervalType>;
  /** Start date from which the learning activity has to be measured. */
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Type representing details about an interval of dates */
export type DateInterval = {
  __typename?: 'DateInterval';
  /** The count of type in the interval */
  count: Scalars['Int']['output'];
  /** Type type of interval */
  type: DateIntervalType;
};

/** Supported units of time over which a subscription trial or billing cycle can occur */
export enum DateIntervalType {
  /** Daily interval */
  Day = 'DAY',
  /** Monthly interval */
  Month = 'MONTH',
  /** Weekly interval */
  Week = 'WEEK',
  /** Yearly interval */
  Year = 'YEAR'
}

/** Instruction level of the course */
export enum DifficultyLevel {
  /** Item is appropriate for all skill levels */
  AllLevels = 'ALL_LEVELS',
  /** Item is for beginners */
  Beginner = 'BEGINNER',
  /** Item is for students with expert knowledge */
  Expert = 'EXPERT',
  /** Item is for students with intermediates knowledge */
  Intermediate = 'INTERMEDIATE'
}

/** definition of different discount source types */
export enum DiscountSourceType {
  /** General discount coupon code */
  GeneralCoupon = 'GENERAL_COUPON',
  /** Instructor discount coupon code */
  InstructorCoupon = 'INSTRUCTOR_COUPON',
  /** Udemy discount coupon code */
  UdemyCoupon = 'UDEMY_COUPON'
}

/** DiscussionEntry represents a piece user-created content in context of discussion */
export type DiscussionEntry = {
  /** Author */
  author: User;
  /** The time the content was created at */
  createdAt: Scalars['DateTime']['output'];
  /** The time the content was deleted at */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The entry id */
  id: Scalars['UUID']['output'];
  /** The text part of the entry */
  text: Scalars['SafeHtml']['output'];
  /** The last time the content was updated at */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A discussion entry input type for creating and updating discussion entries i.e. Posts, Comments, etc. */
export type DiscussionEntryInput = {
  /** The text of the social entry */
  text: Scalars['SafeHtml']['input'];
};

/** The content of a component defined in the CMS */
export type DynamicWebContent = {
  __typename?: 'DynamicWebContent';
  /** The URL to the associated content. */
  contentUrl?: Maybe<Scalars['URL']['output']>;
  /** The text for the URL to the piece of content. */
  contentUrlText?: Maybe<Scalars['String']['output']>;
  /** The associated sub content contained within this piece of content. This allows us to create a collection of nested web content. */
  contents?: Maybe<Array<Maybe<DynamicWebContent>>>;
  /** The description text for the dynamic web content */
  description?: Maybe<Scalars['String']['output']>;
  /** The heading text for the dynamic web content */
  heading?: Maybe<Scalars['String']['output']>;
  /** The text for the content label. */
  image?: Maybe<DynamicWebContentImage>;
  /** The image URL of the secondary image asset associated with this content, usually an icon, with optional alt text */
  labelText?: Maybe<Scalars['String']['output']>;
  /** The image URL of the main image asset associated with this content, with optional alt text */
  secondaryImage?: Maybe<DynamicWebContentImage>;
  /** The tracking ID of the piece of content. To be used to uniquely identify the content in event tracking */
  trackingId?: Maybe<Scalars['ID']['output']>;
};

/** The dynamic web content query filter options */
export type DynamicWebContentFilters = {
  /** The ID of the content to fetch (e.g. `promotionItem`, `organizationLogs`, etc) */
  contentId: Scalars['String']['input'];
  /** The overriding parameters that will be parsed from URL */
  contentPreviewOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The URL query param that will be parsed to enable returning the content that has been saved but not yet published in the CMS. */
  isContentPreviewEnabled?: Scalars['Boolean']['input'];
  /** The specific page type for the dynamic web content that will match values set in the CMS */
  pageType: DynamicWebContentPageType;
};

/** The dynamic web image object. This combines the image URL and the alt text for the image for a11y purposes. */
export type DynamicWebContentImage = {
  __typename?: 'DynamicWebContentImage';
  /** The alt text of the image for a11y purposes. */
  altText?: Maybe<Scalars['String']['output']>;
  /** The URL to the image asset */
  url?: Maybe<Scalars['URL']['output']>;
};

/** The specific page type for the dynamic web content. This is important for narrowing down the content in the CMS to where Marketing wants to render it. */
export enum DynamicWebContentPageType {
  /** Logged out home page of udemy */
  LoggedOutHomePage = 'LOGGED_OUT_HOME_PAGE',
  /** Personal Plan Landing page */
  PersonalPlanLandingPage = 'PERSONAL_PLAN_LANDING_PAGE',
  /** Udemy Business request demo form */
  RequestDemoForm = 'REQUEST_DEMO_FORM'
}

/** Lecture in form of an EBook (deprecated) */
export type EBookLecture = CurriculumItem & Lecture & {
  __typename?: 'EBookLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Total duration of the lecture's content in seconds */
  durationInSeconds: Scalars['DurationInSeconds']['output'];
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** A record of user's enrollment to a learning product */
export type Enrollment = {
  __typename?: 'Enrollment';
  /** Time of enrollment */
  createdAt: Scalars['DateTime']['output'];
  /** Learning Product to which the enrollment has been created */
  learningProduct: LearningProduct;
};

/** Response object for enrollment creation */
export type EnrollmentCreateResponse = {
  __typename?: 'EnrollmentCreateResponse';
  /** Enrollment */
  enrollment?: Maybe<Enrollment>;
};

/** Input for enrolling to learning product */
export type EnrollmentsLearningProductInput = {
  /** ID of the learning product */
  id: Scalars['ID']['input'];
  /** Type of the learning product */
  type: EnrollmentsLearningProductInputType;
  /** Version ID of the learning product */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

/** Learning product type input */
export enum EnrollmentsLearningProductInputType {
  /** Course */
  Course = 'COURSE'
}

/** Lecture that refers to an external source (deprecated) */
export type ExternalLecture = CurriculumItem & Lecture & {
  __typename?: 'ExternalLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** Stores realtime attribute like build_number->20220322_v1 */
export type FeatureRequestAttributeInput = {
  /** Key, ex: build_number */
  key: Scalars['String']['input'];
  /** Value, ex: 20220322_v1 */
  value: Scalars['String']['input'];
};

/** Holds assigned feature variant  */
export type FeatureVariantAssignment = {
  __typename?: 'FeatureVariantAssignment';
  /** Configuration data of assigned feature variant */
  configuration?: Maybe<Scalars['JSON']['output']>;
  /** List of experiment ids bucketed for current feature */
  experimentIds: Array<Scalars['Int']['output']>;
  /** Feature code of assigned feature variant */
  featureCode: Scalars['String']['output'];
  /** Shows whether this feature variant is in experiment */
  isInExperiment?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the presentation on the frontend is the actual response of the Experimentation Platform or not. */
  isLocalDefault: Scalars['Boolean']['output'];
};

/** Holds filters for feature variant assignments */
export type FeatureVariantAssignmentFilters = {
  /** Filter by real time attributes */
  realtimeAttributes?: InputMaybe<Array<FeatureRequestAttributeInput>>;
};

/** Feedback object */
export type Feedback = {
  __typename?: 'Feedback';
  /** ID associated with Feedback */
  id: Scalars['ID']['output'];
  /** Option selected when storing the feedback */
  option: FeedbackOption;
  /** Text feedback to a target */
  text?: Maybe<Scalars['String']['output']>;
};

/** Input to create Feedback */
export type FeedbackCreateInput = {
  /** Option selected when storing a feedback */
  option: FeedbackOption;
  /** Object to give feedback to */
  target: FeedbackInputTarget;
  /** Text feedback to a target */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Response for storing a feedback */
export type FeedbackCreateResponse = {
  __typename?: 'FeedbackCreateResponse';
  /** The feedback created if the mutation was successful */
  feedback?: Maybe<Feedback>;
};

/** Polymorphic object to represent what the feedback is being applied to */
export type FeedbackInputTarget = {
  /** ID of the target */
  id: Scalars['ID']['input'];
  /** Type of the target */
  type: FeedbackTargetType;
};

/** Possible feedback options */
export enum FeedbackOption {
  /** Negative feedback is given to a target */
  Negative = 'NEGATIVE',
  /** Positive feedback is given to a target */
  Positive = 'POSITIVE',
  /** Feedback to a target is unselected, or removed */
  Unselected = 'UNSELECTED'
}

/** Possible object targets to apply the feedback to */
export enum FeedbackTargetType {
  /** Feedback is given to a course section */
  CourseSection = 'COURSE_SECTION',
  /** Feedback is given to a learning assistant chat message */
  LearningAssistantChatMessage = 'LEARNING_ASSISTANT_CHAT_MESSAGE',
  /** Feedback is given to a lecture */
  Lecture = 'LECTURE'
}

/** Lecture in the form of a single file (deprecated) */
export type FileLecture = CurriculumItem & Lecture & {
  __typename?: 'FileLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** Instructor for a learning product */
export type Instructor = {
  /** ID of the Instructor */
  id: Scalars['ID']['output'];
  /** Instructor's image by varying pixels */
  images: InstructorImages;
  /** Instructor's name */
  name: Scalars['String']['output'];
  /** The URL to access the instructor page */
  url?: Maybe<Scalars['URL']['output']>;
};

/** Instructor's account balance */
export type InstructorAccountBalance = {
  __typename?: 'InstructorAccountBalance';
  /** Instructor's current account balance amount */
  amount: Scalars['NonNegativeFloat']['output'];
};

/** Instructor's account issues */
export type InstructorAccountIssues = {
  __typename?: 'InstructorAccountIssues';
  /** If instructor has no payout method */
  noPayoutMethod: Scalars['Boolean']['output'];
  /** If instructor has no tax forms */
  noTaxForms: Scalars['Boolean']['output'];
};

/** Instructor images by varying dimensions */
export type InstructorImages = {
  __typename?: 'InstructorImages';
  /** Instructor image with 50x50 dimensions in pixels */
  px50x50?: Maybe<Scalars['String']['output']>;
};

/** Instructor's payout */
export type InstructorPayout = {
  __typename?: 'InstructorPayout';
  /** Payout amount */
  amount: Scalars['NonNegativeFloat']['output'];
  /** Payout created date */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** Payout currency */
  currency: CurrencyCode;
  /** Payout id */
  id: Scalars['ID']['output'];
  /** Payout method type */
  payoutMethodType?: Maybe<PaymentMethodType>;
  /** Payout status */
  status: PayoutStatusType;
  /** Payout tax amount */
  taxAmount: Scalars['NonNegativeFloat']['output'];
};

/** Instructor's payouts paged */
export type InstructorPayoutsPaged = Paginated & {
  __typename?: 'InstructorPayoutsPaged';
  /** If instructor has payouts older than limit year */
  hasOlderPayouts: Scalars['Boolean']['output'];
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** Number of pages of instructor's payouts */
  pageCount: Scalars['Int']['output'];
  /** List of instructor's payouts */
  payouts?: Maybe<Array<InstructorPayout>>;
};

/** The Lab object. */
export type Lab = LearningProduct & {
  __typename?: 'Lab';
  /** Bulleted list of things a person will accomplish in this Lab. */
  activities: Array<Scalars['String']['output']>;
  /** Top level description of the Lab. */
  description?: Maybe<Scalars['SafeHtml']['output']>;
  /** ID of the Lab. */
  id: Scalars['ID']['output'];
  /** The Lab's Instructors */
  instructors: Array<LabInstructor>;
  /** Bulleted list of things a person will learn in this Lab. */
  learningOutcomes: Array<Scalars['String']['output']>;
  /** Upper bound of estimated time (in seconds) to complete Lab. */
  maxEstimatedTime: Scalars['DurationInSeconds']['output'];
  /** Metadata associated with the lab */
  metadata?: Maybe<LabMetaData>;
  /** Lower bound of estimated time (in seconds) to complete Lab. */
  minEstimatedTime: Scalars['DurationInSeconds']['output'];
  /** Bulleted list of things a person should already know in order to do this Lab. */
  prerequisites: Array<Scalars['String']['output']>;
  /** Title of the Lab. */
  title: Scalars['String']['output'];
  /** The Lab's topics */
  topics?: Maybe<Array<Topic>>;
};

/** Instructor for a lab */
export type LabInstructor = Instructor & {
  __typename?: 'LabInstructor';
  /** ID of the Instructor */
  id: Scalars['ID']['output'];
  /** Instructor's image by varying pixels */
  images: InstructorImages;
  /** Instructor's name */
  name: Scalars['String']['output'];
  /** The URL to access the instructor page */
  url?: Maybe<Scalars['URL']['output']>;
};

/** MetaData for a lab */
export type LabMetaData = {
  __typename?: 'LabMetaData';
  /** Unique analytics ID for this instance of Lab returned from the server in this request. */
  trackingId?: Maybe<Scalars['String']['output']>;
};

/** List of Labs and additional data about search response */
export type LabSearchResponse = {
  __typename?: 'LabSearchResponse';
  /** Total number of Labs matching the search query and filters. */
  count: Scalars['Int']['output'];
  /** Identifies available search filter facets. */
  filterOptions: Array<SearchAggregation>;
  /** List of Lab objects. */
  labs: Array<Lab>;
  /** Search analytics tracking id; for uniquely identifying this query and result set; for this request */
  trackingId: Scalars['String']['output'];
};

/** The LanguageCode scalar type as defined by ISO 639-1. */
export enum LanguageCode {
  /** Afrikaans */
  Af = 'AF',
  /** Akan */
  Ak = 'AK',
  /** Amharic */
  Am = 'AM',
  /** Arabic */
  Ar = 'AR',
  /** Assamese */
  As = 'AS',
  /** Azerbaijani */
  Az = 'AZ',
  /** Belarusian */
  Be = 'BE',
  /** Bulgarian */
  Bg = 'BG',
  /** Bambara */
  Bm = 'BM',
  /** Bangla */
  Bn = 'BN',
  /** Tibetan */
  Bo = 'BO',
  /** Breton */
  Br = 'BR',
  /** Bosnian */
  Bs = 'BS',
  /** Catalan */
  Ca = 'CA',
  /** Chechen */
  Ce = 'CE',
  /** Czech */
  Cs = 'CS',
  /** Church Slavic */
  Cu = 'CU',
  /** Welsh */
  Cy = 'CY',
  /** Danish */
  Da = 'DA',
  /** German */
  De = 'DE',
  /** Dzongkha */
  Dz = 'DZ',
  /** Ewe */
  Ee = 'EE',
  /** Greek */
  El = 'EL',
  /** English */
  En = 'EN',
  /** Esperanto */
  Eo = 'EO',
  /** Spanish */
  Es = 'ES',
  /** Estonian */
  Et = 'ET',
  /** Basque */
  Eu = 'EU',
  /** Persian */
  Fa = 'FA',
  /** Fulah */
  Ff = 'FF',
  /** Finnish */
  Fi = 'FI',
  /** Faroese */
  Fo = 'FO',
  /** French */
  Fr = 'FR',
  /** Western Frisian */
  Fy = 'FY',
  /** Irish */
  Ga = 'GA',
  /** Scottish Gaelic */
  Gd = 'GD',
  /** Galician */
  Gl = 'GL',
  /** Gujarati */
  Gu = 'GU',
  /** Manx */
  Gv = 'GV',
  /** Hausa */
  Ha = 'HA',
  /** Hebrew */
  He = 'HE',
  /** Hindi */
  Hi = 'HI',
  /** Croatian */
  Hr = 'HR',
  /** Hungarian */
  Hu = 'HU',
  /** Armenian */
  Hy = 'HY',
  /** Interlingua */
  Ia = 'IA',
  /** Indonesian */
  Id = 'ID',
  /** Igbo */
  Ig = 'IG',
  /** Sichuan Yi */
  Ii = 'II',
  /** Icelandic */
  Is = 'IS',
  /** Italian */
  It = 'IT',
  /** Japanese */
  Ja = 'JA',
  /** Javanese */
  Jv = 'JV',
  /** Georgian */
  Ka = 'KA',
  /** Kikuyu */
  Ki = 'KI',
  /** Kazakh */
  Kk = 'KK',
  /** Kalaallisut */
  Kl = 'KL',
  /** Khmer */
  Km = 'KM',
  /** Kannada */
  Kn = 'KN',
  /** Korean */
  Ko = 'KO',
  /** Kashmiri */
  Ks = 'KS',
  /** Kurdish */
  Ku = 'KU',
  /** Cornish */
  Kw = 'KW',
  /** Kyrgyz */
  Ky = 'KY',
  /** Luxembourgish */
  Lb = 'LB',
  /** Ganda */
  Lg = 'LG',
  /** Lingala */
  Ln = 'LN',
  /** Lao */
  Lo = 'LO',
  /** Lithuanian */
  Lt = 'LT',
  /** Luba-Katanga */
  Lu = 'LU',
  /** Latvian */
  Lv = 'LV',
  /** Malagasy */
  Mg = 'MG',
  /** Māori */
  Mi = 'MI',
  /** Macedonian */
  Mk = 'MK',
  /** Malayalam */
  Ml = 'ML',
  /** Mongolian */
  Mn = 'MN',
  /** Marathi */
  Mr = 'MR',
  /** Malay */
  Ms = 'MS',
  /** Maltese */
  Mt = 'MT',
  /** Burmese */
  My = 'MY',
  /** Norwegian (Bokmål) */
  Nb = 'NB',
  /** North Ndebele */
  Nd = 'ND',
  /** Nepali */
  Ne = 'NE',
  /** Dutch */
  Nl = 'NL',
  /** Norwegian Nynorsk */
  Nn = 'NN',
  /** Norwegian */
  No = 'NO',
  /** Oromo */
  Om = 'OM',
  /** Odia */
  Or = 'OR',
  /** Ossetic */
  Os = 'OS',
  /** Punjabi */
  Pa = 'PA',
  /** Polish */
  Pl = 'PL',
  /** Pashto */
  Ps = 'PS',
  /** Portuguese */
  Pt = 'PT',
  /** Portuguese (Brazil) */
  PtBr = 'PT_BR',
  /** Portuguese (Portugal) */
  PtPt = 'PT_PT',
  /** Quechua */
  Qu = 'QU',
  /** Romansh */
  Rm = 'RM',
  /** Rundi */
  Rn = 'RN',
  /** Romanian */
  Ro = 'RO',
  /** Russian */
  Ru = 'RU',
  /** Kinyarwanda */
  Rw = 'RW',
  /** Sindhi */
  Sd = 'SD',
  /** Northern Sami */
  Se = 'SE',
  /** Sango */
  Sg = 'SG',
  /** Sinhala */
  Si = 'SI',
  /** Slovak */
  Sk = 'SK',
  /** Slovenian */
  Sl = 'SL',
  /** Shona */
  Sn = 'SN',
  /** Somali */
  So = 'SO',
  /** Albanian */
  Sq = 'SQ',
  /** Serbian */
  Sr = 'SR',
  /** Sundanese */
  Su = 'SU',
  /** Swedish */
  Sv = 'SV',
  /** Swahili */
  Sw = 'SW',
  /** Tamil */
  Ta = 'TA',
  /** Telugu */
  Te = 'TE',
  /** Tajik */
  Tg = 'TG',
  /** Thai */
  Th = 'TH',
  /** Tigrinya */
  Ti = 'TI',
  /** Turkmen */
  Tk = 'TK',
  /** Tongan */
  To = 'TO',
  /** Turkish */
  Tr = 'TR',
  /** Tatar */
  Tt = 'TT',
  /** Uyghur */
  Ug = 'UG',
  /** Ukrainian */
  Uk = 'UK',
  /** Urdu */
  Ur = 'UR',
  /** Uzbek */
  Uz = 'UZ',
  /** Vietnamese */
  Vi = 'VI',
  /** Volapük */
  Vo = 'VO',
  /** Wolof */
  Wo = 'WO',
  /** Xhosa */
  Xh = 'XH',
  /** Yiddish */
  Yi = 'YI',
  /** Yoruba */
  Yo = 'YO',
  /** Chinese */
  Zh = 'ZH',
  /** Chinese (Simplified) */
  ZhCn = 'ZH_CN',
  /** Chinese (Traditional) */
  ZhTw = 'ZH_TW',
  /** Zulu */
  Zu = 'ZU'
}

/** Represents the learning activity period like daily or monthly and the corresponding date in value. For monthly, day portion in the value will be 1st of the month. */
export type LearningActivityPeriod = {
  __typename?: 'LearningActivityPeriod';
  /** Day on which learning activity happened. */
  day?: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Month on which learning activity happened. */
  month?: Maybe<Scalars['NonNegativeInt']['output']>;
  /** Type of the interval. */
  type?: Maybe<DateIntervalType>;
  /** Year on which learning activity happened. */
  year?: Maybe<Scalars['NonNegativeInt']['output']>;
};

/** Contains records of time spent in a learning activity */
export type LearningActivityRecord = {
  __typename?: 'LearningActivityRecord';
  /** Period of the learning activity. */
  period?: Maybe<LearningActivityPeriod>;
  /** Learning mintues spent between startDate and endDate. */
  timeSpentInMinutes: Scalars['NonNegativeFloat']['output'];
};

/** Represents the learning activity in trend view. */
export type LearningActivityTrend = {
  __typename?: 'LearningActivityTrend';
  /** List of learning activity over a period of time. */
  learningActivityRecords?: Maybe<Array<Maybe<LearningActivityRecord>>>;
};

/** Filters used to fetch learning activity. */
export type LearningActivityTrendFilters = {
  /** Filter used to pass start and end date between which learning activity has to be returned. */
  dateFilter?: InputMaybe<DateFilter>;
};

/** Response for initiating a chat session */
export type LearningAssistantChatInitiateResponse = {
  __typename?: 'LearningAssistantChatInitiateResponse';
  /** Server returns an ID for the chat */
  chatId: Scalars['ID']['output'];
};

/** Represent learning products that can be shared within a chat message (deprecated) */
export type LearningAssistantChatLearningProduct = {
  __typename?: 'LearningAssistantChatLearningProduct';
  /** List of learning products */
  learningProducts: Array<LearningAssistantLearningProduct>;
};

/** Represent learning products or curriculum items that can be shared within a chat message */
export type LearningAssistantChatLearningProductOrCurriculumItem = {
  __typename?: 'LearningAssistantChatLearningProductOrCurriculumItem';
  /** List of learning products or curriculum items */
  learningProducts: Array<LearningAssistantLearningProductOrCurriculumItem>;
};

/** Represent a chat message */
export type LearningAssistantChatMessage = {
  __typename?: 'LearningAssistantChatMessage';
  /** Timestamp indicating when the message was created */
  created: Scalars['Timestamp']['output'];
  /** ID of the chat message */
  id: Scalars['ID']['output'];
  /** Items of the chat message */
  items: Array<LearningAssistantChatMessageItem>;
  /** Role of the chat message */
  role: LearningAssistantChatMessageRole;
};

/** Union of possible chat message items */
export type LearningAssistantChatMessageItem = LearningAssistantChatLearningProduct | LearningAssistantChatLearningProductOrCurriculumItem | LearningAssistantChatTextMessage;

/** Represents the roles of chat messages */
export enum LearningAssistantChatMessageRole {
  /** Represents messages generated by the learning assistant */
  Assistant = 'ASSISTANT',
  /** Represents messages sent by users */
  User = 'USER'
}

/** Response for sending a chat message */
export type LearningAssistantChatMessageSendResponse = {
  __typename?: 'LearningAssistantChatMessageSendResponse';
  /** Chat message returned by the server */
  message: LearningAssistantChatMessage;
};

/** Paginated chat messages */
export type LearningAssistantChatMessagesPaged = Paginated & {
  __typename?: 'LearningAssistantChatMessagesPaged';
  /** Chat messages */
  items: Array<Maybe<LearningAssistantChatMessage>>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count/page size) */
  pageCount: Scalars['Int']['output'];
};

/** Represents different types of chat sessions */
export enum LearningAssistantChatName {
  /** Discovery Chat */
  DiscoveryChat = 'DISCOVERY_CHAT',
  /** In Course Chat */
  InCourseChat = 'IN_COURSE_CHAT',
  /** Interactive Question Generation Chat */
  IqgChat = 'IQG_CHAT',
  /** Skills Chat */
  SkillsChat = 'SKILLS_CHAT'
}

/** Represent a text message that can be shared within a chat message */
export type LearningAssistantChatTextMessage = {
  __typename?: 'LearningAssistantChatTextMessage';
  /** The text content */
  text: Scalars['String']['output'];
};

/** Input for sending a text chat message */
export type LearningAssistantChatTextMessageInput = {
  /** The text content */
  text: Scalars['String']['input'];
};

/** Represent a curriculum item that extends an actual curriculum item */
export type LearningAssistantCurriculumItem = {
  __typename?: 'LearningAssistantCurriculumItem';
  /** The description of the curriculum item */
  learningAssistantGeneratedDescription?: Maybe<Scalars['String']['output']>;
  /** The curriculum item */
  learningProduct: CurriculumItem;
};

/** Represent a learning assistant-specific learning product that extends an actual learning product */
export type LearningAssistantLearningProduct = {
  __typename?: 'LearningAssistantLearningProduct';
  /** The description of the learning product */
  learningAssistantGeneratedDescription?: Maybe<Scalars['String']['output']>;
  /** The learning product */
  learningProduct: LearningProduct;
};

/** Union of possible chat message item types that can be shared within a chat message item */
export type LearningAssistantLearningProductOrCurriculumItem = LearningAssistantCurriculumItem | LearningAssistantLearningProduct;

/** Prompt suggestions that users can ask the assistant */
export type LearningAssistantSuggestedPrompt = {
  __typename?: 'LearningAssistantSuggestedPrompt';
  /** ID of the suggested prompt */
  id: Scalars['ID']['output'];
  /** Prompt string that serves as a template to be potentially interpolated with paramters by the FE, such as topic or occupation name */
  template: Scalars['String']['output'];
};

/** Represents all pages that supporte displaying suggested prompts */
export enum LearningAssistantSuggestedPromptPageSource {
  /** AI Assistant Page */
  AiAssistantPage = 'AI_ASSISTANT_PAGE',
  /** Course Landing Page */
  CourseLandingPage = 'COURSE_LANDING_PAGE',
  /** Course Taking Page */
  CourseTakingPage = 'COURSE_TAKING_PAGE',
  /** Logged In Home Page */
  LoggedInHomePage = 'LOGGED_IN_HOME_PAGE',
  /** Search Results Page */
  SearchResultsPage = 'SEARCH_RESULTS_PAGE'
}

/** Paginated suggested prompts */
export type LearningAssistantSuggestedPromptsPaged = Paginated & {
  __typename?: 'LearningAssistantSuggestedPromptsPaged';
  /** Suggested prompts */
  items: Array<LearningAssistantSuggestedPrompt>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count/page size) */
  pageCount: Scalars['Int']['output'];
};

/** The Learning Community */
export type LearningCommunity = {
  __typename?: 'LearningCommunity';
  /** The reason for creating the learning community */
  creationReason: LearningCommunityCreateReason;
  /** The learning community description */
  description?: Maybe<Scalars['String']['output']>;
  /** The learning community ID. */
  id: Scalars['UUID']['output'];
  /** The members to the learning community, owner/creator is included here too */
  members: Array<User>;
  /** The number of members in the learning community */
  numberOfMembers: Scalars['Int']['output'];
  /** The organization in which the learning community is created */
  organizationId: Scalars['ID']['output'];
  /** The owner/creator of the learning community */
  owner: User;
  /** The learning community title */
  title: Scalars['String']['output'];
  /** The topics selected for the learning community, should not be empty */
  topics: Array<Topic>;
  /** The visibility of the learning community, defaults to PRIVATE */
  visibility: LearningCommunityVisibility;
};

/** A page of Learning Community Activities */
export type LearningCommunityActivitiesPaged = {
  __typename?: 'LearningCommunityActivitiesPaged';
  /** The cursor to the next Learning Community Activities Page */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The activities in this page */
  items: Array<LearningCommunityActivity>;
};

/** An activity is an event initiated by a user */
export type LearningCommunityActivity = {
  __typename?: 'LearningCommunityActivity';
  /** The user who initiated the event */
  by: User;
  /** The event */
  event: LearningCommunityActivityEvent;
  /** The activity id */
  id: Scalars['UUID']['output'];
  /** The reactions to the activity */
  reactions?: Maybe<Array<LearningCommunityActivityUserReactions>>;
};

/** All the activity types presented in a learning community activity feed */
export type LearningCommunityActivityEvent = LearningCommunityCurriculumItemEvent | LearningCommunityPostEvent | LearningProductEvent;

/** The response for updating a learning community activity */
export type LearningCommunityActivityUpdateResponse = {
  __typename?: 'LearningCommunityActivityUpdateResponse';
  /** The learning community activity which has been updated */
  activity: LearningCommunityActivity;
};

/** The type of reaction that is being added or removed */
export type LearningCommunityActivityUserReactionInput = {
  /** The string representation of the reaction */
  type: LearningCommunityActivityUserReactionType;
};

/** The type of reaction available to users */
export enum LearningCommunityActivityUserReactionType {
  /** A celebrate reaction type */
  Celebrate = 'CELEBRATE',
  /** An insightful reaction type */
  Insightful = 'INSIGHTFUL',
  /** A like reaction type */
  Like = 'LIKE',
  /** A plus one reaction type */
  PlusOne = 'PLUS_ONE'
}

/** The reactions and the users who reacted to a learning community activity */
export type LearningCommunityActivityUserReactions = {
  __typename?: 'LearningCommunityActivityUserReactions';
  /** The number of users who reacted */
  count: Scalars['Int']['output'];
  /** The string representation of the reaction */
  type: LearningCommunityActivityUserReactionType;
  /** A list of the users who reacted */
  users: Array<Maybe<User>>;
};

/** Response for creating a new Learning Community */
export type LearningCommunityCreateOrUpdateResponse = {
  __typename?: 'LearningCommunityCreateOrUpdateResponse';
  /** The learning community created or updated if the mutation was successful */
  learningCommunity?: Maybe<LearningCommunity>;
};

/** The Reason for creating a new Learning Community */
export type LearningCommunityCreateReason = {
  __typename?: 'LearningCommunityCreateReason';
  /** If type is equal to OTHER you have to provide a text for Reason */
  text?: Maybe<Scalars['String']['output']>;
  /** The Reason for creating the learning community */
  type: LearningCommunityCreateReasonType;
};

/** The Reason for creating a new Learning Community */
export type LearningCommunityCreateReasonInput = {
  /** If type is equal to OTHER, you have to provide a text for Reason. */
  text?: InputMaybe<Scalars['String']['input']>;
  /** The Reason for creating the learning community */
  type: LearningCommunityCreateReasonType;
};

/** The Reasons for creating a new Learning Community */
export enum LearningCommunityCreateReasonType {
  /** The reason to create the Learning Community was to initiate a company-wide transformation */
  CompanyWideTransformationInitiate = 'COMPANY_WIDE_TRANSFORMATION_INITIATE',
  /** The reason to create the Learning Community was compliance training */
  ComplianceTraining = 'COMPLIANCE_TRAINING',
  /** The reason to create the Learning Community was to learn new skill(s) in a social setting */
  LearnNewSkillsTogetherWithOthers = 'LEARN_NEW_SKILLS_TOGETHER_WITH_OTHERS',
  /** The reason to create the Learning Community was to onboard new member(s) to a team */
  OnboardingANewTeam = 'ONBOARDING_A_NEW_TEAM',
  /** The reason to create the Learning Community was not included so a free text is provided */
  Other = 'OTHER',
  /** The reason to create the Learning Community was to Study for a certificate */
  StudyForACertificate = 'STUDY_FOR_A_CERTIFICATE'
}

/** An event happening to a curriculum item which is part of a course */
export type LearningCommunityCurriculumItemEvent = {
  __typename?: 'LearningCommunityCurriculumItemEvent';
  /** The course containing the item */
  course: Course;
  /** The moment in time which the event happend */
  dateTime: Scalars['DateTime']['output'];
  /** Type of event happening to the item */
  eventType: LearningCommunityCurriculumItemEventType;
  /** The curriculum item */
  item: CurriculumItem;
};

/** The type of events that happens to a curriculum item */
export enum LearningCommunityCurriculumItemEventType {
  /** Completed working on/watching a curriculum item */
  Completed = 'COMPLETED'
}

/** Response for deleting a Learning Community */
export type LearningCommunityDeleteResponse = {
  __typename?: 'LearningCommunityDeleteResponse';
  /** The ID of the deleted learning community if the mutation was successful */
  learningCommunityId?: Maybe<Scalars['UUID']['output']>;
};

/** The input used for creating a new Learning Community */
export type LearningCommunityInput = {
  /** The reason for creating the learning community */
  creationReason: LearningCommunityCreateReasonInput;
  /** The learning community description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The learning community title */
  title: Scalars['String']['input'];
  /** The topics selected for the learning community, can not be empty */
  topicIds: Array<Scalars['ID']['input']>;
  /** The visibility of the learning community */
  visibility?: InputMaybe<LearningCommunityVisibility>;
};

/** The input used for inviting new members to the learning community */
export type LearningCommunityInviteInput = {
  /** Invitation language */
  language: LanguageCode;
  /** Invitation Message */
  message: Scalars['String']['input'];
};

/** A post in a learning community activity feed */
export type LearningCommunityPost = DiscussionEntry & {
  __typename?: 'LearningCommunityPost';
  /** Author */
  author: User;
  /** The time the content was created at */
  createdAt: Scalars['DateTime']['output'];
  /** The time the content was deleted at */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The post id */
  id: Scalars['UUID']['output'];
  /** The text part of the entry */
  text: Scalars['SafeHtml']['output'];
  /** The last time the content was updated at */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** The response for creating a new post in the learning community activity feed */
export type LearningCommunityPostCreateOrUpdateResponse = {
  __typename?: 'LearningCommunityPostCreateOrUpdateResponse';
  /** The post created inside a learning community activity feed */
  post?: Maybe<LearningCommunityPost>;
};

/** A post event happening in a learning community activity feed */
export type LearningCommunityPostEvent = {
  __typename?: 'LearningCommunityPostEvent';
  /** The moment in time which the event happened */
  dateTime: Scalars['DateTime']['output'];
  /** The post */
  post: LearningCommunityPost;
};

/** The visibility of the learning community */
export enum LearningCommunityVisibility {
  /** The learning community is visible to only members */
  Private = 'PRIVATE',
  /** The learning community is visible to everyone */
  Public = 'PUBLIC'
}

/** A learning objective is a brief statements that describe what students will be expected to learn. */
export type LearningObjective = {
  __typename?: 'LearningObjective';
  /** Activities in the Learning Objective */
  activities: Array<Maybe<LearningObjectiveActivity>>;
  /** Unique id to identify for learning objective */
  id: Scalars['ID']['output'];
  /** More granular learning objectives associated with this learning objective */
  learningObjectives: Array<Maybe<LearningObjective>>;
  /** Name of learning objective */
  name: Scalars['String']['output'];
};

/** Represents the types of activities we can map to a Learning Objective. */
export type LearningObjectiveActivity = ArticleLecture | AssessmentItem | AudioLecture | EBookLecture | ExternalLecture | FileLecture | Lab | PresentationLecture | ProLearningPath | VideoLecture | VideoMashupLecture;

/** Allows the user to set the activity id's and the activity types they want to query for. */
export type LearningObjectiveActivityInput = {
  /** Activity ID */
  activityId: Scalars['ID']['input'];
  /** Activity Type */
  activityType: LearningObjectiveActivityTypeInput;
};

/** Represent the types of activities we can map to a Learning Objective. */
export enum LearningObjectiveActivityTypeInput {
  /** This activity is an Adaptive assessment question */
  AssessmentQuestion = 'ASSESSMENT_QUESTION',
  /** This activity is a lab */
  Lab = 'LAB',
  /** This activity is a lab task */
  LabTask = 'LAB_TASK',
  /** This activity is a lecture */
  Lecture = 'LECTURE'
}

/** This holds data to create a learning objective */
export type LearningObjectiveInput = {
  /** Id to identify for learning objective */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Child Learning objectives associated with this learning objective. */
  learningObjectives: Array<LearningObjectiveInput>;
  /** Name of learning objective */
  name: Scalars['String']['input'];
};

/** The learning path object. */
export type LearningPath = {
  /** Badges of the learning path. */
  badges?: Maybe<Array<BadgeClass>>;
  /** Description of the learning path. */
  description?: Maybe<Scalars['String']['output']>;
  /** ID of the learning path. */
  id: Scalars['ID']['output'];
  /** Is the user enrolled in this learning path or not */
  isEnrolled: Scalars['Boolean']['output'];
  /** Number of items in the learning path. */
  itemCount: Scalars['Int']['output'];
  /** Total No of enrollments for the learning path. */
  numberOfEnrollments: Scalars['Int']['output'];
  /** Owner user for the learning path */
  owner: LearningPathInstructor;
  /** Title of the learning path. */
  title: Scalars['String']['output'];
  /** Topics associated with the learning path. */
  topics?: Maybe<Array<Topic>>;
  /** Unique analytics ID for this instance of LearningPath returned from the server in this request. */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** Landing page to view this LearningPath */
  urlLanding: Scalars['URL']['output'];
};

/** Response for creating/updating a learning path from skills */
export type LearningPathCreateResponse = {
  __typename?: 'LearningPathCreateResponse';
  /** Learning path created/updated */
  learningPath?: Maybe<ProLearningPath>;
};

/** Instructor for a Learning Path */
export type LearningPathInstructor = Instructor & {
  __typename?: 'LearningPathInstructor';
  /** ID of the Instructor */
  id: Scalars['ID']['output'];
  /** Instructor's image by varying pixels */
  images: InstructorImages;
  /** Instructor's name */
  name: Scalars['String']['output'];
  /** The URL to access the instructor page */
  url?: Maybe<Scalars['URL']['output']>;
};

/** Search filters to apply on path search request */
export type LearningPathSearchFilters = {
  /** Indicates whether path must have a certificate or not */
  mustHaveCertificate?: InputMaybe<Scalars['Boolean']['input']>;
};

/** List of LearningPath and additional data about search response */
export type LearningPathSearchResponse = {
  __typename?: 'LearningPathSearchResponse';
  /** Total number of Paths matching the search query and filters. */
  count: Scalars['Int']['output'];
  /** Metadata for whole search result used by front end */
  metadata?: Maybe<PathSearchResponseMetadata>;
  /** Found learning for search request. */
  paths: Array<ProLearningPath>;
};

/** All partner's content collection items that are available, currently just course */
export type LearningProduct = {
  /** ID of the learning product */
  id: Scalars['ID']['output'];
};

/** An event happening to a learning product in a moment in time */
export type LearningProductEvent = {
  __typename?: 'LearningProductEvent';
  /** The moment in time which the event happened */
  dateTime: Scalars['DateTime']['output'];
  /** The type of event on the learning product */
  eventType: LearningProductEventType;
  /** The learning product */
  product: LearningProduct;
};

/** The type of events that happens to a learning product and matters to the learning community service */
export enum LearningProductEventType {
  /** Completed working on/watching a learning product */
  Completed = 'COMPLETED',
  /** Enrolled in a course */
  Enrolled = 'ENROLLED',
  /** Started working on/watching a learning product (lecture, lab, etc.) */
  Started = 'STARTED'
}

/** Input for getting badge classes by learning products */
export type LearningProductInput = {
  /** ID of the learning product */
  id: Scalars['ID']['input'];
  /** Type of the learning product */
  type: LearningProductType;
  /** Version ID of the learning product */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

/** Type of learning product. */
export enum LearningProductType {
  /** Assessment */
  Assessment = 'ASSESSMENT',
  /** Course */
  Course = 'COURSE',
  /** Lab */
  Lab = 'LAB',
  /** Learning path */
  LearningPath = 'LEARNING_PATH'
}

/** A collection of learning products for a partner */
export type LearningProductsPaged = Paginated & {
  __typename?: 'LearningProductsPaged';
  /** The contents of the collection, currently just courses. Items may be less than requested size if objects become unavailable */
  items: Array<LearningProduct>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page size) */
  pageCount: Scalars['Int']['output'];
};

/** The LearningReminder object. */
export type LearningReminder = {
  __typename?: 'LearningReminder';
  /** Calendar Type for the learning reminder */
  calendarType?: Maybe<LearningReminderCalendarType>;
  /** Duration of the learning event. */
  durationInMinutes?: Maybe<Scalars['DurationInMinutes']['output']>;
  /** End date of the recurring learning reminders. */
  endDate: Scalars['DateTime']['output'];
  /** ID of the learning reminder. */
  id: Scalars['ID']['output'];
  /** Learning product of the learning reminder. */
  learningProduct?: Maybe<LearningProduct>;
  /** The recurrence rule for the reminder */
  recurrencePattern?: Maybe<Scalars['RRuleString']['output']>;
  /** Delivery method for the learning reminder */
  reminderMethod: LearningReminderNotificationMethod;
  /** When to show the reminder, expressed as minutes before the learning reminder start time */
  reminderMinutesBefore: Scalars['Int']['output'];
  /** Start date of the first recurring learning reminder. */
  startDate: Scalars['DateTime']['output'];
  /** Title of the learning reminder. */
  title: Scalars['String']['output'];
};

/** Calendar type of the learning reminder */
export enum LearningReminderCalendarType {
  /** Apple Calendar Type */
  Apple = 'APPLE',
  /** Google Calendar Type */
  Google = 'GOOGLE',
  /** Other Calendars */
  Other = 'OTHER',
  /** Outlook Calendar Type */
  Outlook = 'OUTLOOK'
}

/** Response for creating or updating a learning reminder */
export type LearningReminderCreateOrUpdateResponse = {
  __typename?: 'LearningReminderCreateOrUpdateResponse';
  /** The learning reminder that was created */
  learningReminder?: Maybe<LearningReminder>;
};

/** Response for deleting a learning reminder */
export type LearningReminderDeleteResponse = {
  __typename?: 'LearningReminderDeleteResponse';
  /** The ID of the learning reminder that was deleted */
  learningReminderId?: Maybe<Scalars['ID']['output']>;
};

/** Input for creating and updating a learning reminder */
export type LearningReminderInput = {
  /** Calendar Type for the learning reminder */
  calendarType?: InputMaybe<LearningReminderCalendarType>;
  /** Duration of the learning event. */
  durationInMinutes?: InputMaybe<Scalars['DurationInMinutes']['input']>;
  /** End date of the recurring learning reminders. */
  endDate: Scalars['DateTime']['input'];
  /** Learning product of the learning reminder. */
  learningProduct?: InputMaybe<LearningReminderLearningProductInput>;
  /** The recurrence rule for the reminder */
  recurrencePattern?: InputMaybe<Scalars['RRuleString']['input']>;
  /** Delivery method for the learning reminder */
  reminderMethod: LearningReminderNotificationMethod;
  /** When to show the reminder, expressed as minutes before the learning reminder start time */
  reminderMinutesBefore: Scalars['Int']['input'];
  /** Start date of the first recurring learning reminder. */
  startDate: Scalars['DateTime']['input'];
  /** Title of the learning reminder. */
  title: Scalars['String']['input'];
};

/** Input for learning product of the learning reminder */
export type LearningReminderLearningProductInput = {
  /** ID of the learning product. */
  id: Scalars['ID']['input'];
  /** ID of the learning product. */
  type: Scalars['String']['input'];
};

/** Notification method of the learning reminder */
export enum LearningReminderNotificationMethod {
  /** Email Reminder Method */
  Email = 'EMAIL',
  /** Push Reminder Method */
  Push = 'PUSH'
}

/** Paginated learning reminders of the user */
export type LearningRemindersPaged = Paginated & {
  __typename?: 'LearningRemindersPaged';
  /** Learning reminders of the user */
  items: Array<LearningReminder>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page size) */
  pageCount: Scalars['Int']['output'];
};

/** The testimonial given relevant to a learning item or event */
export type LearningTestimonial = {
  __typename?: 'LearningTestimonial';
  /** The localized content of the Learning Testimonial */
  content: Scalars['String']['output'];
  /** The ID of the Learning Testimonial */
  id: Scalars['ID']['output'];
  /** The URL related to the Learning Testimonial */
  relatedUrl: Scalars['URL']['output'];
  /** The localized text for the URL related to the Learning Testimonial */
  relatedUrlText: Scalars['String']['output'];
  /** The source of the Learning Testimonial */
  source: LearningTestimonialSource;
  /** The type of Learning Testimonial */
  type: LearningTestimonialType;
};

/** The Learning Testimonials query filter options */
export type LearningTestimonialFilters = {
  /** The URL query param that will be parsed to enable returning the content that has been saved but not yet published in the CMS. */
  isContentPreviewEnabled?: Scalars['Boolean']['input'];
  /** The specific page type for Learning Testimonials that will match values set in the CMS */
  pageType: LearningTestimonialPageType;
};

/** The specific page type for the Learning Testimonial. This is important for narrowing down the testimonial content in the CMS to where Marketing wants to render it. (Other pages will be added in the future, e.g., Personal Plan, etc) */
export enum LearningTestimonialPageType {
  /** Logged out home page of udemy */
  LoggedOutHomePage = 'LOGGED_OUT_HOME_PAGE'
}

/** The source of the learning testimonial */
export type LearningTestimonialSource = {
  __typename?: 'LearningTestimonialSource';
  /** Description text of the source for the Learning Testimonial (e.g., job title for Course Review types, etc) */
  description?: Maybe<Scalars['String']['output']>;
  /** The URL to the image asset of the source for the Learning Testimonial */
  image: Scalars['URL']['output'];
  /** Name of the source for the Learning Testimonial */
  name: Scalars['String']['output'];
};

/** Type of Learning Testimonial */
export enum LearningTestimonialType {
  /** Case study Learning Testimonial */
  CaseStudy = 'CASE_STUDY',
  /** Course review Learning Testimonial */
  CourseReview = 'COURSE_REVIEW',
  /** Third party Learning Testimonial */
  ThirdParty = 'THIRD_PARTY'
}

/** Represents a lecture type  */
export type Lecture = {
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** Lecture images by varying dimensions */
export type LectureImages = {
  __typename?: 'LectureImages';
  /** Lecture preview image with 50 pixels height */
  height50?: Maybe<Scalars['String']['output']>;
  /** Lecture preview image with 75 pixels height */
  height75?: Maybe<Scalars['String']['output']>;
  /** Lecture preview image with 100 pixels height */
  height100?: Maybe<Scalars['String']['output']>;
  /** Lecture preview image with 320 pixels height */
  height320?: Maybe<Scalars['String']['output']>;
  /** Lecture preview image with 480 pixels height */
  height480?: Maybe<Scalars['String']['output']>;
};

/** List of LectureResults and additional data about search response */
export type LectureSearchResponse = {
  __typename?: 'LectureSearchResponse';
  /** For the request, shows experiment metadata result like bucking info, experiment IDs of assigned feature variants. */
  experimentResults: Array<FeatureVariantAssignment>;
  /** Metadata for whole search result used by front end */
  metadata?: Maybe<LectureSearchResponseMetadata>;
  /** List of LectureResult objects. The main search result is found in this list. This result is made nullable as a catch point for any errors that may occur downstream. */
  results: Array<Maybe<LectureSearchResult>>;
};

/** Contains info for front end related stuff and tracking info. This data is calculated by backend service and used by Udemy front end. If you are an external user, you shouldn't need this. */
export type LectureSearchResponseMetadata = {
  __typename?: 'LectureSearchResponseMetadata';
  /** Order of the lab unit at the search result page. The value is calculated according to query intent evaluation */
  labUnitOrder: Scalars['Int']['output'];
  /** Experiment variant for the lecture unit  */
  lectureExperimentVariant: Scalars['String']['output'];
  /** Order of the lecture unit at the search result page. The value is calculated according to query intent evaluation */
  lectureUnitOrder: Scalars['Int']['output'];
  /** Search analytics tracking id; for uniquely identifying this query and result set; for this request */
  trackingId: Scalars['String']['output'];
};

/** Identifies each result for lecture search. */
export type LectureSearchResult = {
  __typename?: 'LectureSearchResult';
  /** Containing course of the found lecture. */
  course: Course;
  /** Found lecture for lecture search request. */
  lecture: Lecture;
  /** Unique analytics ID for the found lecture. */
  trackingId?: Maybe<Scalars['String']['output']>;
};

/** License pool */
export type LicensePool = {
  __typename?: 'LicensePool';
  /** The License pool unique identifier */
  id: Scalars['ID']['output'];
  /** Users with invite permissions to this license pool */
  inviters?: Maybe<Array<User>>;
  /** True if it is a default pool */
  isDefault: Scalars['Boolean']['output'];
  /** Set of license counts assigned to this license pool */
  licenseCounts: Array<LicensePoolProductTypeCount>;
  /** Pool name */
  name: Scalars['String']['output'];
  /** The organization this pool is assigned to */
  organization: Organization;
};

/** Response for a license pool create or update mutation */
export type LicensePoolCreateOrUpdateResponse = {
  __typename?: 'LicensePoolCreateOrUpdateResponse';
  /** License pool */
  licensePool?: Maybe<LicensePool>;
};

/** Response for a license pool delete mutation */
export type LicensePoolDeleteResponse = {
  __typename?: 'LicensePoolDeleteResponse';
  /** Id of the deleted license pool */
  licensePoolId?: Maybe<Scalars['ID']['output']>;
};

/** The input used for creating and updating a license pool */
export type LicensePoolInput = {
  /** Set of license counts assigned to this license pool */
  licenseCounts?: InputMaybe<Array<LicensePoolProductTypeCountInput>>;
  /** License pool name */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Product type license count */
export type LicensePoolProductTypeCount = {
  __typename?: 'LicensePoolProductTypeCount';
  /** The current max license count */
  maxLicenseCount: Scalars['Int']['output'];
  /** The product type this license count is assigned to */
  productType: SubscriptionPlanProductType;
  /** The current used license count for this license count */
  usedLicenseCount: Scalars['Int']['output'];
};

/** The input used for describing a product type license count */
export type LicensePoolProductTypeCountInput = {
  /** The current max license count */
  maxLicenseCount: Scalars['Int']['input'];
  /** The product type this license count is assigned to */
  productType: SubscriptionPlanProductType;
};

/** Response for License Pool users move mutation */
export type LicensePoolUsersMoveResponse = {
  __typename?: 'LicensePoolUsersMoveResponse';
  /** License Pools users moved per SubscriptionPlanProductType of the pool */
  usersMovedPerType?: Maybe<Array<LicensePoolUsersPerType>>;
};

/** License Pools users per SubscriptionPlanProductType of the pool */
export type LicensePoolUsersPerType = {
  __typename?: 'LicensePoolUsersPerType';
  /** The SubscriptionPlanProductType these moved users are assigned to */
  productType: SubscriptionPlanProductType;
  /** Number of users */
  userCount: Scalars['NonNegativeInt']['output'];
};

/** Money */
export type Money = {
  __typename?: 'Money';
  /** Amount */
  amount?: Maybe<Scalars['Decimal']['output']>;
  /** Currency */
  currency?: Maybe<CurrencyCode>;
};

/** Price option for computed price plan. Will be returned for monthly plans */
export type MonthlySubscriptionPlanPricingOption = {
  __typename?: 'MonthlySubscriptionPlanPricingOption';
  /** Contains information about the discount context for a given subscription plan price option */
  discount?: Maybe<SubscriptionPlanDiscount>;
  /** Field containing details about the discounted trial subscription offer for a given user. Null indicates no trial is available */
  discountTrial?: Maybe<SubscriptionTrial>;
  /** ID of the price option: */
  id: Scalars['ID']['output'];
  /** Contains information about the license context for a given subscription plan price option */
  licenseContext?: Maybe<SubscriptionPlanLicenseContext>;
  /** The list price of the subscription price plan based on provided requested count from request */
  listPrice: Money;
  /** Field showing the prices for the option. */
  price?: Maybe<SubscriptionPlanPrice>;
  /** Interval for renewing the subscription plan ie the length of the subscription plan */
  renewalInterval: DateInterval;
  /** Field containing details about the trial subscription offer for a given user. Null indicates no trial is available */
  trial?: Maybe<SubscriptionTrial>;
};

/** Root mutation from which every mutation schema extends */
export type Mutation = {
  __typename?: 'Mutation';
  /** Create an API client for an organization or partner */
  apiClientCreate?: Maybe<ApiClientCreateResponse>;
  /** Delete an API client for an organization or partner, returns the deleted API Client ID */
  apiClientDelete?: Maybe<ApiClientDeleteResponse>;
  /** Delete assertion of current user with given id */
  badgeAssertionDelete?: Maybe<BadgeAssertionDeleteResponse>;
  /** Storing the badge assertion URL and corresponding image path, retrieved from the Udemy uploader, for the current user. */
  badgeAssertionStoreByUrl?: Maybe<BadgeAssertionCreateOrUpdateResponse>;
  /** Update a badge class topic (only available for applications with admin:application scope) */
  badgeClassUpdateTopic?: Maybe<BadgeClassUpdateResponse>;
  /** Update a badge class visibilityStatus (only available for applications with admin:application scope) */
  badgeClassUpdateVisibilityStatus?: Maybe<BadgeClassUpdateResponse>;
  /** A mutation to add a cohort path library item. */
  cohortPathAddLibraryItem?: Maybe<CohortPathCreateUpdateResponse>;
  /** A mutation to create a cohort path. */
  cohortPathCreate?: Maybe<CohortPathCreateUpdateResponse>;
  /** A mutation to remove a cohort path library item. */
  cohortPathRemoveLibraryItem?: Maybe<CohortPathCreateUpdateResponse>;
  /** A mutation to update name and description of cohort path. */
  cohortPathUpdateMetadata?: Maybe<CohortPathCreateUpdateResponse>;
  /** Update published state of cohort path. */
  cohortPathUpdatePublished?: Maybe<CohortPathCreateUpdateResponse>;
  /** A mutation to update cohort path sections. */
  cohortPathUpdateSections?: Maybe<CohortPathCreateUpdateResponse>;
  /** Creates a snapshot of a course's head version with an optional comment */
  courseSnapshotCreate?: Maybe<CourseSnapshotCreateResponse>;
  /** Enrolls current user to learning product */
  enrollmentCreate?: Maybe<EnrollmentCreateResponse>;
  /** Create a feedback */
  feedbackCreate?: Maybe<FeedbackCreateResponse>;
  /** Initiate a chat session */
  learningAssistantChatInitiate?: Maybe<LearningAssistantChatInitiateResponse>;
  /** Initiate a chat session with course id */
  learningAssistantChatInitiateWithCourse?: Maybe<LearningAssistantChatInitiateResponse>;
  /** Send a chat message */
  learningAssistantChatMessageTextSend?: Maybe<LearningAssistantChatMessageSendResponse>;
  /** Add a user's reaction to an activity in the learning community activity feed. */
  learningCommunityActivityUserReactionAdd?: Maybe<LearningCommunityActivityUpdateResponse>;
  /** Remove a user's reaction from an activity in the learning community activity feed. */
  learningCommunityActivityUserReactionRemove?: Maybe<LearningCommunityActivityUpdateResponse>;
  /** Add members to a Learning Community */
  learningCommunityAddMembers?: Maybe<LearningCommunityCreateOrUpdateResponse>;
  /** Create a new Learning Community. */
  learningCommunityCreate?: Maybe<LearningCommunityCreateOrUpdateResponse>;
  /** Delete an existing Learning Community by ID. */
  learningCommunityDelete?: Maybe<LearningCommunityDeleteResponse>;
  /** Leave the learning community */
  learningCommunityLeave?: Maybe<LearningCommunityCreateOrUpdateResponse>;
  /** Create a post on the learning community activity feed  */
  learningCommunityPostCreate?: Maybe<LearningCommunityPostCreateOrUpdateResponse>;
  /** Delete a post from the learning community activity feed. */
  learningCommunityPostDelete?: Maybe<LearningCommunityPostCreateOrUpdateResponse>;
  /** Update the content for a post on the learning community activity feed. */
  learningCommunityPostUpdate?: Maybe<LearningCommunityPostCreateOrUpdateResponse>;
  /** Remove members from a Learning Community */
  learningCommunityRemoveMembers?: Maybe<LearningCommunityCreateOrUpdateResponse>;
  /** Create a learning path from skills framework */
  learningPathCreateFromSkillsFramework?: Maybe<LearningPathCreateResponse>;
  /** Create a learning reminder */
  learningReminderCreate?: Maybe<LearningReminderCreateOrUpdateResponse>;
  /** Delete the learning reminder */
  learningReminderDelete?: Maybe<LearningReminderDeleteResponse>;
  /** Update the learning reminder */
  learningReminderUpdate?: Maybe<LearningReminderCreateOrUpdateResponse>;
  /** Adds inviter to the given license pool */
  licensePoolAddInviter?: Maybe<LicensePoolCreateOrUpdateResponse>;
  /** Create a license pool entity */
  licensePoolCreate?: Maybe<LicensePoolCreateOrUpdateResponse>;
  /** Delete a license pool entity */
  licensePoolDelete?: Maybe<LicensePoolDeleteResponse>;
  /** Remove inviter from the given license pool */
  licensePoolRemoveInviter?: Maybe<LicensePoolCreateOrUpdateResponse>;
  /** Update a license pool entity */
  licensePoolUpdate?: Maybe<LicensePoolCreateOrUpdateResponse>;
  /** Moves users from their respective license pools to a target license pool */
  licensePoolUsersMove?: Maybe<LicensePoolUsersMoveResponse>;
  /** Assign existing occupation to the user */
  occupationAssign?: Maybe<UserOccupationInfoUpdateResponse>;
  /** Assign existing occupation group to the user */
  occupationGroupAssign?: Maybe<UserOccupationInfoUpdateResponse>;
  /** Assign new occupation string to the user */
  occupationRawAssign?: Maybe<UserOccupationInfoUpdateResponse>;
  /** Assign management status to the user. */
  occupationUpdateIsManager?: Maybe<UserOccupationInfoUpdateResponse>;
  /** Updates given notification preferences of organization */
  organizationNotificationPreferencesUpdate?: Maybe<OrganizationNotificationPreferencesUpdateResponse>;
  /** Create a new reflectionAnswer. */
  reflectionAnswerCreate?: Maybe<ReflectionAnswerCreateResponse>;
  /** Update the response for a reflectionAnswer. */
  reflectionAnswerUpdateAnswer?: Maybe<ReflectionAnswerUpdateResponse>;
  /** Activate a skills framework */
  skillsFrameworkActivateById?: Maybe<SkillsFrameworkUpdateResponse>;
  /** Map content to skills framework */
  skillsFrameworkMapContent?: Maybe<SkillsFrameworkMapContentResponse>;
  /** Publish skills framework to make it immutable */
  skillsFrameworkPublishById?: Maybe<SkillsFrameworkUpdateResponse>;
  /** Save a skills framework */
  skillsFrameworkUpdate?: Maybe<SkillsFrameworkUpdateResponse>;
  /**
   * This mutation is used when a user wants to cancel a subscription.
   *
   * Preconditions:
   * - The status of the subscription enrollment is either ACTIVE or TRIAL.
   * - The product type of the subscription enrollment is either 'Consumer Subscription' or 'Team Plan'.
   * - The user must be authorized on that subscription. Being authorized means either:
   * - Product type is 'Consumer Subscription' and the user is the subscriber of the subscription enrollment.
   * - Product type is 'Team Plan' and the user is an admin for the subscriber organization.
   *
   * If the criteria above are met, the subscription will be canceled and the new SubscriptionEnrollment data will be
   * returned. As a result of the cancellation:
   * - There won't be any future charges. There will not be any refund for earlier charges.
   * - The user will see the subscription status as CANCELED immediately. However, the content will remain accessible
   * until the end of the existing billing period.
   * - The user may revert this cancellation until the end of the current billing period. (See the mutation named
   * 'subscriptionEnrollmentReactivate')
   * - At the end of the current billing period, the status will become EXPIRED and the content will become inaccessible.
   */
  subscriptionEnrollmentCancel?: Maybe<SubscriptionEnrollmentUpdateResponse>;
  /**
   * This mutation is used when a user wants to revert a previous cancellation operation done by the mutation named
   * 'subscriptionEnrollmentCancel'.
   *
   * Preconditions:
   * - The status of the subscription enrollment is CANCELED.
   * - The product type of the subscription enrollment is either 'Consumer Subscription' or 'Team Plan'.
   * - The user must be authorized on that subscription. Being authorized means either:
   * - Product type is 'Consumer Subscription' and the user is the subscriber of the subscription enrollment.
   * - Product type is 'Team Plan' and the user is an admin for the subscriber organization.
   *
   * If the criteria above are met, the previous cancellation will be reverted and the new SubscriptionEnrollment data
   * will be returned. As a result of the reactivation:
   * - Future charges will be scheduled again according to the initial plan.
   * - The user will be able to see the new subscription status immediately. The new status will be:
   * - TRIAL, unless the trial period has ended
   * - ACTIVE, if the trial period has ended
   */
  subscriptionEnrollmentReactivate?: Maybe<SubscriptionEnrollmentUpdateResponse>;
  /** Override subscription feature values for the given organization or user (only available for applications with admin:application scope) */
  subscriptionFeaturesSetOverrides?: Maybe<SubscriptionFeaturesSetOverrideResponse>;
  /** Assign a user's topic interest */
  topicInterestAssign?: Maybe<TopicInterestUpdateResponse>;
  /** Un-assign a user's topic interest */
  topicInterestUnassign?: Maybe<TopicInterestUpdateResponse>;
  /** Updates users notification preferences */
  userNotificationPreferencesUpdate?: Maybe<UserNotificationPreferencesUpdateResponse>;
};


/** Root mutation from which every mutation schema extends */
export type MutationApiClientCreateArgs = {
  apiClient: ApiClientInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationApiClientDeleteArgs = {
  clientId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationBadgeAssertionDeleteArgs = {
  id: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationBadgeAssertionStoreByUrlArgs = {
  badgeAssertionImagePath?: InputMaybe<Scalars['String']['input']>;
  externalUrl: Scalars['URL']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationBadgeClassUpdateTopicArgs = {
  input: BadgeClassUpdateTopicInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationBadgeClassUpdateVisibilityStatusArgs = {
  input: BadgeClassUpdateVisibilityStatusInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationCohortPathAddLibraryItemArgs = {
  cohortPathId: Scalars['UUID']['input'];
  courseId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationCohortPathCreateArgs = {
  cohortPath: CohortPathCreateUpdateInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationCohortPathRemoveLibraryItemArgs = {
  cohortPathId: Scalars['UUID']['input'];
  courseId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationCohortPathUpdateMetadataArgs = {
  cohortPath: CohortPathCreateUpdateInput;
  cohortPathId: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationCohortPathUpdatePublishedArgs = {
  cohortPathId: Scalars['UUID']['input'];
  isPublished: Scalars['Boolean']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationCohortPathUpdateSectionsArgs = {
  cohortPathId: Scalars['UUID']['input'];
  sectionsInput: CohortPathSectionsUpdateInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationCourseSnapshotCreateArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationEnrollmentCreateArgs = {
  learningProduct: EnrollmentsLearningProductInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationFeedbackCreateArgs = {
  feedbackCreateInput: FeedbackCreateInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningAssistantChatInitiateArgs = {
  name: LearningAssistantChatName;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningAssistantChatInitiateWithCourseArgs = {
  courseId: Scalars['ID']['input'];
  name: LearningAssistantChatName;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningAssistantChatMessageTextSendArgs = {
  chatId: Scalars['ID']['input'];
  message: LearningAssistantChatTextMessageInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityActivityUserReactionAddArgs = {
  activityId: Scalars['UUID']['input'];
  learningCommunityId: Scalars['UUID']['input'];
  reaction: LearningCommunityActivityUserReactionInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityActivityUserReactionRemoveArgs = {
  activityId: Scalars['UUID']['input'];
  learningCommunityId: Scalars['UUID']['input'];
  reaction: LearningCommunityActivityUserReactionInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityAddMembersArgs = {
  id: Scalars['UUID']['input'];
  invitations: LearningCommunityInviteInput;
  userIds: Array<Scalars['ID']['input']>;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityCreateArgs = {
  learningCommunity: LearningCommunityInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityDeleteArgs = {
  id: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityLeaveArgs = {
  id: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityPostCreateArgs = {
  entry: DiscussionEntryInput;
  learningCommunityId: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityPostDeleteArgs = {
  learningCommunityId: Scalars['UUID']['input'];
  postId: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityPostUpdateArgs = {
  entry: DiscussionEntryInput;
  learningCommunityId: Scalars['UUID']['input'];
  postId: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningCommunityRemoveMembersArgs = {
  id: Scalars['UUID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningPathCreateFromSkillsFrameworkArgs = {
  skillsFrameworkIdInput: SkillsFrameworkIdInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningReminderCreateArgs = {
  learningReminder: LearningReminderInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningReminderDeleteArgs = {
  learningReminderId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLearningReminderUpdateArgs = {
  learningReminder: LearningReminderInput;
  learningReminderId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLicensePoolAddInviterArgs = {
  licensePoolId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLicensePoolCreateArgs = {
  licensePool: LicensePoolInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationLicensePoolDeleteArgs = {
  licensePoolId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLicensePoolRemoveInviterArgs = {
  licensePoolId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLicensePoolUpdateArgs = {
  licensePool: LicensePoolInput;
  licensePoolId: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationLicensePoolUsersMoveArgs = {
  targetLicensePoolId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


/** Root mutation from which every mutation schema extends */
export type MutationOccupationAssignArgs = {
  occupationAssignment?: InputMaybe<OccupationAssignmentInput>;
};


/** Root mutation from which every mutation schema extends */
export type MutationOccupationGroupAssignArgs = {
  occupationGroupAssignment?: InputMaybe<OccupationGroupAssignmentInput>;
};


/** Root mutation from which every mutation schema extends */
export type MutationOccupationRawAssignArgs = {
  occupationAssignment?: InputMaybe<OccupationRawAssignmentInput>;
};


/** Root mutation from which every mutation schema extends */
export type MutationOccupationUpdateIsManagerArgs = {
  isManager: Scalars['Boolean']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationOrganizationNotificationPreferencesUpdateArgs = {
  organizationPreferences: OrganizationNotificationPreferenceSetInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationReflectionAnswerCreateArgs = {
  reflectionAnswer: ReflectionPromptAnswerInput;
  reflectionPromptId: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationReflectionAnswerUpdateAnswerArgs = {
  reflectionAnswer: ReflectionPromptAnswerInput;
  reflectionPromptId: Scalars['UUID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationSkillsFrameworkActivateByIdArgs = {
  skillsFrameworkIdInput: SkillsFrameworkIdInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationSkillsFrameworkMapContentArgs = {
  skillsFrameworkIdInput: SkillsFrameworkIdInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationSkillsFrameworkPublishByIdArgs = {
  skillsFrameworkIdInput: SkillsFrameworkIdInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationSkillsFrameworkUpdateArgs = {
  entityTag: Scalars['Int']['input'];
  skillsFramework: SkillsFrameworkInput;
  skillsFrameworkIdInput: SkillsFrameworkIdInput;
};


/** Root mutation from which every mutation schema extends */
export type MutationSubscriptionEnrollmentCancelArgs = {
  id: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationSubscriptionEnrollmentReactivateArgs = {
  id: Scalars['ID']['input'];
};


/** Root mutation from which every mutation schema extends */
export type MutationSubscriptionFeaturesSetOverridesArgs = {
  overrides: Array<SubscriptionFeatureOverrideInput>;
};


/** Root mutation from which every mutation schema extends */
export type MutationTopicInterestAssignArgs = {
  topicIds: Array<Scalars['ID']['input']>;
};


/** Root mutation from which every mutation schema extends */
export type MutationTopicInterestUnassignArgs = {
  topicIds: Array<Scalars['ID']['input']>;
};


/** Root mutation from which every mutation schema extends */
export type MutationUserNotificationPreferencesUpdateArgs = {
  userNotificationPreferencesInput: UserNotificationPreferenceSetInput;
};

/** Details of Occupation */
export type Occupation = {
  __typename?: 'Occupation';
  /** ID of the occupation instance */
  id: Scalars['ID']['output'];
  /** Name of the occupation */
  name: Scalars['String']['output'];
  /** Representative Topic */
  representativeTopic?: Maybe<Topic>;
  /** URL of the landing page, can be null if no landing page such as OLP exists */
  urlLandingPage?: Maybe<Scalars['URL']['output']>;
};

/** User is assigning occupation to themself */
export type OccupationAssignmentInput = {
  /** ID of the occupation */
  occupationId: Scalars['ID']['input'];
};

/** Occupation group type */
export type OccupationGroup = {
  __typename?: 'OccupationGroup';
  /** ID of the occupation group */
  id: Scalars['ID']['output'];
  /** Name of the occupation group */
  name: Scalars['String']['output'];
};

/** Current user assigning existing occupation group. */
export type OccupationGroupAssignmentInput = {
  /** ID of the occupation group */
  occupationGroupId: Scalars['ID']['input'];
};

/** Shows page search results of occupation */
export type OccupationPaged = Paginated & {
  __typename?: 'OccupationPaged';
  /** Occupation groups. Items may be less than requested size if objects become unavailable */
  items: Array<Occupation>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page size) */
  pageCount: Scalars['Int']['output'];
};

/** Current user assigning user provided occupation. */
export type OccupationRawAssignmentInput = {
  /** Raw user provided string to represent the current user's occupation. */
  userProvidedOccupation: Scalars['String']['input'];
};

/** Filters for occupation search */
export type OccupationSearchFilters = {
  /** Include occupations with the following group ids */
  groupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** A UB organization */
export type Organization = {
  __typename?: 'Organization';
  /** The UB organization's unique identifier */
  id: Scalars['ID']['output'];
};

/** Represents the each organization notification preference */
export type OrganizationNotificationPreferenceInput = {
  /** True if notification type is enabled */
  isEnabled: Scalars['Boolean']['input'];
  /** The role-based setting of notification */
  notificationRoleType: OrganizationNotificationRoleType;
};

/** Represents the notification preferences of an organization */
export type OrganizationNotificationPreferenceSetInput = {
  /** List of role-based notification preferences belongs to organization */
  roleBasedPreferences: Array<OrganizationNotificationPreferenceInput>;
  /** Source of the action to store audit information */
  source: Scalars['String']['input'];
};

/** Represents the organization notification save response */
export type OrganizationNotificationPreferencesUpdateResponse = {
  __typename?: 'OrganizationNotificationPreferencesUpdateResponse';
  /** The updated notification preferences if mutation is successful */
  roleBasedPreferences?: Maybe<Array<OrganizationRoleBasedNotificationPreference>>;
};

/** Role-based organization notifications to group organization notifications by role */
export enum OrganizationNotificationRoleType {
  /** Represents every member of a particular organization */
  Everyone = 'EVERYONE',
  /** Represents group admins of a particular organization */
  GroupAdmins = 'GROUP_ADMINS',
  /** Represents learners of a particular organization */
  Learners = 'LEARNERS'
}

/** A notification preference belongs to organization */
export type OrganizationRoleBasedNotificationPreference = {
  __typename?: 'OrganizationRoleBasedNotificationPreference';
  /** True if notification type is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** The role-based setting of notification */
  notificationRoleType: OrganizationNotificationRoleType;
};

/** Object that describes the type and status of license assigned to a user in an organization */
export type OrganizationUserLicense = {
  __typename?: 'OrganizationUserLicense';
  /** The organization this license is assigned to */
  organization: Organization;
  /** The status of license assigned to the user */
  status: OrganizationUserLicenseStatus;
  /** The type of license assigned to the user */
  type: OrganizationUserLicenseType;
  /** The User this license is assigned to */
  user: User;
};

/** Object that describes the status of license assigned to a user in an organization */
export enum OrganizationUserLicenseStatus {
  /** license is usable and assigned to the specified user */
  Active = 'ACTIVE',
  /** license is not usable */
  Inactive = 'INACTIVE'
}

/** Object that describes the type of license assigned to a user in an organization */
export enum OrganizationUserLicenseType {
  /** Default Enterprise and Team Plan license Type */
  Basic = 'BASIC',
  /** UB Pro license Type */
  Pro = 'PRO'
}

/** Filters for the organizationUserLicenses query */
export type OrganizationUserLicensesFilters = {
  /** Filter by license status */
  status?: InputMaybe<OrganizationUserLicenseStatus>;
  /** Filter by license type */
  type?: InputMaybe<OrganizationUserLicenseType>;
};

/** Interface for implementing paginated results */
export type Paginated = {
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page size) */
  pageCount: Scalars['Int']['output'];
};

/** A Partner */
export type Partner = {
  __typename?: 'Partner';
  /** The partner's unique identifier */
  id: Scalars['ID']['output'];
};

/** Contains info for front end related stuff and tracking info. This data is calculated by backend service and used by Udemy front end. If you are an external user, you shouldn't need this. */
export type PathSearchResponseMetadata = {
  __typename?: 'PathSearchResponseMetadata';
  /** For the request, shows experiment metadata result like bucking info, experiment IDs of assigned feature variants. */
  experimentResults: Array<FeatureVariantAssignment>;
  /** Search analytics tracking id; for uniquely identifying this query and result set; for this request */
  trackingId: Scalars['String']['output'];
};

/** Enum for different payment method types */
export enum PaymentMethodType {
  /** Payment method CHEQUE */
  Cheque = 'CHEQUE',
  /** Payment method JPMORGAN */
  Jpmorgan = 'JPMORGAN',
  /** Payment method PAYONEER */
  Payoneer = 'PAYONEER',
  /** Payment method PAYPAL */
  Paypal = 'PAYPAL',
  /** Payment method WIRE */
  Wire = 'WIRE'
}

/** Enum for different payout statuses */
export enum PayoutStatusType {
  /** Payout status BLOCKED */
  Blocked = 'BLOCKED',
  /** Payout status CANCELLED */
  Cancelled = 'CANCELLED',
  /** Payout status FAILED */
  Failed = 'FAILED',
  /** Payout status INITIAL */
  Initial = 'INITIAL',
  /** Payout status PENDING */
  Pending = 'PENDING',
  /** Payout status PROCESSING */
  Processing = 'PROCESSING',
  /** Payout status QUEUING */
  Queuing = 'QUEUING',
  /** Payout status READY_TO_PAY */
  ReadyToPay = 'READY_TO_PAY',
  /** Payout status SUCCESS */
  Success = 'SUCCESS',
  /** Payout status UNCLAIMED */
  Unclaimed = 'UNCLAIMED',
  /** Payout status UNPAID */
  Unpaid = 'UNPAID'
}

/** Popular topic */
export type PopularTopic = {
  __typename?: 'PopularTopic';
  /** The type of popularity associated with this topic */
  popularityType?: Maybe<TopicPopularityTypes>;
  /** Associated Subcategory */
  subcategory?: Maybe<PopularTopicSubCategory>;
  /** A topic */
  topic: Topic;
};

/** Topic SubCategory */
export type PopularTopicSubCategory = SubCategory & {
  __typename?: 'PopularTopicSubCategory';
  /** ID of the subcategory */
  id: Scalars['ID']['output'];
  /** Name of the subcategory */
  name: Scalars['String']['output'];
};

/** Assigment test with multiple-choice and free-from questions reviewed by instructors */
export type PracticeAssignment = CurriculumItem & {
  __typename?: 'PracticeAssignment';
  /** Description of the assignment */
  description: Scalars['String']['output'];
  /** The Id of the assignment */
  id: Scalars['ID']['output'];
  /** The title of the assignment */
  title: Scalars['String']['output'];
  /** Landing page to view this PracticeAssignment */
  urlLanding: Scalars['URL']['output'];
};

/** Practice test that is more detailed than a Quiz */
export type PracticeTest = CurriculumItem & {
  __typename?: 'PracticeTest';
  /** The Id of the practice test */
  id: Scalars['ID']['output'];
  /** Percentage required to pass (0 - 100) */
  minimumPassingScore: Scalars['Int']['output'];
  /** Whether the question and answer order is randomized */
  randomized: Scalars['Boolean']['output'];
  /** The title of the practice test */
  title: Scalars['String']['output'];
  /** Landing page to view this PracticeTest */
  urlLanding: Scalars['URL']['output'];
};

/** Presentation Lecture for Udemy Business */
export type PresentationLecture = CurriculumItem & Lecture & {
  __typename?: 'PresentationLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Total duration of the lecture's content in seconds */
  durationInSeconds: Scalars['DurationInSeconds']['output'];
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** The Pro learning path object. */
export type ProLearningPath = LearningPath & LearningProduct & {
  __typename?: 'ProLearningPath';
  /** Badges of the learning path. */
  badges?: Maybe<Array<BadgeClass>>;
  /** Description of the learning path. */
  description?: Maybe<Scalars['String']['output']>;
  /** ID of the learning path. */
  id: Scalars['ID']['output'];
  /** Is the user enrolled in this learning path or not */
  isEnrolled: Scalars['Boolean']['output'];
  /** Number of items in the learning path. */
  itemCount: Scalars['Int']['output'];
  /** Total No of enrollments for the learning path. */
  numberOfEnrollments: Scalars['Int']['output'];
  /** Owner user for the learning path */
  owner: LearningPathInstructor;
  /** Title of the learning path. */
  title: Scalars['String']['output'];
  /** Topics associated with the learning path. */
  topics?: Maybe<Array<Topic>>;
  /** Unique analytics ID for this instance of LearningPath returned from the server in this request. */
  trackingId?: Maybe<Scalars['String']['output']>;
  /** Landing page to view this LearningPath */
  urlLanding: Scalars['URL']['output'];
};

/** Root query from which every query schema extends */
export type Query = {
  __typename?: 'Query';
  /** Get API client by ID */
  apiClient: ApiClient;
  /** Get all API clients the requesting organization user or partner has access to */
  apiClients: Array<ApiClient>;
  /** Searches assessments by given query */
  assessmentSearch?: Maybe<AssessmentSearchResponse>;
  /** Get the latest attempts for all assessments in a collection */
  assessmentVariantAttemptsLatestByCollection: Array<AssessmentVariantAttempt>;
  /** Get an Open Badge Assertion by id */
  badgeAssertion?: Maybe<BadgeAssertion>;
  /** Get list of Open Badge Assertions by their ids */
  badgeAssertions: Array<Maybe<BadgeAssertion>>;
  /** Get list of Open Badge Assertions of given user. Must be a UB admin for the given User */
  badgeAssertionsByUser?: Maybe<BadgeAssertionPaged>;
  /** Get list of Open Badge Assertions for organization. User must be an admin for the given organization */
  badgeAssertionsForCurrentOrganization?: Maybe<BadgeAssertionPaged>;
  /** Get user's issued badges that are uploaded by the user */
  badgeAssertionsImported?: Maybe<BadgeAssertionPaged>;
  /** Get certification subject areas for badges */
  badgeCertificationSubjectAreas: Array<BadgeCertificationSubjectArea>;
  /** Get BadgeClass by id */
  badgeClass?: Maybe<BadgeClass>;
  /** Search for BadgeClasses with Admin filters (only available for applications with admin:application scope) */
  badgeClassAdminSearch?: Maybe<BadgeClassSearchResponse>;
  /** Get user's enrollments for a badge class */
  badgeClassEnrollmentsByBadgeClassId: Array<BadgeClassEnrollment>;
  /** Issuer list of all BadgeClasses */
  badgeClassIssuers: Array<BadgeClassIssuer>;
  /** Search for BadgeClasses */
  badgeClassSearch?: Maybe<BadgeClassSearchResponse>;
  /** Get user's active certification preparation */
  badgeClassesActive: Array<BadgeClass>;
  /** Get mapped badge classes for learning products */
  badgeClassesByLearningProducts?: Maybe<Array<BadgeClass>>;
  /** Get list of BadgeClasses under a topic */
  badgeClassesByTopic: Array<BadgeClass>;
  /** Get list of BadgeClasses under list of topics */
  badgeClassesByTopics: Array<BadgeClass>;
  /** Retrieve a set of coding exercise templates by their language */
  codingExerciseTemplatesByLanguage: Array<CodingExerciseTemplate>;
  /** A query to return the cohort path by id. */
  cohortPath?: Maybe<CohortPath>;
  /** A query to return all cohort paths paginated by page and size */
  cohortPathsPaged?: Maybe<CohortPathsPaged>;
  /** Retrieve a course by its ID */
  course?: Maybe<Course>;
  /**
   * Retrieves list of Assessments associated with course id that contain items a user attempted. Can filter by correct,
   * skipped or incorrect Items with default returning all 3. A course may have multiple Assessments associated with it.
   */
  courseAssessmentItemResults: Array<AssessmentResults>;
  /** Retrieve course catalog for the client */
  courseCatalogFull?: Maybe<CourseCatalogFullResponsePaged>;
  /** Retrieve changes to course catalog since last sync */
  courseCatalogUpdated?: Maybe<CourseCatalogUpdatedResponsePaged>;
  /** Retrieve a category by id */
  courseCategory?: Maybe<CourseCategory>;
  /** Search for courses */
  courseSearch?: Maybe<CourseSearchResponse>;
  /** Retrieve a set of courses by their IDs */
  courses: Array<Maybe<Course>>;
  /** Fetch the current subscription of user or subscription of user's organization if user is a member of an organization. Current subscription could be cancelled or expired. */
  currentSubscriptionEnrollment?: Maybe<SubscriptionEnrollment>;
  /** The query to get the dynamic web content for the component represented by the `contentId` filter */
  dynamicWebContent: DynamicWebContent;
  /** Returns assigned feature variants by configuration context code */
  featureVariantAssignmentsByConfigurationContextCode: Array<FeatureVariantAssignment>;
  /** Returns assigned feature variants */
  featureVariantAssignmentsByFeatureCodes: Array<FeatureVariantAssignment>;
  /** Return featured reviews for a given set of topics */
  featuredReviewsByTopics: Array<Review>;
  /** A query to return the account balance amount. */
  instructorAccountBalance?: Maybe<InstructorAccountBalance>;
  /** A query to return instructor's account issues. */
  instructorAccountIssues?: Maybe<InstructorAccountIssues>;
  /** A query to return a list of instructor's payouts. Page size is fixed to 15 */
  instructorPayoutsByPage?: Maybe<InstructorPayoutsPaged>;
  /** Retrieve a Lab by its ID */
  lab?: Maybe<Lab>;
  /** Search for labs */
  labSearch?: Maybe<LabSearchResponse>;
  /** Retrieve a set of Labs by their IDs */
  labs: Array<Maybe<Lab>>;
  /** Query to get learning activity of a user based on the filter. */
  learningActivityTrend?: Maybe<LearningActivityTrend>;
  /** List chat messages for a chat session since the given timestamp */
  learningAssistantChatMessages: LearningAssistantChatMessagesPaged;
  /** Retrieve a set of suggested prompts that users can ask the assistant */
  learningAssistantSuggestedPrompts: LearningAssistantSuggestedPromptsPaged;
  /**
   * List Learning Communities by IDs.
   * If no `ids` are provided then all the learning communities which the user is part of will be returned.
   */
  learningCommunities: Array<Maybe<LearningCommunity>>;
  /**
   * Fetch the activities for a learning community with the cursor.
   * The `limit` is used to limit the number of activities returned on the page.
   * The cursor can be used to continue fetching the next pages of activities. the cursor is part of previous results.
   */
  learningCommunityActivities?: Maybe<LearningCommunityActivitiesPaged>;
  /** Get learning objectives by skills framework id and learning objective ids */
  learningObjectivesByIdsAndSkills: Array<Maybe<LearningObjective>>;
  /** Get learning objectives by skills framework id and activities */
  learningObjectivesBySkillsAndActivities: Array<Maybe<LearningObjective>>;
  /** Searches paths by given query */
  learningPathSearch?: Maybe<LearningPathSearchResponse>;
  /** Returns available learning products for the client */
  learningProducts: LearningProductsPaged;
  /** Retrieve a Learning Reminder by its ID */
  learningReminder?: Maybe<LearningReminder>;
  /** List Learning Reminders for a user inferred from an authentication context */
  learningReminders: LearningRemindersPaged;
  /** Returns a list of Learning Testimonials based on the page type */
  learningTestimonials: Array<Maybe<LearningTestimonial>>;
  /** Searches lectures semantically for given query. To get a result, query must be in english and at least three words. The search team has been focused on leveraging semantic search to be able to recommend the right lectures for users. Instead of relying on just titles and descriptions, the team leverage caption data to understand content better and provide better results */
  lectureSearch?: Maybe<LectureSearchResponse>;
  /** Get license pools */
  licensePools: Array<LicensePool>;
  /** Returns a list of pools that the given user can invite others to */
  licensePoolsByInviter: Array<LicensePool>;
  /** Fetch occupation groups */
  occupationGroups: Array<Maybe<OccupationGroup>>;
  /** Search occupations */
  occupationSearch: OccupationPaged;
  /** Fetch occupations by ID */
  occupations: Array<Maybe<Occupation>>;
  /** Returns organization notification preferences */
  organizationNotificationPreferences: Array<OrganizationRoleBasedNotificationPreference>;
  /** Get the current licenses assigned to the User calling this Query in their org, filtered by type and if it is active or not */
  organizationUserLicenses?: Maybe<Array<OrganizationUserLicense>>;
  /** Gets a list of all popular topics */
  popularTopicsByCategoryId: Array<Maybe<PopularTopic>>;
  /** Get Pro learning paths by topic */
  proLearningPathsByTopic: Array<ProLearningPath>;
  /** Paginated list of ReflectionAnswers for User. Use search filters to provide specific list of responses. */
  reflectionAnswersPaginated: ReflectionAnswersPaginatedResponse;
  /** Retrieve a reflection by its UUID */
  reflectionPrompt?: Maybe<ReflectionPrompt>;
  /** Search for autocomplete */
  searchAutocomplete: Array<Maybe<SearchAutocompleteSuggestion>>;
  /** Get skills framework by learning path id and optional versionId */
  skillsFrameworkByGeneratedLearningPath?: Maybe<SkillsFramework>;
  /** Get Skills Framework by id and type with optional versionId */
  skillsFrameworkByIdAndType?: Maybe<SkillsFramework>;
  /** Get reference id status for mapping content to skills framework */
  skillsFrameworkMapContentTask?: Maybe<SkillsFrameworkMapContentTask>;
  /** Get current lecture consumption streak data for a request's user. */
  streakLectureConsumptionWeekly: StreakLectureConsumptionWeekly;
  /** Retrieve all subscription features with their availability for the given subscriber (only available for applications with admin:application scope) */
  subscriptionFeaturesAllWithAvailabilityBySubscriber: Array<SubscriptionFeature>;
  /** Retrieve availability of the given subscription features for the user */
  subscriptionFeaturesAvailabilityByKeys: Array<SubscriptionFeatureAvailability>;
  /** Returns available subscription plans for a given user */
  subscriptionPlans: Array<SubscriptionPlan>;
  /** Returns a topic by ID */
  topic?: Maybe<Topic>;
  /** Returns a list of topic groups by ID */
  topicGroups: Array<TopicGroup>;
  /** Fetch topics user has expressed interest in */
  topicInterests: Array<Topic>;
  /** Search topics */
  topicSearch: Array<Topic>;
  /** Returns user notification preferences */
  userNotificationPreferences: Array<UserNotificationPreference>;
  /** Returns the details of current user occupation */
  userOccupationInfo: UserOccupationInfo;
};


/** Root query from which every query schema extends */
export type QueryApiClientArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryAssessmentSearchArgs = {
  query: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QueryAssessmentVariantAttemptsLatestByCollectionArgs = {
  collectionId: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeAssertionArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeAssertionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


/** Root query from which every query schema extends */
export type QueryBadgeAssertionsByUserArgs = {
  page?: Scalars['NonNegativeInt']['input'];
  pageSize?: Scalars['MaxResultsPerPage']['input'];
  userId: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeAssertionsForCurrentOrganizationArgs = {
  filters?: InputMaybe<BadgeAssertionsSearchFilters>;
  page?: Scalars['NonNegativeInt']['input'];
  pageSize?: Scalars['MaxResultsPerPage']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeAssertionsImportedArgs = {
  page?: Scalars['NonNegativeInt']['input'];
  pageSize?: Scalars['MaxResultsPerPage']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeClassArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeClassAdminSearchArgs = {
  filters?: InputMaybe<BadgeClassSearchFilters>;
  page?: Scalars['NonNegativeInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  size?: Scalars['MaxResultsPerPage']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeClassEnrollmentsByBadgeClassIdArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeClassSearchArgs = {
  filters?: InputMaybe<BadgeClassSearchFilters>;
  page: Scalars['NonNegativeInt']['input'];
  pageSize?: Scalars['MaxResultsPerPage']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Root query from which every query schema extends */
export type QueryBadgeClassesByLearningProductsArgs = {
  learningProducts: Array<LearningProductInput>;
};


/** Root query from which every query schema extends */
export type QueryBadgeClassesByTopicArgs = {
  topicId: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryBadgeClassesByTopicsArgs = {
  topicIds: Array<Scalars['ID']['input']>;
};


/** Root query from which every query schema extends */
export type QueryCodingExerciseTemplatesByLanguageArgs = {
  filters?: InputMaybe<CodingExerciseTemplateFilters>;
  language: CodingExerciseLanguageOption;
};


/** Root query from which every query schema extends */
export type QueryCohortPathArgs = {
  id: Scalars['UUID']['input'];
};


/** Root query from which every query schema extends */
export type QueryCohortPathsPagedArgs = {
  page?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  pageSize?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
};


/** Root query from which every query schema extends */
export type QueryCourseArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryCourseAssessmentItemResultsArgs = {
  courseId: Scalars['ID']['input'];
  filters?: InputMaybe<AssessmentItemResultsFilters>;
};


/** Root query from which every query schema extends */
export type QueryCourseCatalogFullArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
};


/** Root query from which every query schema extends */
export type QueryCourseCatalogUpdatedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
  syncToken: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QueryCourseCategoryArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryCourseSearchArgs = {
  filters?: InputMaybe<CourseSearchFilters>;
  page?: Scalars['NonNegativeInt']['input'];
  pageSize?: Scalars['MaxResultsPerPage']['input'];
  query: Scalars['String']['input'];
  sortOrder?: InputMaybe<CourseSearchSortType>;
};


/** Root query from which every query schema extends */
export type QueryCoursesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


/** Root query from which every query schema extends */
export type QueryDynamicWebContentArgs = {
  filters: DynamicWebContentFilters;
};


/** Root query from which every query schema extends */
export type QueryFeatureVariantAssignmentsByConfigurationContextCodeArgs = {
  configurationContextCode: Scalars['String']['input'];
  filters?: InputMaybe<FeatureVariantAssignmentFilters>;
};


/** Root query from which every query schema extends */
export type QueryFeatureVariantAssignmentsByFeatureCodesArgs = {
  featureCodes: Array<Scalars['String']['input']>;
  filters?: InputMaybe<FeatureVariantAssignmentFilters>;
};


/** Root query from which every query schema extends */
export type QueryFeaturedReviewsByTopicsArgs = {
  topicIds: Array<Scalars['ID']['input']>;
};


/** Root query from which every query schema extends */
export type QueryInstructorPayoutsByPageArgs = {
  page: Scalars['Int']['input'];
};


/** Root query from which every query schema extends */
export type QueryLabArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryLabSearchArgs = {
  filters?: InputMaybe<Array<SearchAggregationInputOption>>;
  query: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QueryLabsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


/** Root query from which every query schema extends */
export type QueryLearningActivityTrendArgs = {
  filters?: InputMaybe<LearningActivityTrendFilters>;
};


/** Root query from which every query schema extends */
export type QueryLearningAssistantChatMessagesArgs = {
  chatId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Timestamp']['input']>;
  size?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
};


/** Root query from which every query schema extends */
export type QueryLearningAssistantSuggestedPromptsArgs = {
  name: LearningAssistantChatName;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSource: LearningAssistantSuggestedPromptPageSource;
  size?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
};


/** Root query from which every query schema extends */
export type QueryLearningCommunitiesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};


/** Root query from which every query schema extends */
export type QueryLearningCommunityActivitiesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  limit: Scalars['Int']['input'];
};


/** Root query from which every query schema extends */
export type QueryLearningObjectivesByIdsAndSkillsArgs = {
  ids: Array<Scalars['ID']['input']>;
  skillsFrameworkId: Scalars['ID']['input'];
  type: SkillsFrameworkGoalType;
};


/** Root query from which every query schema extends */
export type QueryLearningObjectivesBySkillsAndActivitiesArgs = {
  activities: Array<LearningObjectiveActivityInput>;
  skillsFrameworkId: Scalars['ID']['input'];
  type: SkillsFrameworkGoalType;
};


/** Root query from which every query schema extends */
export type QueryLearningPathSearchArgs = {
  filters?: InputMaybe<LearningPathSearchFilters>;
  query: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QueryLearningProductsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
};


/** Root query from which every query schema extends */
export type QueryLearningReminderArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryLearningRemindersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['MaxResultsPerPage']['input']>;
};


/** Root query from which every query schema extends */
export type QueryLearningTestimonialsArgs = {
  filters: LearningTestimonialFilters;
};


/** Root query from which every query schema extends */
export type QueryLectureSearchArgs = {
  query: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QueryLicensePoolsByInviterArgs = {
  userId: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryOccupationGroupsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Root query from which every query schema extends */
export type QueryOccupationSearchArgs = {
  filters?: InputMaybe<OccupationSearchFilters>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Root query from which every query schema extends */
export type QueryOccupationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


/** Root query from which every query schema extends */
export type QueryOrganizationUserLicensesArgs = {
  filters?: InputMaybe<OrganizationUserLicensesFilters>;
};


/** Root query from which every query schema extends */
export type QueryPopularTopicsByCategoryIdArgs = {
  categoryId: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryProLearningPathsByTopicArgs = {
  topicId: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryReflectionAnswersPaginatedArgs = {
  filters?: InputMaybe<ReflectionAnswersFilter>;
  page?: Scalars['NonNegativeInt']['input'];
  size?: Scalars['MaxResultsPerPage']['input'];
};


/** Root query from which every query schema extends */
export type QueryReflectionPromptArgs = {
  reflectionPromptId: Scalars['UUID']['input'];
};


/** Root query from which every query schema extends */
export type QuerySearchAutocompleteArgs = {
  filters?: InputMaybe<SearchAutocompleteRequestFilters>;
  limit?: Scalars['PositiveInt']['input'];
  query?: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QuerySkillsFrameworkByGeneratedLearningPathArgs = {
  id: Scalars['ID']['input'];
  versionId?: InputMaybe<Scalars['String']['input']>;
};


/** Root query from which every query schema extends */
export type QuerySkillsFrameworkByIdAndTypeArgs = {
  id: Scalars['ID']['input'];
  type: SkillsFrameworkGoalType;
  versionId?: InputMaybe<Scalars['String']['input']>;
};


/** Root query from which every query schema extends */
export type QuerySkillsFrameworkMapContentTaskArgs = {
  taskReferenceId: Scalars['String']['input'];
};


/** Root query from which every query schema extends */
export type QuerySubscriptionFeaturesAllWithAvailabilityBySubscriberArgs = {
  subscriberId: Scalars['ID']['input'];
  subscriberType: SubscriberType;
};


/** Root query from which every query schema extends */
export type QuerySubscriptionFeaturesAvailabilityByKeysArgs = {
  keys: Array<SubscriptionFeatureKey>;
};


/** Root query from which every query schema extends */
export type QuerySubscriptionPlansArgs = {
  filters?: InputMaybe<SubscriptionPlanFilters>;
};


/** Root query from which every query schema extends */
export type QueryTopicArgs = {
  id: Scalars['ID']['input'];
};


/** Root query from which every query schema extends */
export type QueryTopicGroupsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Root query from which every query schema extends */
export type QueryTopicSearchArgs = {
  filters?: InputMaybe<TopicFilters>;
  limit: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

/** Quiz with simple questions and multiple choice answers */
export type Quiz = CurriculumItem & {
  __typename?: 'Quiz';
  /** An optional description */
  description?: Maybe<Scalars['String']['output']>;
  /** The Id of the quiz */
  id: Scalars['ID']['output'];
  /** The title of the quiz */
  title: Scalars['String']['output'];
  /** Landing page to view this Quiz */
  urlLanding: Scalars['URL']['output'];
};

/** The answer created by the user in response to the given reflection prompt. */
export type ReflectionAnswer = {
  __typename?: 'ReflectionAnswer';
  /** The time the reflection answer was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The reflection answer ID. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Did user skip answering the question */
  isSkipped: Scalars['Boolean']['output'];
  /** The time the reflection answer was modified */
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The answer to the reflection prompt */
  promptAnswer?: Maybe<Scalars['String']['output']>;
  /** Reflection associated to the answer */
  reflectionPrompt: ReflectionPrompt;
  /** Resource in which the user answered the reflection */
  reflectionResource: ReflectionResource;
  /** User who this reflection answer belongs to. */
  user: User;
};

/** Response for creating a Reflection Answer */
export type ReflectionAnswerCreateResponse = {
  __typename?: 'ReflectionAnswerCreateResponse';
  /** The reflection answer created if the mutation was successful */
  reflectionAnswer?: Maybe<ReflectionAnswer>;
};

/** Response for updating the answer for a Reflection */
export type ReflectionAnswerUpdateResponse = {
  __typename?: 'ReflectionAnswerUpdateResponse';
  /** The reflection answer updated if the mutation was successful */
  reflectionAnswer?: Maybe<ReflectionAnswer>;
};

/** Variations for searching user reflection responses */
export type ReflectionAnswersFilter = {
  /** Filter by specific reflection prompt */
  reflectionPromptId?: InputMaybe<Scalars['UUID']['input']>;
  /** Filter by specific learning resource the prompt was seen in */
  reflectionResource?: InputMaybe<ReflectionResourceInput>;
  /** Include skipped responses by the user */
  showSkipped?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Paginated list of user reflection answers for list response */
export type ReflectionAnswersPaginatedResponse = Paginated & {
  __typename?: 'ReflectionAnswersPaginatedResponse';
  /** List of Reflection Answers */
  items: Array<ReflectionAnswer>;
  /** The current page number, 0 based */
  page: Scalars['Int']['output'];
  /** The total amount of pages. Calculated as (total result count / page pageSize) */
  pageCount: Scalars['Int']['output'];
  /** Total number of Reflection Answers */
  totalCount: Scalars['Int']['output'];
};

/** The prompt to be displayed to the user to answer. The prompt is intended to invoke a user response that is not a yes/no but is typically personal to them in context to what is being asked. */
export type ReflectionPrompt = {
  __typename?: 'ReflectionPrompt';
  /** The time the reflection was created at */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The reflection prompt ID. */
  id: Scalars['UUID']['output'];
  /** The time the reflection was modified */
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Question to be displayed to the user */
  prompt: Scalars['String']['output'];
};

/** The Answer given by the user for reflection prompt */
export type ReflectionPromptAnswerInput = {
  /** User decided to not answer the reflection */
  isSkipped: Scalars['Boolean']['input'];
  /** The Answer given by the user for reflection */
  promptAnswer?: InputMaybe<Scalars['String']['input']>;
  /** The resource in which the user gave the answer */
  reflectionResource: ReflectionResourceInput;
};

/** The resource objects in which the user can answer the reflection prompt */
export type ReflectionResource = Course | LearningCommunity;

/** The input used for defining the resource in which the user gave the answer */
export type ReflectionResourceInput = {
  /** Id of the resource */
  id: Scalars['ID']['input'];
  /** Resource Type */
  type: ReflectionResourceType;
  /** Version related to the resource */
  versionId?: InputMaybe<Scalars['String']['input']>;
};

/** The Types of resources in which the user can answer the reflection prompt */
export enum ReflectionResourceType {
  /** Course */
  Course = 'COURSE',
  /** Learning Community */
  LearningCommunity = 'LEARNING_COMMUNITY'
}

/** A review of a learning product */
export type Review = {
  __typename?: 'Review';
  /** The type of learning product being reviewed */
  learningProductType: LearningProductType;
  /** The review text */
  text: Scalars['String']['output'];
  /** The URL of the reviewed learning product */
  urlLearningProduct: Scalars['URL']['output'];
};

/** Identifies available search filter facets. */
export type SearchAggregation = {
  __typename?: 'SearchAggregation';
  /** Available values for this aggregation. */
  buckets: Array<SearchAggregationOption>;
  /** Key argument that can be passed to query to filter by this option. */
  key: Scalars['String']['output'];
  /** Label for this type/group of aggregation; e.g. 'Topic'. */
  label: Scalars['String']['output'];
};

/** Options for search aggregates */
export type SearchAggregationInputOption = {
  /** Key of search aggregation to apply */
  key: Scalars['String']['input'];
  /** Value of search aggregation to apply */
  value: Scalars['String']['input'];
};

/** One of the available options within a search facet type */
export type SearchAggregationOption = {
  __typename?: 'SearchAggregationOption';
  /** Number of results if this filter option were to be applied. */
  countWithFilterApplied: Scalars['Int']['output'];
  /** Human-readable label for this filter option. */
  label: Scalars['String']['output'];
  /** Value argument that can be passed to query to filter by this option. */
  value: Scalars['String']['output'];
};

/** A simple auto-complete item can be search log, course or instructor */
export type SearchAutocompleteItem = Course | CourseInstructor | SearchAutocompleteLogItem;

/** Search Log suggestion for autocomplete */
export type SearchAutocompleteLogItem = {
  __typename?: 'SearchAutocompleteLogItem';
  /** Phrase that will be shows as a suggestion */
  title: Scalars['String']['output'];
};

/** Search Autocomplete request filters */
export type SearchAutocompleteRequestFilters = {
  /** Indicates either free courses will be shown or not */
  freeCourseSuppression?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A simple autocomplete item with the tracking metadata */
export type SearchAutocompleteSuggestion = {
  __typename?: 'SearchAutocompleteSuggestion';
  /** Search Autocomplete suggestion for autocomplete */
  item?: Maybe<SearchAutocompleteItem>;
  /** Search analytics tracking id; for uniquely identifying whole result set; for this request */
  resultTrackingId: Scalars['String']['output'];
  /** Search analytics tracking id; for uniquely identifying this item */
  trackingId: Scalars['String']['output'];
};

/** A skills framework is a collection of learning objectives that are organized into a hierarchy. */
export type SkillsFramework = LearningProduct & {
  __typename?: 'SkillsFramework';
  /** Entity tag for skills framework */
  entityTag: Scalars['Int']['output'];
  /** Id to identify for skills framework */
  id: Scalars['ID']['output'];
  /** Child Learning objectives associated with this skills framework. */
  learningObjectives: Array<LearningObjective>;
  /** Name of skills framework */
  name: Scalars['String']['output'];
  /** Type for the skills framework */
  type: SkillsFrameworkGoalType;
  /** Version id of the skills framework */
  versionId: Scalars['String']['output'];
};

/** The goal for a skills framework which contains a set of learning objectives. */
export enum SkillsFrameworkGoalType {
  /** Blueprint is an assessment Udemy owns containing a list of learning objectives a user need to learn */
  Blueprint = 'BLUEPRINT',
  /** Small template that DL team generated */
  BottomUp = 'BOTTOM_UP',
  /** Open badge is not owned by Udemy containing a list of learning objectives a user need to learn. */
  OpenBadge = 'OPEN_BADGE',
  /** Generated via AI model */
  OrgUpskill = 'ORG_UPSKILL'
}

/** A SkillsFramework Id Input is used to identify a skills framework by id and type. */
export type SkillsFrameworkIdInput = {
  /** Id to identify for skills framework */
  id: Scalars['ID']['input'];
  /** Type for the skills framework */
  type: SkillsFrameworkGoalType;
};

/** To save a SkillsFramework we need the name and learning objectives associated with it */
export type SkillsFrameworkInput = {
  /** Child Learning objectives associated with this skills framework. */
  learningObjectives: Array<LearningObjectiveInput>;
  /** Name of skills framework */
  name: Scalars['String']['input'];
};

/** Response for mapping content to skills framework task reference id status */
export type SkillsFrameworkMapContentResponse = {
  __typename?: 'SkillsFrameworkMapContentResponse';
  /** Status of the mapping content to skills framework task */
  task: SkillsFrameworkMapContentTask;
};

/** Content mapping task id and current mapping task status */
export type SkillsFrameworkMapContentTask = {
  __typename?: 'SkillsFrameworkMapContentTask';
  /** Content mapping task id */
  id: Scalars['ID']['output'];
  /** Current status of mapping task */
  status: SkillsFrameworkMapContentTaskStatus;
};

/** Status of map content for a reference Id */
export enum SkillsFrameworkMapContentTaskStatus {
  /** Task failed */
  Failed = 'FAILED',
  /** Task is in progress */
  InProgress = 'IN_PROGRESS',
  /** Task was killed */
  Killed = 'KILLED',
  /** Task is completed successfully */
  Success = 'SUCCESS'
}

/** This is the response for updating a skills framework */
export type SkillsFrameworkUpdateResponse = {
  __typename?: 'SkillsFrameworkUpdateResponse';
  /** Saved skills framework */
  skillsFramework?: Maybe<SkillsFramework>;
};

/** Details about a user's lecture minutes consumption streak, including what to achieve by what date */
export type StreakLectureConsumptionWeekly = {
  __typename?: 'StreakLectureConsumptionWeekly';
  /** Lecture consumption target the user needs to hit to continue streak, in minutes. */
  goalMinutesThisWeek?: Maybe<Scalars['Int']['output']>;
  /** Lecture consumption so far this week, in minutes. */
  minutesThisWeek?: Maybe<Scalars['Int']['output']>;
  /** Current status of the streak. */
  streakStatus: StreakStatus;
  /** Date to display to user for when the week ended. */
  weekEndTime: Scalars['DateTime']['output'];
  /** Date to display to user for when the week started. */
  weekStartTime: Scalars['DateTime']['output'];
  /** Serialized WeeklyStreak object. */
  weeklyStreak: WeeklyStreak;
};

/** Status of any type of streak */
export enum StreakStatus {
  /** The user's streak is ongoing and has not been broken, and they have completed their goals this week */
  ActiveCurrentPeriodAchieved = 'ACTIVE_CURRENT_PERIOD_ACHIEVED',
  /** The user's streak is ongoing and has not been broken, but they haven't completed their goals this week */
  ActiveCurrentPeriodUnachieved = 'ACTIVE_CURRENT_PERIOD_UNACHIEVED',
  /** The user's streak has expired, they will need to start a new streak from scratch */
  Dropped = 'DROPPED',
  /** The user has no history for this type of streak, they never have achieved this streak */
  ZeroHistory = 'ZERO_HISTORY'
}

/** SubCategory that is part of CourseCategory */
export type SubCategory = {
  /** ID of the subcategory */
  id: Scalars['ID']['output'];
  /** Name of the subcategory */
  name: Scalars['String']['output'];
};

/** Subscription plan that the subscriber is subscribed to */
export type SubscribedPlan = {
  __typename?: 'SubscribedPlan';
  /** Content collections that are included in the subscription plan */
  contentCollections: Array<ContentCollection>;
  /** Description of the subscription plan */
  description?: Maybe<Scalars['String']['output']>;
  /** Id of the subscription plan */
  id: Scalars['ID']['output'];
  /** The types of learning products included in the subscription plan */
  learningProductTypes: Array<LearningProductType>;
  /** Type of the subscription product in the plan */
  productType: SubscriptionPlanProductType;
  /** Title of the subscription plan */
  title: Scalars['String']['output'];
  /** URL for the learn more page of the subscription plan */
  urlLearnMore: Scalars['URL']['output'];
};

/** Subscriber of the subscription */
export type Subscriber = Organization | User;

/** Subscriber Type */
export enum SubscriberType {
  /** Organization */
  Organization = 'ORGANIZATION',
  /** User */
  User = 'USER'
}

/** Billing details of a subscription */
export type SubscriptionBilling = {
  __typename?: 'SubscriptionBilling';
  /** Total recurring amount for the subscription including tax */
  chargePrice: Money;
  /** End date of the current billing period */
  currentPeriodEndDate: Scalars['DateTime']['output'];
  /** Start date of the current billing period */
  currentPeriodStartDate: Scalars['DateTime']['output'];
  /** End date of the discount period */
  discountPeriodEndDate?: Maybe<Scalars['DateTime']['output']>;
  /** Total recurring amount for the discount period including tax */
  discountPrice?: Maybe<Money>;
  /** List price without the discount including tax */
  listPrice: Money;
  /** Payment method used for the subscription */
  paymentMethod: SubscriptionPaymentMethod;
  /** Tax amount for the subscription */
  taxPrice: Money;
};

/** Credit card used for the subscription */
export type SubscriptionCreditCard = {
  __typename?: 'SubscriptionCreditCard';
  /** Last 4 digits of the card number */
  last4Digits: Scalars['String']['output'];
  /** Card provider (visa, mc, amex, etc.) */
  provider: Scalars['String']['output'];
};

/** Subscription object that represents consumer and UB subscriptions */
export type SubscriptionEnrollment = {
  __typename?: 'SubscriptionEnrollment';
  /** Billing information for the subscription with recurring payment support */
  billing?: Maybe<SubscriptionBilling>;
  /** Date when the subscription was canceled */
  cancelDate?: Maybe<Scalars['DateTime']['output']>;
  /** Reference key to Recurring Billing System */
  checkoutReference?: Maybe<Scalars['String']['output']>;
  /** End date of the subscription */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Id of the subscription */
  id: Scalars['ID']['output'];
  /** Max number of licenses (seats) for the subscription */
  licenseCount: Scalars['Int']['output'];
  /** Renewal period of the subscription */
  renewalInterval: DateInterval;
  /** Start date of the subscription */
  startDate: Scalars['DateTime']['output'];
  /** Status of the subscription */
  status: SubscriptionStatus;
  /** Subscription plan that the subscriber is subscribed to */
  subscribedPlan: SubscribedPlan;
  /** User or Organization that has the subscription */
  subscriber: Subscriber;
  /** Trial period of the subscription */
  trialInterval?: Maybe<DateInterval>;
  /** Number of licenses (seats) in use */
  usedLicenseCount: Scalars['Int']['output'];
};

/** Response for subscription enrollment update mutations */
export type SubscriptionEnrollmentUpdateResponse = {
  __typename?: 'SubscriptionEnrollmentUpdateResponse';
  /** The subscription enrollment that was updated */
  subscriptionEnrollment?: Maybe<SubscriptionEnrollment>;
};

/** Subscription Feature */
export type SubscriptionFeature = {
  __typename?: 'SubscriptionFeature';
  /** Subscription feature description */
  description: Scalars['String']['output'];
  /** Subscription feature grouping category */
  groupingCategory?: Maybe<Scalars['String']['output']>;
  /** Whether subscription feature is available or not based on the override */
  isAvailable: Scalars['Boolean']['output'];
  /** Whether Subscription feature value is overridden or not */
  isOverridden: Scalars['Boolean']['output'];
  /** Subscription feature unique identifier */
  key: SubscriptionFeatureKey;
  /** Subscription feature name */
  name: Scalars['String']['output'];
};

/** Subscription Feature Availability */
export type SubscriptionFeatureAvailability = {
  __typename?: 'SubscriptionFeatureAvailability';
  /** Whether subscription feature is available or not */
  isAvailable: Scalars['Boolean']['output'];
  /** Subscription feature unique identifier */
  key: SubscriptionFeatureKey;
};

/** Subscription Feature Key */
export enum SubscriptionFeatureKey {
  /** SCIM Feature */
  Scim = 'SCIM'
}

/** Subscription Feature Override */
export type SubscriptionFeatureOverride = {
  __typename?: 'SubscriptionFeatureOverride';
  /** The value to override the subscription feature */
  isAvailable: Scalars['Boolean']['output'];
  /** Subscription feature unique identifier */
  key: SubscriptionFeatureKey;
  /** ID of the Subscriber to whom the key is being overridden for */
  subscriberId: Scalars['ID']['output'];
  /** Type of the Subscriber to whom the key is being overridden for */
  subscriberType: SubscriberType;
};

/** Subscription Feature Override Input */
export type SubscriptionFeatureOverrideInput = {
  /** The value to override the subscription feature */
  isAvailable: Scalars['Boolean']['input'];
  /** Subscription feature unique identifier */
  key: SubscriptionFeatureKey;
  /** ID of the Subscriber to whom the key is being overridden for */
  subscriberId: Scalars['ID']['input'];
  /** Type of the Subscriber to whom the key is being overridden for */
  subscriberType: SubscriberType;
};

/** Response for the Subscription Feature Set Overrides mutations */
export type SubscriptionFeaturesSetOverrideResponse = {
  __typename?: 'SubscriptionFeaturesSetOverrideResponse';
  /** Subscription Feature Override that was created or modified */
  overrides: Array<SubscriptionFeatureOverride>;
};

/** Payment method of the subscription */
export type SubscriptionPaymentMethod = SubscriptionCreditCard;

/** An offer for a consumer subscription plan to access a catalog of Udemy content */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  /** List of content groups included in a given plan */
  contentCollections: Array<ContentCollection>;
  /** ID of the subscription plan: */
  id: Scalars['ID']['output'];
  /** Computed price options for given plan */
  priceOptions: Array<SubscriptionPlanPricingOptionItem>;
  /** Type of subscription plan being offered */
  productType: SubscriptionPlanProductType;
  /** Express checkout url for given plan */
  urlExpressCheckout: Scalars['URL']['output'];
  /** Learn more url for a given plan */
  urlLearnMore: Scalars['URL']['output'];
  /** Terms and Conditions url for a given plan */
  urlTermsOfUse: Scalars['URL']['output'];
};


/** An offer for a consumer subscription plan to access a catalog of Udemy content */
export type SubscriptionPlanPriceOptionsArgs = {
  licenseCount?: InputMaybe<Scalars['Int']['input']>;
};

/** Contains information about the discount metadata for a given subscription plan price option */
export type SubscriptionPlanDiscount = {
  __typename?: 'SubscriptionPlanDiscount';
  /** List of applied discounts on the plan price option data */
  appliedDiscounts: Array<SubscriptionPlanDiscountDetails>;
};

/** Details of the specific discount type */
export type SubscriptionPlanDiscountDataItem = SubscriptionPlanPriceDiscountData | SubscriptionPlanTrialDiscountData;

/** Contains information on the start and end perioid for the discount interval type can be month/year */
export type SubscriptionPlanDiscountDateIntervalType = {
  __typename?: 'SubscriptionPlanDiscountDateIntervalType';
  /** Total duration of the discount period */
  duration: Scalars['Int']['output'];
  /** End period of the discounted data */
  end: Scalars['Int']['output'];
  /** Start period of the discounted data */
  start: Scalars['Int']['output'];
  /** Interval type for the discounted period */
  type: DateIntervalType;
};

/** Discount details for applied discount */
export type SubscriptionPlanDiscountDetails = {
  __typename?: 'SubscriptionPlanDiscountDetails';
  /** Coupon code/campaign code for the discount applied */
  code: Scalars['String']['output'];
  /** Discount data for the specific discount type */
  data: SubscriptionPlanDiscountDataItem;
  /** Specific discount type for subscriptions ex: trial/price/seat discounts */
  discountType: SubscriptionPlanDiscountType;
  /** Flag for the applied code. tells if the applied discount can be removed on FE */
  isRemovable: Scalars['Boolean']['output'];
  /** Type of coupon code. will be either one of udemy/customer coupon */
  sourceType: DiscountSourceType;
};

/** enum definition for different discount types for subscription */
export enum SubscriptionPlanDiscountType {
  /** discount on the plan price monthly/yearly */
  PlanPriceDiscount = 'PLAN_PRICE_DISCOUNT',
  /** discount on the trial days */
  TrialDiscount = 'TRIAL_DISCOUNT'
}

/** Filers for subscription plans */
export type SubscriptionPlanFilters = {
  /** Filter by product type */
  productType?: InputMaybe<SubscriptionPlanProductType>;
};

/** Contains information about the license context for a given subscription plan price option */
export type SubscriptionPlanLicenseContext = {
  __typename?: 'SubscriptionPlanLicenseContext';
  /** Default license count to be offered for purchase for given subscription plan option */
  defaultLicenseCount: Scalars['Int']['output'];
  /** License count for the subscription plan option */
  licenseCount: Scalars['Int']['output'];
  /** Maximum license count for purchase for given subscription plan option */
  maximumLicenseCount: Scalars['Int']['output'];
  /** Minimum license count for purchase for given subscription plan option */
  minimumLicenseCount: Scalars['Int']['output'];
  /** The unit price of the subscription price plan option based on provided requested count from request */
  unitPrice: Money;
};

/** Price data for plan pricing options */
export type SubscriptionPlanPrice = {
  __typename?: 'SubscriptionPlanPrice';
  /** The annual savings amount for the subscription price plan when compared to monthly plans. calculated on pricing backend can be null */
  annualSavings?: Maybe<Scalars['Decimal']['output']>;
  /** Currency */
  currency?: Maybe<CurrencyCode>;
  /** Discount price for the pricing option */
  discount?: Maybe<Scalars['Decimal']['output']>;
  /** The annual savings amount on discounted annual price for the subscription price plan when compared to monthly plans. */
  discountAnnualSavings?: Maybe<Scalars['Decimal']['output']>;
  /** The monthly price for the discounted annual price option */
  discountMonthly?: Maybe<Scalars['Decimal']['output']>;
  /** List price for the pricing option */
  list: Scalars['Decimal']['output'];
  /** Monthly price for the annual pricing option, for monthly plan price option will be equal to list price. */
  monthly?: Maybe<Scalars['Decimal']['output']>;
};

/** Contains information on the plan price discount */
export type SubscriptionPlanPriceDiscountData = {
  __typename?: 'SubscriptionPlanPriceDiscountData';
  /** Contains information on the start and end period for discount */
  interval: SubscriptionPlanDiscountDateIntervalType;
  /** Discount type for the price can be percentage off/fixed price discount */
  type: SubscriptionPlanPriceDiscountType;
  /** Discount value applied on the actual price. */
  value: Scalars['Decimal']['output'];
};

/** Definition for different price discount types */
export enum SubscriptionPlanPriceDiscountType {
  /** fixed value discount on the price */
  Fixed = 'FIXED',
  /** percentage of on the price */
  Percentage = 'PERCENTAGE'
}

/** Union of possible plan pricing options */
export type SubscriptionPlanPricingOptionItem = AnnualSubscriptionPlanPricingOption | DailySubscriptionPlanPricingOption | MonthlySubscriptionPlanPricingOption | WeeklySubscriptionPlanPricingOption;

/** The type of subscription plan being offered */
export enum SubscriptionPlanProductType {
  /** Consumer subscription (previously Spadefish) */
  Consumersubscription = 'CONSUMERSUBSCRIPTION',
  /** Enterprise Plan */
  Enterprise = 'ENTERPRISE',
  /** Enterprise PRO Plan */
  Enterprisepro = 'ENTERPRISEPRO',
  /** Team Plan */
  Team = 'TEAM',
  /** Udemy Pro */
  Udemypro = 'UDEMYPRO'
}

/** contains information on the trial discount if available */
export type SubscriptionPlanTrialDiscountData = {
  __typename?: 'SubscriptionPlanTrialDiscountData';
  /** The total trial days offered as part of the discount */
  days: Scalars['Int']['output'];
};

/** Status of the subscription */
export enum SubscriptionStatus {
  /** Subscription is paid and active */
  Active = 'ACTIVE',
  /** Subscription has canceled but still usable until the end date */
  Canceled = 'CANCELED',
  /** Subscription has ended and not usable anymore */
  Expired = 'EXPIRED',
  /** Subscription has not started yet (only for UB subscriptions) */
  Future = 'FUTURE',
  /** Subscription is in free trial period */
  Trial = 'TRIAL'
}

/** Type representing details about the trial subscription offer available for a given user and plan */
export type SubscriptionTrial = {
  __typename?: 'SubscriptionTrial';
  /** The length of the trial available to a user for a subscription plan. */
  dateInterval: DateInterval;
};

/** Topic */
export type Topic = {
  __typename?: 'Topic';
  /** Topic groups this topic belongs to */
  groups: Array<TopicGroup>;
  /** ID of topic */
  id: Scalars['ID']['output'];
  /** Title of topic (Python, Programming Languages) */
  name: Scalars['String']['output'];
  /** A collection of question and answer pairs with optional link for additional context */
  questionsAndAnswers: Array<TopicQuestionAndAnswer>;
  /** Web url of the topic page */
  url: Scalars['URL']['output'];
};

/** Topic Filters for searching topics. All filters are ANDed together. */
export type TopicFilters = {
  /** Include topics associated with these topic ids */
  associatedWithTopicIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Exclude topic group IDs */
  excludeTopicGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Include topic group IDs */
  includeTopicGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Include topics related to occupation group IDs */
  occupationGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Include topics related to occupation IDs */
  occupationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Topic Group */
export type TopicGroup = {
  __typename?: 'TopicGroup';
  /** ID of topic group */
  id: Scalars['ID']['output'];
  /** Name of topic group */
  name: Scalars['String']['output'];
};

/** Response for topic interest update mutations */
export type TopicInterestUpdateResponse = {
  __typename?: 'TopicInterestUpdateResponse';
  /** The topics that are assigned as interests to the user */
  topicInterests?: Maybe<Array<Topic>>;
};

/** Types of popularity for Topics */
export enum TopicPopularityTypes {
  /** Popular popularity type */
  Popular = 'POPULAR',
  /** Trending popularity type */
  Trending = 'TRENDING'
}

/** A question and answer pair with optional link for additional context */
export type TopicQuestionAndAnswer = {
  __typename?: 'TopicQuestionAndAnswer';
  /** The answer text */
  answer: Scalars['String']['output'];
  /** ID of the question and answer */
  id: Scalars['ID']['output'];
  /** Text to display for the link to additional information about the question and answer */
  linkText?: Maybe<Scalars['String']['output']>;
  /** The question text */
  question: Scalars['String']['output'];
  /** The URL for additional information about the question and answer */
  urlReadMore?: Maybe<Scalars['URL']['output']>;
};

/** A Udemy user */
export type User = {
  __typename?: 'User';
  /** Id of the user */
  id: Scalars['ID']['output'];
  /**
   * Personally identifiable information of the User
   * scopes required: udemy:application
   */
  profile: UserProfile;
};

/** Represents the each notification preference of a user */
export type UserNotificationPreference = {
  __typename?: 'UserNotificationPreference';
  /** Preference value of particular notification type */
  isEnabled: Scalars['Boolean']['output'];
  /** Type of notification preference */
  notificationType: UserNotificationType;
};

/** Represents the users notification preference */
export type UserNotificationPreferenceInput = {
  /** Preference value of particular notification type */
  isEnabled: Scalars['Boolean']['input'];
  /** Type of notification preference */
  notificationType: UserNotificationType;
};

/** Input for update for notification preferences  */
export type UserNotificationPreferenceSetInput = {
  /** Source of the action to store audit information */
  source: Scalars['String']['input'];
  /** Set of users notification preferences containing preference value */
  userNotificationPreferences: Array<UserNotificationPreferenceInput>;
};

/** Response of update user notification preferences */
export type UserNotificationPreferencesUpdateResponse = {
  __typename?: 'UserNotificationPreferencesUpdateResponse';
  /** The updated notification preferences */
  userNotificationPreferences: Array<UserNotificationPreference>;
};

/** Type of notification preferences */
export enum UserNotificationType {
  /** Encapsulates all notifications */
  All = 'ALL',
  /** Notifications that inform about the state of asset */
  AssetReady = 'ASSET_READY',
  /** Notifications that announces an update about specific course */
  CourseAnnouncement = 'COURSE_ANNOUNCEMENT',
  /** Course related messages */
  CourseMessageNotification = 'COURSE_MESSAGE_NOTIFICATION',
  /** Notifications that contain promo for specific course */
  CoursePromotion = 'COURSE_PROMOTION',
  /** Notifications that contain recommendations */
  CourseRecommendations = 'COURSE_RECOMMENDATIONS',
  /** Daily digest informing notifications */
  DailyDiscussionsDigest = 'DAILY_DISCUSSIONS_DIGEST',
  /** Offerings for MX learners to upgrade to UB */
  EnterpriseUbOfferings = 'ENTERPRISE_UB_OFFERINGS',
  /** Notifications sent by instructors to learners */
  InstructorAnnouncement = 'INSTRUCTOR_ANNOUNCEMENT',
  /** Inspiration notifications */
  LearningInspiration = 'LEARNING_INSPIRATION',
  /** Learning statistics notifications */
  LearningStats = 'LEARNING_STATS',
  /** On-site message notifications */
  MessageNotification = 'MESSAGE_NOTIFICATION',
  /** Promo containing notifications */
  OffersPromotions = 'OFFERS_PROMOTIONS',
  /** Notifications that contains announcements */
  ProductAnnouncements = 'PRODUCT_ANNOUNCEMENTS',
  /** Represents notifications containing promotions */
  Promotional = 'PROMOTIONAL',
  /** Video upload status notification */
  VideoUploadError = 'VIDEO_UPLOAD_ERROR'
}

/** Shows details of the user's occupation */
export type UserOccupationInfo = {
  __typename?: 'UserOccupationInfo';
  /** User's canonical occupation, may be null if user provided raw occupation. */
  canonical?: Maybe<Occupation>;
  /** Occupation group of the occupation */
  group?: Maybe<OccupationGroup>;
  /** Shows if the user is manager */
  isManager?: Maybe<Scalars['Boolean']['output']>;
  /** User's raw input occupation */
  raw?: Maybe<Occupation>;
};

/** Mutation response for occupation info update */
export type UserOccupationInfoUpdateResponse = {
  __typename?: 'UserOccupationInfoUpdateResponse';
  /** The updated user occupation info if the mutation succeeded */
  userOccupationInfo?: Maybe<UserOccupationInfo>;
};

/** Represents the basic information of a user */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** Email of the user */
  email: Scalars['EmailAddress']['output'];
  /** User avatar in different sizes */
  images: UserProfileImages;
  /** Name of the user */
  name: Scalars['String']['output'];
  /** Surname of the user */
  surname: Scalars['String']['output'];
};

/** Represents different sizes of the same image */
export type UserProfileImages = {
  __typename?: 'UserProfileImages';
  /** User profile image size 125h */
  height125: Scalars['URL']['output'];
  /** User profile image size 200h */
  height200: Scalars['URL']['output'];
  /** User profile image size 50x50 */
  px50x50: Scalars['URL']['output'];
  /** User profile image size 75x75 */
  px75x75: Scalars['URL']['output'];
  /** User profile image size 100x100 */
  px100x100: Scalars['URL']['output'];
};

/** Video lecture */
export type VideoLecture = CurriculumItem & Lecture & {
  __typename?: 'VideoLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Total duration of the lecture's content in seconds */
  durationInSeconds: Scalars['DurationInSeconds']['output'];
  /** Id of the video lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** Length of the video in course */
export enum VideoLength {
  /** Extra long */
  ExtraLong = 'EXTRA_LONG',
  /** Extra short */
  ExtraShort = 'EXTRA_SHORT',
  /** Long */
  Long = 'LONG',
  /** Medium */
  Medium = 'MEDIUM',
  /** Short */
  Short = 'SHORT'
}

/** Mashup lecture has both video and a presentation */
export type VideoMashupLecture = CurriculumItem & Lecture & {
  __typename?: 'VideoMashupLecture';
  /** The Course this lecture belongs to */
  course?: Maybe<Course>;
  /** Total duration of the lecture's content in seconds */
  durationInSeconds: Scalars['DurationInSeconds']['output'];
  /** Id of the lecture */
  id: Scalars['ID']['output'];
  /** Images by their dimensions */
  images: LectureImages;
  /** The URL of the lecture thumbnail */
  thumbnail?: Maybe<Scalars['URL']['output']>;
  /** Lecture title */
  title: Scalars['String']['output'];
  /** The URL to access the lecture on the auto-enroll page */
  urlAutoEnroll: Scalars['URL']['output'];
  /** Landing page to view this Lecture */
  urlLanding: Scalars['URL']['output'];
};

/** Contains metadata about any retention streak */
export type WeeklyStreak = {
  __typename?: 'WeeklyStreak';
  /** Have they achieved their goal this week? */
  achievedThisWeek: Scalars['Boolean']['output'];
  /** ID of the streak. */
  id: Scalars['ID']['output'];
  /** Start date of the first week of this streak chain. */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** Current number of weeks achieved. */
  streakLength: Scalars['Int']['output'];
  /** User who this streak belongs to. */
  userId: Scalars['ID']['output'];
};

/** Price option for computed price plan. Will be returned for weekly plans */
export type WeeklySubscriptionPlanPricingOption = {
  __typename?: 'WeeklySubscriptionPlanPricingOption';
  /** Contains information about the discount context for a given subscription plan price option */
  discount?: Maybe<SubscriptionPlanDiscount>;
  /** Field containing details about the discounted trial subscription offer for a given user. Null indicates no trial is available */
  discountTrial?: Maybe<SubscriptionTrial>;
  /** ID of the price option: */
  id: Scalars['ID']['output'];
  /** Contains information about the license context for a given subscription plan price option */
  licenseContext?: Maybe<SubscriptionPlanLicenseContext>;
  /** The list price of the subscription price plan based on provided requested count from request */
  listPrice: Money;
  /** Field showing the prices for the option. */
  price?: Maybe<SubscriptionPlanPrice>;
  /** Interval for renewing the subscription plan ie the length of the subscription plan */
  renewalInterval: DateInterval;
  /** Field containing details about the trial subscription offer for a given user. Null indicates no trial is available */
  trial?: Maybe<SubscriptionTrial>;
};

export type DynamicWebContentTestimonialsQueryVariables = Exact<{
  pageType: DynamicWebContentPageType;
  isContentPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contentPreviewOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type DynamicWebContentTestimonialsQuery = { __typename?: 'Query', dynamicWebContent: { __typename?: 'DynamicWebContent', heading?: string | null, trackingId?: string | null, contents?: Array<{ __typename?: 'DynamicWebContent', labelText?: string | null, description?: string | null, contentUrl?: any | null, contentUrlText?: string | null, trackingId?: string | null, contents?: Array<{ __typename?: 'DynamicWebContent', heading?: string | null, description?: string | null, image?: { __typename?: 'DynamicWebContentImage', url?: any | null } | null } | null> | null } | null> | null } };

export type DynamicWebContentCaseStudiesQueryVariables = Exact<{
  pageType: DynamicWebContentPageType;
  isContentPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contentPreviewOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type DynamicWebContentCaseStudiesQuery = { __typename?: 'Query', dynamicWebContent: { __typename?: 'DynamicWebContent', trackingId?: string | null, contents?: Array<{ __typename?: 'DynamicWebContent', heading?: string | null, trackingId?: string | null, contentUrl?: any | null, contentUrlText?: string | null, image?: { __typename?: 'DynamicWebContentImage', url?: any | null, altText?: string | null } | null, secondaryImage?: { __typename?: 'DynamicWebContentImage', url?: any | null, altText?: string | null } | null, contents?: Array<{ __typename?: 'DynamicWebContent', heading?: string | null, description?: string | null } | null> | null } | null> | null } };

export type DynamicWebContentPromoItemQueryVariables = Exact<{
  pageType: DynamicWebContentPageType;
  isContentPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contentPreviewOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type DynamicWebContentPromoItemQuery = { __typename?: 'Query', dynamicWebContent: { __typename?: 'DynamicWebContent', heading?: string | null, description?: string | null, contentUrl?: any | null, contentUrlText?: string | null, trackingId?: string | null, image?: { __typename?: 'DynamicWebContentImage', url?: any | null, altText?: string | null } | null } };

export type DynamicWebContentFeaturesQueryVariables = Exact<{
  pageType: DynamicWebContentPageType;
  isContentPreviewEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  contentPreviewOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type DynamicWebContentFeaturesQuery = { __typename?: 'Query', dynamicWebContent: { __typename?: 'DynamicWebContent', heading?: string | null, description?: string | null, trackingId?: string | null, contents?: Array<{ __typename?: 'DynamicWebContent', heading?: string | null, description?: string | null, contentUrl?: any | null, contentUrlText?: string | null, labelText?: string | null, trackingId?: string | null, image?: { __typename?: 'DynamicWebContentImage', url?: any | null, altText?: string | null } | null, secondaryImage?: { __typename?: 'DynamicWebContentImage', url?: any | null, altText?: string | null } | null } | null> | null } };



export const DynamicWebContentTestimonialsDocument = `
    query DynamicWebContentTestimonials($pageType: DynamicWebContentPageType!, $isContentPreviewEnabled: Boolean = false, $contentPreviewOverrides: [String]) {
  dynamicWebContent(
    filters: {contentId: "learningTestimonials", pageType: $pageType, isContentPreviewEnabled: $isContentPreviewEnabled, contentPreviewOverrides: $contentPreviewOverrides}
  ) {
    heading
    contents {
      labelText
      description
      contentUrl
      contentUrlText
      contents {
        heading
        description
        image {
          url
        }
      }
      trackingId
    }
    trackingId
  }
}
    `;

export const useDynamicWebContentTestimonialsQuery = <
      TData = DynamicWebContentTestimonialsQuery,
      TError = unknown
    >(
      variables: DynamicWebContentTestimonialsQueryVariables,
      options?: UseQueryOptions<DynamicWebContentTestimonialsQuery, TError, TData>
    ) => {
    
    return useQuery<DynamicWebContentTestimonialsQuery, TError, TData>(
      ['DynamicWebContentTestimonials', variables],
      fetcher<DynamicWebContentTestimonialsQuery, DynamicWebContentTestimonialsQueryVariables>(DynamicWebContentTestimonialsDocument, variables),
      options
    )};

useDynamicWebContentTestimonialsQuery.getKey = (variables: DynamicWebContentTestimonialsQueryVariables) => ['DynamicWebContentTestimonials', variables];


useDynamicWebContentTestimonialsQuery.fetcher = (variables: DynamicWebContentTestimonialsQueryVariables, options?: RequestInit['headers']) => fetcher<DynamicWebContentTestimonialsQuery, DynamicWebContentTestimonialsQueryVariables>(DynamicWebContentTestimonialsDocument, variables, options);

export const DynamicWebContentCaseStudiesDocument = `
    query DynamicWebContentCaseStudies($pageType: DynamicWebContentPageType!, $isContentPreviewEnabled: Boolean = false, $contentPreviewOverrides: [String]) {
  dynamicWebContent(
    filters: {contentId: "caseStudies", pageType: $pageType, isContentPreviewEnabled: $isContentPreviewEnabled, contentPreviewOverrides: $contentPreviewOverrides}
  ) {
    contents {
      heading
      trackingId
      image {
        url
        altText
      }
      secondaryImage {
        url
        altText
      }
      contentUrl
      contentUrlText
      contents {
        heading
        description
      }
    }
    trackingId
  }
}
    `;

export const useDynamicWebContentCaseStudiesQuery = <
      TData = DynamicWebContentCaseStudiesQuery,
      TError = unknown
    >(
      variables: DynamicWebContentCaseStudiesQueryVariables,
      options?: UseQueryOptions<DynamicWebContentCaseStudiesQuery, TError, TData>
    ) => {
    
    return useQuery<DynamicWebContentCaseStudiesQuery, TError, TData>(
      ['DynamicWebContentCaseStudies', variables],
      fetcher<DynamicWebContentCaseStudiesQuery, DynamicWebContentCaseStudiesQueryVariables>(DynamicWebContentCaseStudiesDocument, variables),
      options
    )};

useDynamicWebContentCaseStudiesQuery.getKey = (variables: DynamicWebContentCaseStudiesQueryVariables) => ['DynamicWebContentCaseStudies', variables];


useDynamicWebContentCaseStudiesQuery.fetcher = (variables: DynamicWebContentCaseStudiesQueryVariables, options?: RequestInit['headers']) => fetcher<DynamicWebContentCaseStudiesQuery, DynamicWebContentCaseStudiesQueryVariables>(DynamicWebContentCaseStudiesDocument, variables, options);

export const DynamicWebContentPromoItemDocument = `
    query DynamicWebContentPromoItem($pageType: DynamicWebContentPageType!, $isContentPreviewEnabled: Boolean = false, $contentPreviewOverrides: [String]) {
  dynamicWebContent(
    filters: {contentId: "promotionItem", pageType: $pageType, isContentPreviewEnabled: $isContentPreviewEnabled, contentPreviewOverrides: $contentPreviewOverrides}
  ) {
    heading
    description
    contentUrl
    contentUrlText
    image {
      url
      altText
    }
    trackingId
  }
}
    `;

export const useDynamicWebContentPromoItemQuery = <
      TData = DynamicWebContentPromoItemQuery,
      TError = unknown
    >(
      variables: DynamicWebContentPromoItemQueryVariables,
      options?: UseQueryOptions<DynamicWebContentPromoItemQuery, TError, TData>
    ) => {
    
    return useQuery<DynamicWebContentPromoItemQuery, TError, TData>(
      ['DynamicWebContentPromoItem', variables],
      fetcher<DynamicWebContentPromoItemQuery, DynamicWebContentPromoItemQueryVariables>(DynamicWebContentPromoItemDocument, variables),
      options
    )};

useDynamicWebContentPromoItemQuery.getKey = (variables: DynamicWebContentPromoItemQueryVariables) => ['DynamicWebContentPromoItem', variables];


useDynamicWebContentPromoItemQuery.fetcher = (variables: DynamicWebContentPromoItemQueryVariables, options?: RequestInit['headers']) => fetcher<DynamicWebContentPromoItemQuery, DynamicWebContentPromoItemQueryVariables>(DynamicWebContentPromoItemDocument, variables, options);

export const DynamicWebContentFeaturesDocument = `
    query DynamicWebContentFeatures($pageType: DynamicWebContentPageType!, $isContentPreviewEnabled: Boolean = false, $contentPreviewOverrides: [String]) {
  dynamicWebContent(
    filters: {contentId: "features", pageType: $pageType, isContentPreviewEnabled: $isContentPreviewEnabled, contentPreviewOverrides: $contentPreviewOverrides}
  ) {
    heading
    description
    contents {
      heading
      description
      contentUrl
      contentUrlText
      labelText
      image {
        url
        altText
      }
      secondaryImage {
        url
        altText
      }
      trackingId
    }
    trackingId
  }
}
    `;

export const useDynamicWebContentFeaturesQuery = <
      TData = DynamicWebContentFeaturesQuery,
      TError = unknown
    >(
      variables: DynamicWebContentFeaturesQueryVariables,
      options?: UseQueryOptions<DynamicWebContentFeaturesQuery, TError, TData>
    ) => {
    
    return useQuery<DynamicWebContentFeaturesQuery, TError, TData>(
      ['DynamicWebContentFeatures', variables],
      fetcher<DynamicWebContentFeaturesQuery, DynamicWebContentFeaturesQueryVariables>(DynamicWebContentFeaturesDocument, variables),
      options
    )};

useDynamicWebContentFeaturesQuery.getKey = (variables: DynamicWebContentFeaturesQueryVariables) => ['DynamicWebContentFeatures', variables];


useDynamicWebContentFeaturesQuery.fetcher = (variables: DynamicWebContentFeaturesQueryVariables, options?: RequestInit['headers']) => fetcher<DynamicWebContentFeaturesQuery, DynamicWebContentFeaturesQueryVariables>(DynamicWebContentFeaturesDocument, variables, options);
