import React, {createContext} from 'react';

import {professionalSkillsShowcaseData} from '@udemy/react-discovery-units';

type ProfessionalSkillsShowcaseDataContextValue = typeof professionalSkillsShowcaseData;

export const ProfessionalSkillsShowcaseDataContext =
    createContext<ProfessionalSkillsShowcaseDataContextValue>(
        {} as ProfessionalSkillsShowcaseDataContextValue,
    );

interface ProfessionalSkillsShowcaseDataProviderProps {
    context: ProfessionalSkillsShowcaseDataContextValue;
}

export const ProfessionalSkillsShowcaseDataProvider: React.FC<
    ProfessionalSkillsShowcaseDataProviderProps
> = ({context, children}) => {
    return (
        <ProfessionalSkillsShowcaseDataContext.Provider value={context}>
            {children}
        </ProfessionalSkillsShowcaseDataContext.Provider>
    );
};
