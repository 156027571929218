import {dehydrate, QueryClient} from '@tanstack/react-query';
import {GetStaticPropsContext} from 'next/types';

import {
    getAllAcademiesForLocale,
    InDemandCareer,
    buildInDemandCareer,
} from '@udemy/career-academies';
import {RENDER_MODES} from '@udemy/nextjs-core';
import {udSentry} from '@udemy/sentry';

import {LoggedOutHomePageFeature} from 'src/features/lohp';
import {getCompactTranslations} from 'src/lib-server/locale.server';
import {FOUR_HOURS} from 'src/lib/constants';
import {AppContextData} from 'src/types/page-props';

import {getStaticAppContext} from '../api/context.api';
import {getAcademyDetailsData} from './lib/get-academy-details-data';
import {getLohpData} from './lib/get-lohp-data';

/**
 * @remarks
 * Normally, we prefer to use just ISR without any pre-generation on the server. However, in this case, we
 * needed to remove that dynamic routing since it was causing path mismatch on the client side hydration such as:
 * Requested URL (href) on client -> /
 * Page Path on server (next.js)  -> /lohp/
 *
 * This causes mismatch on next.js hydration since it expected /lohp on the client side href.
 */
export const getStaticProps = async ({locale}: GetStaticPropsContext) => {
    locale = locale as string;
    const translations = await getCompactTranslations(locale as string);
    const client = new QueryClient();

    // Resolve appContext
    const staticDataPromise = getStaticAppContext({locale});

    const {professionalSkillsShowcaseData} = getLohpData(locale);

    // Resolve Career Academies
    const academiesListForLocale = getAllAcademiesForLocale(locale);

    const academyDetailsPromise = Promise.allSettled(
        academiesListForLocale.map((academy) =>
            getAcademyDetailsData(academy.curriculum.buyableIds.map(Number), locale),
        ),
    );

    const [staticData, academyDetailsResults] = await Promise.all([
        staticDataPromise,
        academyDetailsPromise,
    ]);

    const appContext: AppContextData = staticData;

    const academiesList = academyDetailsResults.reduce<InDemandCareer[]>(
        (acc, detailsResult, index) => {
            if (detailsResult.status === 'fulfilled') {
                const academy = academiesListForLocale[index];
                const courses = detailsResult.value;
                acc.push(buildInDemandCareer(academy, courses));
            } else {
                udSentry.captureException(detailsResult.reason);
            }
            return acc;
        },
        [],
    );

    return {
        props: {
            appContext,
            dehydratedState: dehydrate(client),
            locale,
            pageKey: 'discovery_logged_out_home',
            renderMode: RENDER_MODES.ISR, // supports render functions in _document.tsx and _app.tsx.
            translations,
            professionalSkillsShowcaseData,
            academies: academiesList,
        },
        revalidate: FOUR_HOURS,
    };
};

export default LoggedOutHomePageFeature;
